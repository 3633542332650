import { DataRuleEvaluator } from "./DataRuleEvaluator";
import { DataRuleTranslator } from "./DataRuleTranslator";
import { DataRuleSet } from "./DataRules";
import { DataRuleFieldMapper } from "./DataRulesFieldMapper";
import { DDDataPoint, DDExport, DefinedData } from "../FromElsewhere/DefinedData";
import { SummaryForChecker, SummaryForRule, SummaryHardCoded } from "./Summary";

export interface ConceptChecker {
	check( ddExport : DDExport, ruleArray : DataRuleSet[] ) : SummaryForChecker;
	name : string;
	consultationIDs : string[];
	translator: DataRuleTranslator;
}

export interface CDRConcept {
	id : number;
	name : string;
	status : string;
}

export class ConceptFactory {
	static CONCEPT1 = "Concept 1 Contract Reference";
	static CONCEPT2 = "Concept 2 Contract Type";
	static MRCv3 = "MRCv3";

	static IMPLEMENTED = "Implemented";
	static PARTIAL = "Partially Implemented";
	static NYI = "Not Yet Implemented";
	static NOTINMRC = "Not in MRC";

	static verbose = false;

	static mayLog( ob : any ) : void {
		if( this.verbose ) {
			if( typeof(ob) == "object") {
				console.log( JSON.stringify( ob, null, 4 ));
			} else {
				console.log( ob );
			}
		}
	}

	static makeAllCDRConcepts() : CDRConcept[] {
		const result = [
			{ id: 1, name : "Contract Reference", status : ConceptFactory.PARTIAL },
			{ id: 2, name : "Contract Type", status : ConceptFactory.IMPLEMENTED },
			{ id: 3, name : "Policyholder", status : ConceptFactory.IMPLEMENTED },
			{ id: 4, name : "Additional Insured", status : ConceptFactory.IMPLEMENTED },
			{ id: 5, name : "Placing Broker", status : ConceptFactory.IMPLEMENTED },
			{ id: 6, name : "Other Intermediary", status : ConceptFactory.NYI },
			{ id: 7, name : "Premium", status : ConceptFactory.PARTIAL },
			{ id: 8, name : "Limit", status : ConceptFactory.IMPLEMENTED },
			{ id: 9, name : "Deductible or Excess", status : ConceptFactory.IMPLEMENTED },
			{ id: 10, name : "(Re)Insurer", status : ConceptFactory.NYI },
			{ id: 11, name : "Security Details", status : ConceptFactory.NYI },
			{ id: 12, name : "Period", status : ConceptFactory.PARTIAL },
			{ id: 13, name : "Payment Info", status : ConceptFactory.NYI },
			{ id: 14, name : "Commissions", status : ConceptFactory.NYI },
			{ id: 15, name : "Brokerage", status : ConceptFactory.NYI },
			{ id: 16, name : "Other Deductions", status : ConceptFactory.NYI },
			{ id: 17, name : "Risk Classification", status : ConceptFactory.PARTIAL },
			{ id: 18, name : "Splits", status : ConceptFactory.NYI },
			{ id: 19, name : "Insurable Interest", status : ConceptFactory.NYI },
			{ id: 20, name : "Exposure Measure", status : ConceptFactory.NYI },
			{ id: 21, name : "Other Interested Party", status : ConceptFactory.NYI },
			{ id: 22, name : "Tax", status : ConceptFactory.NYI },
			{ id: 23, name : "Endorsement", status : ConceptFactory.NYI },
			{ id: 24, name : "Beneficiary", status : ConceptFactory.NYI },
			{ id: 25, name : "Law And Jurisdiction", status : ConceptFactory.NYI },
			{ id: 26, name : "Subjectivities", status : ConceptFactory.NYI }
		];
		return result;
	}

	static makeChecker( name : string, translator : DataRuleTranslator ) : ConceptChecker {
		if( name == ConceptFactory.MRCv3 ) { return new MRCv3Checker( translator ); }
		if( name == ConceptFactory.CONCEPT1 || name == "1" ) {	return new Concept1Checker( translator ); }
		if( name == ConceptFactory.CONCEPT2 || name == "2" ) {	return new Concept2Checker(translator); }
		if( name == "3" ) {	return new Concept3Checker(translator); }
		if( name == "4" ) {	return  new Concept4Checker(translator); }
		if( name == "5" ) {	return  new Concept5Checker(translator); }
		if( name == "7" ) {	return  new Concept7Checker(translator); }
		if( name == "8" ) {	return  new Concept8Checker(translator); }
		if( name == "9" ) {	return  new Concept9Checker(translator); }
		if( name == "12" ) {	return  new Concept12Checker(translator); }
		if( name == "17" ) {	return  new Concept17Checker(translator); }
		return new ConceptNullChecker( translator );
	}
	static runCheck( ddExport : DDExport, ruleArray : DataRuleSet[], consultationIDs: string[], translator: DataRuleTranslator ) : SummaryForChecker {
		const fieldMapper = new DataRuleFieldMapper();
		const evaluator = new DataRuleEvaluator( ddExport, translator, fieldMapper );
		
		const summary = new SummaryForChecker();
		consultationIDs.forEach( id => {
			ConceptFactory.mayLog( `runCheck evaluating rule ${id}`);
			const rule = ruleArray.find( r => { return r.identifier == id });
			if( rule ) {
				evaluator.resetRule( rule );
			}
			if( !rule ) {
				const summaryRule = new SummaryForRule( id, undefined );
				summaryRule.result = SummaryHardCoded.MISSING;
				summary.rules.push( summaryRule );
			} else {
				const summaryRule = new SummaryForRule( id, rule );
				summary.rules.push( summaryRule );
				const applies = evaluator.evaluate( rule );
				this.mayLog( rule );
				if( applies.result == "T") {
					const fMap = fieldMapper.mapField( rule.fieldName );
					if( !fMap ) {
						summaryRule.result = SummaryHardCoded.FAIL;
						summaryRule.failMessages.push( `Unknown field ${rule.fieldName}`);
					} else {
						const tags = fMap.tag.split("/").map( x => { return x.trim(); } );
						const headings = fMap.mrcHeading.split("/").map( x => { return x.trim(); } );
						let found : DDDataPoint = new DDDataPoint("", "", "", {name:"",phType:"",value:""} );
						const grail = { tag: "", mrcHeading : ""};
						headings.forEach( heading => {
							tags.forEach( tag => {
								if( !found.mrcHeading ) {
									grail.tag = tag;
									grail.mrcHeading = heading;
									const x = DefinedData.findFirst( ddExport.definedData, grail );
									if( x ) {
										found = x;
									}
								}
							});
						});
						if( found.mrcHeading ) {
							ConceptFactory.mayLog( `seeking ${JSON.stringify(grail)} found ${found.value}`);
							summaryRule.result = SummaryHardCoded.PASS;
						} else {
							ConceptFactory.mayLog( `seeking ${JSON.stringify(grail)} no datapoint found`);
							summaryRule.result = SummaryHardCoded.FAIL;
							summaryRule.failMessages.push( `Nothing found for heading ${fMap.mrcHeading} tag ${fMap.tag}` );
						}
					}
				} else {
					ConceptFactory.mayLog( `rule ${rule.identifier} ${SummaryHardCoded.DOESNOTAPPLY}`);
					summaryRule.result = SummaryHardCoded.DOESNOTAPPLY;
					summaryRule.failMessages.push( ...applies.failMessages );
				}
			}
		});
		summary.summarise();
		return summary;
	}
}

class ConceptNullChecker implements ConceptChecker {
	name : string = "NullConceptChecker";
	consultationIDs = [];
	translator: DataRuleTranslator;
	constructor( translator : DataRuleTranslator ) {
		this.translator = translator;
	}
	check( ddExport : DDExport, ruleArray : DataRuleSet[] ) : SummaryForChecker {
		const summary = new SummaryForChecker();
		summary.narrative = SummaryHardCoded.NYI;
		return summary;
	}
}

class Concept1Checker implements ConceptChecker {
	name : string = ConceptFactory.CONCEPT1;
	consultationIDs = [ "CDR-0060" ];
	translator: DataRuleTranslator;
	constructor( translator : DataRuleTranslator ) {
		this.translator = translator;
	}
	check( ddExport : DDExport, ruleArray : DataRuleSet[] ) : SummaryForChecker {
		return ConceptFactory.runCheck( ddExport, ruleArray, this.consultationIDs, this.translator );
	}
}
class Concept2Checker implements ConceptChecker {
	name : string = ConceptFactory.CONCEPT2;
	consultationIDs = [ "CDR-0080", "CDR-1600", "CDR-1610", "CDR-0090", "CDR-0100", "CDR-2170"];
	translator: DataRuleTranslator;
	constructor( translator : DataRuleTranslator ) {
		this.translator = translator;
	}
	check( ddExport : DDExport, ruleArray : DataRuleSet[] ) : SummaryForChecker {
		return ConceptFactory.runCheck( ddExport, ruleArray, this.consultationIDs, this.translator );
	}
}
class Concept3Checker implements ConceptChecker {
	name : string = "Concept 3 Policyholder";
	consultationIDs = [ "CDR-0110","CDR-0120","CDR-0130","CDR-0140","CDR-0160","CDR-0170","CDR-0150","CDR-0180","CDR-0190","CDR-0200","CDR-0210"];
	translator: DataRuleTranslator;
	constructor( translator : DataRuleTranslator ) {
		this.translator = translator;
	}
	check( ddExport : DDExport, ruleArray : DataRuleSet[] ) : SummaryForChecker {
		return ConceptFactory.runCheck( ddExport, ruleArray, this.consultationIDs, this.translator);
	}
}

class Concept4Checker implements ConceptChecker {
	name : string = "Concept 4 Additional Insured";
	consultationIDs = [ "CDR-0270","CDR-0280","CDR-0220","CDR-0230","CDR-0240","CDR-0250","CDR-0260"];
	translator: DataRuleTranslator;
	constructor( translator : DataRuleTranslator ) {
		this.translator = translator;
	}
	check( ddExport : DDExport, ruleArray : DataRuleSet[] ) : SummaryForChecker {
		return ConceptFactory.runCheck( ddExport, ruleArray, this.consultationIDs, this.translator );
	}
}

class Concept5Checker implements ConceptChecker {
	name : string = "Concept 5 Placing Broker";
	consultationIDs = [ "CDR-0290","CDR-1620"];
	translator: DataRuleTranslator;
	constructor( translator : DataRuleTranslator ) {
		this.translator = translator;
	}
	check( ddExport : DDExport, ruleArray : DataRuleSet[] ) : SummaryForChecker {
		return ConceptFactory.runCheck( ddExport, ruleArray, this.consultationIDs, this.translator );
	}
}

// Concept 6 Other Intermediary tricky because RWDefinedData not good fit

class Concept7Checker implements ConceptChecker {
	name : string = "Concept 7 Premium";
	consultationIDs = [ "CDR-0430","CDR-1630","CDR-1640","CDR-0440","CDR-0480","CDR-1650","CDR-1660","CDR-1670","CDR-1680","CDR-1690",
		"CDR-0850","CDR-1700","CDR-2030","CDR-2100","CDR-2260","CDR-2270","CDR-2290","CDR-2370","CDR-2380","CDR-2560"];
	translator: DataRuleTranslator;
	constructor( translator : DataRuleTranslator ) {
		this.translator = translator;
	}
	
	check( ddExport : DDExport, ruleArray : DataRuleSet[] ) : SummaryForChecker {
		const summary = ConceptFactory.runCheck( ddExport, ruleArray, this.consultationIDs, this.translator );
		const rule = summary.rules.find( r => { return r.ruleID == "CDR-0440"});
		if( rule && rule.result == SummaryHardCoded.FAIL ) {
			const grail = { tag: "Premium_Contractual_Currency"};
			const dp = DefinedData.findFirst( ddExport.definedData, grail );
			if( dp ) {
				rule.result = SummaryHardCoded.PASS;
				summary.summarise();
			}
		}
		return summary;
	}
}

class Concept8Checker implements ConceptChecker {
	name : string = "Concept 8 Limit";
	consultationIDs = [ "CDR-0490","CDR-0500","CDR-0510" ];
	translator: DataRuleTranslator;
	constructor( translator : DataRuleTranslator ) {
		this.translator = translator;
	}
	check( ddExport : DDExport, ruleArray : DataRuleSet[] ) : SummaryForChecker {
		return ConceptFactory.runCheck( ddExport, ruleArray, this.consultationIDs, this.translator );
	}
}

class Concept9Checker implements ConceptChecker {
	name : string = "Concept 9 Deductible or Excess";
	consultationIDs = [ "CDR-0520","CDR-0530","CDR-0540","CDR-2040","CDR-0550" ];
	// consultationIDs = [ "CDR-0530","CDR-0540","CDR-2040","CDR-0550" ];
	translator: DataRuleTranslator;
	constructor( translator : DataRuleTranslator ) {
		this.translator = translator;
	}
	check( ddExport : DDExport, ruleArray : DataRuleSet[] ) : SummaryForChecker {
		return ConceptFactory.runCheck( ddExport, ruleArray, this.consultationIDs, this.translator );
	}
}

class Concept12Checker implements ConceptChecker {
	name : string = "Concept 12 Period";
	consultationIDs = [ "CDR-0700","CDR-0710","CDR-0730","CDR-2210","CDR-2220","CDR-2230" ]; 
	// CDR-0720 is derived
	// "CDR-2240","CDR-2250","CDR-2570" are a bit nebulous
	translator: DataRuleTranslator;
	constructor( translator : DataRuleTranslator ) {
		this.translator = translator;
	}
	check( ddExport : DDExport, ruleArray : DataRuleSet[] ) : SummaryForChecker {
		return ConceptFactory.runCheck( ddExport, ruleArray, this.consultationIDs, this.translator );
	}
}

class Concept17Checker implements ConceptChecker {
	name : string = "Concept 17 Risk Classification";
	consultationIDs = [ "CDR-0860", "CDR-0870", "CDR-0940" ];
	translator: DataRuleTranslator;
	constructor( translator : DataRuleTranslator ) {
		this.translator = translator;
	}
	check( ddExport : DDExport, ruleArray : DataRuleSet[] ) : SummaryForChecker {
		return ConceptFactory.runCheck( ddExport, ruleArray, this.consultationIDs, this.translator );
	}
}

// MRC ////////////////////////////////////////////////////////////////////////////////////


class MRCv3Checker implements ConceptChecker {
	name : string = "MRCv3 Checker";
	consultationIDs = [];
	translator: DataRuleTranslator;
	constructor( translator : DataRuleTranslator ) {
		this.translator = translator;
	}
	check( ddExport : DDExport, ruleArray : DataRuleSet[] ) : SummaryForChecker {
		const fieldMapper = new DataRuleFieldMapper();
		const evaluator = new DataRuleEvaluator( ddExport, this.translator, fieldMapper );
		
		const summary = new SummaryForChecker();
		ruleArray.forEach( rule => {
			evaluator.resetRule( rule );
			const summaryRule = new SummaryForRule( rule.identifier, rule );
			summary.rules.push( summaryRule );
			const applies = evaluator.evaluate( rule );
			if( applies.result == "T") {
				ConceptFactory.mayLog( `rule ${rule.identifier} ${SummaryHardCoded.PASS}`);
				summaryRule.result = SummaryHardCoded.PASS;
			} else {
				ConceptFactory.mayLog( `rule ${rule.identifier} ${SummaryHardCoded.FAIL}`);
				summaryRule.result = SummaryHardCoded.FAIL;
				summaryRule.failMessages.push( ...applies.failMessages );
			}
		});
		summary.summarise();
		return summary;	}
}
