import { utils } from "../../FromElsewhere/utils";
import dayjs, { Dayjs } from "dayjs";
import { DefinedData, DDExport, DDDataPoint, DDSectionDefinition } from '../../FromElsewhere/DefinedData';
import { Market } from '../../helpers/Market';
import { JMRC } from '../../rebrowse/jmrc'
import { PKFeedback, PKObservation } from "./PaymentFeedback";
import { PKFigure } from "./PKFigure";

// IC4509D02D-DC14-4DD7-A61A-5C3F7671A8AC TESS sarah_klau_blackpool Poltergeist 

export const SPANEnum = {
	taxesDeducted : -1,
	taxesAdded : -2,
	otherDeductions : -3,
	gwpProRatedForPeriod : -4, 
	gwpAdjustedForOrder : -5,
	discounts : -6,
	gwpDiscounted : -7,
	gwpAfterTax : -8,
	brokerage : -9,
	netPremium: -10,
	uwrNetPremium: -11
}

export class SPANSectionCarrierPair {
	sectionID = "";
	sectionName = "";
	stampID = "";
	signedPercentage = "";
	companyId = "";
	companyName = "";
	stamp : JMRC.Stamp | null = null;

	constructor( sectionID: string, sectionName : string, companyId : string, companyName : string, stampID : string, signedPercentage : string ) {
		this.sectionName = sectionName;
		this.sectionID = sectionID;
		this.companyId = companyId;
		this.companyName = companyName;
		this.stampID = stampID;
		this.signedPercentage = signedPercentage;
	}

	static fromDefinedData( dd : DDExport, market : Market ) : SPANSectionCarrierPair[] {
		const result : SPANSectionCarrierPair[] = [];
		const iids = DefinedData.findAll( dd.definedData, { tag: "Insurer_Identification"});
		let sections: DDSectionDefinition[] = [ { sectionID: "", sectionName : "" }];
		if( dd.multiSectionDefinition && dd.multiSectionDefinition.length ) {
			sections = dd.multiSectionDefinition;
		}		
		if( !iids.length ) {
			sections.forEach( sd => {
				result.push( new SPANSectionCarrierPair( sd.sectionID, sd.sectionName, "", "No Signed Lines", "No StampID", "" ));
			});
			return result;
		}
		iids.forEach( iid => {
			const stampID = iid.parentID;
			const corp = market.findCorpForStamp( iid.parentID );
			const stamp = corp?.stamps?.filter( s => { return s.uniqueID == stampID})[0] || null;
			const companyId = corp?.companyId || "";
			const companyName = corp?.name || "";
			sections.forEach( s => {
				if( !s.sectionID || iid.sectionIDs.includes( s.sectionID ) ) {
					const scp = result.filter( scp => { return scp.sectionID == s.sectionID && scp.companyId == companyId} )[0];
					const signedPercentage = DefinedData.findFirst(dd.definedData,{tag:"Signed_Line_Percentage", parentID:iid.parentID })?.value || "";
					const newSCP = new SPANSectionCarrierPair( s.sectionID, s.sectionName, companyId, companyName, stampID, signedPercentage );
					newSCP.stamp = stamp;
					result.push( newSCP );
				}
			});
		});
		result.sort( (a,b) => {
			if( a.sectionID != b.sectionID ) {
				return a.sectionID.localeCompare( b.sectionID );
			}
			if( a.companyId != b.companyId ) {
				return a.companyId.localeCompare( b.companyId );
			}
			return 0;
		})
		return result;
	}
}



export class SPANInstalment {
	parentID = "";
	Instalment_Number = "";
	Instalment_Due_Date = "";
	Settlement_Due_Date = "";
	Instalment_Percentage = "";
	Instalment_Amount = "";
	date = "";
	total = 0.0;
	figure = new PKFigure();
	index = 0;
	d = dayjs("1 January 2000");

	constructor( parentID : string ) {
		this.parentID = parentID;
	}

	// look in PremiumPaymentTerms first; in none try SettlementDueDate
	static fromDefinedData( dd : DDExport, sectionID : string, total : number ) : SPANInstalment[] {
		const result : SPANInstalment[] = [];
		const mrcHeadings = ["PremiumPaymentTerms","SettlementDueDate"];
		const tags = ["Instalment_Number", "Instalment_%", "Instalment_Due_Date", "Instalment_Amount", "Settlement_Due_Date"];

		mrcHeadings.forEach( mrcHeading => {
			if( result.length == 0 ) {
				tags.forEach( t => {
					const dps = DefinedData.findAll( dd.definedData, { tag: t, mrcHeading: mrcHeading });
					dps.forEach( dp => {
						// console.log( `DP: ${dp.tag} ${dp.value} ${dp.parentID}`);
						let si = result.filter( x => { return x.parentID == dp.parentID })[0];
						const addit = si ? false : true;
						if( !si ) {
							si = new SPANInstalment( dp.parentID );
							result.push( si );
						}
						if( t == "Instalment_%" )	si.Instalment_Percentage = dp.value;
						if( t == "Instalment_Number" )	si.Instalment_Number = dp.value;
						if( t == "Instalment_Due_Date" )	si.Instalment_Due_Date = dp.value;
						if( t == "Settlement_Due_Date" )	si.Settlement_Due_Date = dp.value;
						if( t == "Instalment_Amount" )	si.Instalment_Amount = dp.value;
					});
				});		
			}
		});
		result.forEach( si => {
			si.date = si.Settlement_Due_Date || si.Instalment_Due_Date;
			si.d = dayjs( si.date );
		});
		if( result.length && result.filter( si => { return si.Instalment_Percentage == "" }).length == result.length ) {
			if( result.filter( si => { return si.Instalment_Amount != "" }).length == result.length ) 
			{
				const total = result.reduce( ( x, si ) => { return x + ( utils.parseAmount( si.Instalment_Amount )?.figure || 0.0 ) }, 0.0 );
				console.log( `calc total ${total} from ` + result.map( si => { return si.Instalment_Amount }).join( " + ") );
				if( total ) {
					result.forEach( si => {
						si.Instalment_Percentage = utils.trimTrailingZeroes(( 100 * ( utils.parseAmount( si.Instalment_Amount )?.figure || 0.0 ) / total ).toFixed( 8 )) + "%";
					});	
				}
			} else {
				const perc = utils.trimTrailingZeroes( ( 100 / result.length ).toFixed( 8 )) + "%";
				result.forEach( si => {
					si.Instalment_Percentage = perc;
				});		
			}
		}
		result.sort((a,b)=> { return a.d.diff(b.d) } );
		// const count = result.length;
		// let remaining = total;
		// result.forEach( (si,idx) => {
		// 	si.index = idx + 1;
		// 	si.total = total;
		// 	if( idx + 1 == count ) {
		// 		si.figure.setW(remaining);
		// 	} else {
		// 		const perc = parseFloat( si.Instalment_Percentage.replaceAll("%",""));
		// 		if( isNaN(perc)) {
		// 			si.figure.setW(0);
		// 		} else {
		// 			si.figure.setW( utils.round2dps( total * perc * 0.01 ) );
		// 			remaining = utils.round2dps( remaining - si.figure.W() );
		// 		}
		// 	}
		// 	// console.log( `SI: ${si.d} ${si.date} ${si.index}=${si.Instalment_Number} ${si.figure}`);
		// });
		return result;
	}

	static calculate( insts : SPANInstalment[], total : number ) {
		const count = insts.length;
		let remaining = total;
		insts.forEach( (si,idx) => {
			si.index = idx + 1;
			si.total = total;
			if( idx + 1 == count ) {
				si.figure.setW(remaining);
			} else {
				const perc = parseFloat( si.Instalment_Percentage.replaceAll("%",""));
				if( isNaN(perc)) {
					si.figure.setW(0);
				} else {
					si.figure.setW( utils.round2dps( total * perc * 0.01 ) );
					remaining = utils.round2dps( remaining - si.figure.W() );
				}
			}
			// console.log( `SI: ${si.d} ${si.date} ${si.index}=${si.Instalment_Number} ${si.figure}`);
		});
	}
}

type SPANFigureSet = {[key: string]: PKFigure}

export class SPANAdvice {
	riskIC = "";
	riskName = "";
	bkrChannel = "";
	uwrChannel = "";
	policyUMR = "";
	brokerPolicyRef = "";
	inceptionDate = "";
	expiryDate = "";
	adviceDate = "";
	sequenceNumber = "";
	premiumAmount = "";

	uwrCompanyId = "";
	uwrCompanyName = "";
	stamp : JMRC.Stamp | null = null;
	uwrStampID = "";
	uwrPolicyRef = "";
	uwrPolicyYear = "";
	uwrSignedPercentage = "";
	uwrPremiumAmount = "";

	insuredName = "";
	policyType = ""; // (re)insurance, treaty, faculity, etc.
	brokerShare = 0.0;
	orderPercentage = "";
	classOfBusiness = "";
	splitEEAandNonEEA = "";
	endorsementNumber = "";
	premiumType = "";

	sectionName = "";
	sectionID = "";

	otherDeductions = { description: "-", percentage: "" };
	taxesAdded = { description: "-", percentage: "", country: "" };
	taxesDeducted = { description: "-", percentage: "", country: "" };
	brokerage = { percentage: "" };
	yourShare = { percentage: "" };

	currency = "";

	figs : SPANFigureSet = {
		grossWrittenPremium : new PKFigure(),
		gwpProRatedForPeriod : new PKFigure(),
		gwpAdjustedForOrder : new PKFigure(),
		otherDeductionsFigure : new PKFigure(),
		gwpDiscounted : new PKFigure(),
		taxesAddedFigure : new PKFigure(),
		taxesDeductedFigure : new PKFigure(),
		brokerageFigure : new PKFigure(),
		gwpAfterTax : new PKFigure(),
		netPremium : new PKFigure(),
		uwrNetPremium : new PKFigure()
	}
	instalments : SPANInstalment[] = [];
	// grossWrittenPremium = new PKFigure();
	// gwpProRatedForPeriod = new PKFigure();
	// gwpAdjustedForOrder = new PKFigure();
	// otherDeductionsFigure = new PKFigure();
	// gwpDiscounted = new PKFigure();
	// taxesAddedFigure = new PKFigure();
	// taxesDeductedFigure = new PKFigure();
	// brokerageFigure = new PKFigure();
	// gwpAfterTax = new PKFigure();
	// netPremium = new PKFigure();
	// uwrNetPremium = new PKFigure();

	fractionOfYear() : number {
		const d1 = dayjs(this.inceptionDate);
		const d2 = dayjs(this.expiryDate);
		const diffDays = d2.diff(d1,"day");
		if( diffDays == 365 || diffDays == 366 ) {
			return 1.0;
		}
		return ( 1.0 * diffDays ) / 365;
	}

	percentageOfYear() : string {
		const fraction = this.fractionOfYear();
		if( fraction == 1.0 ) {
			return "100%";
		}
		return ( fraction * 100.0 ).toFixed( 4 ) + "%";
	}

	/*
	getPKFigure( item : string ) : PKFigure {
		const ob = JSON.parse( JSON.stringify( this ) );
		if( typeof(ob[item]) == "object" ) {
			// return ob[item]; ... fig.agree() is not an item
			const fig : PKFigure = ob[item];
			// this[item] = fig;
			// const fig : PKFigure = PKFigure.clone(ob[item]);
			// console.log( `getPKFigure ${item} is o:${fig.O()} != p:${fig.P()} so w:${fig.W()}`)
			return fig;
		}
		console.log( `getPKFigure nothing for ${item}`);
		return new PKFigure();
	}
	getFigure( item : string ) : number {
		const ob = JSON.parse( JSON.stringify( this ) );
		if( typeof(ob[item]) == "number" ) {
			return ob[item];
		}
		return NaN;
	}
	*/

	setExtendedFields( riskName : string, bkrChannel: string, uwrChannel : string ) {
		this.riskName = riskName;
		this.bkrChannel = bkrChannel;
		this.uwrChannel = uwrChannel;
	}

	processFeedbacks( feedbacks : PKFeedback[] ) {
		let observations : PKObservation[] = [];
		feedbacks.map( f => { observations = [...observations, ...f.observations ]});
		this.processObservations( observations );
		// observations.sort( (a,b) => { return a.createdAt.localeCompare( b.createdAt )});
		// console.log( `processFeedbacks on ${feedbacks.length} fbs, ${observations.length} obs`);
		// observations.map( ob => { 
		// 	const fig = this.figs[ ob.item ];
		// 	fig.process( ob );
		// });
	}
	processObservations( observations: PKObservation[] ) {
		observations.sort( (a,b) => { return a.createdAt.localeCompare( b.createdAt )});
		console.log( `processObservations on ${observations.length} obs`);
		observations.map( ob => { 
			const fig = this.figs[ ob.item ];
			fig.holdObservation( ob );
		});	
		this.calculate();	
	}

	calculate() {
		let result = utils.round2dps( this.figs.grossWrittenPremium.W() );
		if( this.fractionOfYear() != 1.0 ) {
			result = utils.round2dps( this.figs.grossWrittenPremium.W() * this.fractionOfYear() );
		}
		result = this.figs.gwpProRatedForPeriod.setW( result );

		result = utils.round2dps( result * this.brokerShare * 0.01 );
		result = this.figs.gwpAdjustedForOrder.setW( result );

		let dedufig = 0 - utils.round2dps( result * parseFloat(this.otherDeductions.percentage.replaceAll("%","")) * 0.01 );
		dedufig = this.figs.otherDeductionsFigure.setW( dedufig );
		result = utils.round2dps( result + dedufig );
		result = this.figs.gwpDiscounted.setW( result );

		this.figs.taxesAddedFigure.setW( utils.round2dps( result * parseFloat(this.taxesAdded.percentage.replaceAll("%","")) * 0.01 ) );
		this.figs.taxesDeductedFigure.setW( 0 - utils.round2dps( result * parseFloat(this.taxesDeducted.percentage.replaceAll("%","")) * 0.01 ) );
		this.figs.brokerageFigure.setW( 0 - utils.round2dps( result * parseFloat(this.brokerage.percentage.replaceAll("%","")) * 0.01 ) );

		result = utils.round2dps( result + this.figs.taxesAddedFigure.W() + this.figs.taxesDeductedFigure.W() );
		result = this.figs.gwpAfterTax.setW( result );

		result = utils.round2dps( result + this.figs.brokerageFigure.W() );
		result = this.figs.netPremium.setW( result );

		const signedPerc = parseFloat(this.uwrSignedPercentage.replaceAll("%",""));
		result = isNaN(signedPerc) ? 0.0 : utils.round2dps( result * signedPerc * 0.01 );
		result = this.figs.uwrNetPremium.setW( result );

		if( this.instalments.length > 0 ) {
			SPANInstalment.calculate( this.instalments, result );
		}
	}



	static fromDefinedData( dd : DDExport, sectionID : string, companyId : string, companyName: string, stamp : JMRC.Stamp | null ) : SPANAdvice {
		const span = new SPANAdvice();

		span.uwrCompanyId = companyId;
		span.uwrCompanyName = companyName;
		span.stamp = stamp;

		if( sectionID ) {
			const s = dd.multiSectionDefinition.filter( sd => { return sd.sectionID == sectionID })[0];
			if( !s ) {
				return span;
			}
			span.sectionID = s.sectionID;
			span.sectionName = s.sectionName;
		} else {
			if( dd.multiSectionDefinition && dd.multiSectionDefinition.length ) {
				return span;
			}
			span.sectionID = "";
			span.sectionName = "Unsectioned Risk";
		}
		span.riskIC = dd.metadata?.documentID || "-";
		span.policyUMR = DefinedData.findFirst(dd.definedData,{tag:"Broker_Reference",sectionID:sectionID})?.value || "-";
		span.brokerPolicyRef = "!from PAS";
		span.inceptionDate = DefinedData.findFirst(dd.definedData,{tag:"Inception_Date",sectionID:sectionID})?.value || "-";

		span.adviceDate = "!from Polka";
		span.sequenceNumber = "!from Polka";

		span.insuredName = DefinedData.findFirst(dd.definedData,{tag:"Policyholder_Name",sectionID:sectionID})?.value || "";
		if( !span.insuredName ) {
			span.insuredName = DefinedData.findFirst(dd.definedData,{tag:"Insured_Name",sectionID:sectionID})?.value || "";
		}
		span.expiryDate = DefinedData.findFirst(dd.definedData,{tag:"Expiry_Date",sectionID:sectionID})?.value || "-";

		span.policyType = DefinedData.findFirst(dd.definedData,{tag:"Insurance_Type",sectionID:sectionID})?.value || "-";
		span.classOfBusiness = DefinedData.findFirst(dd.definedData,{tag:"Class_Of_Business",sectionID:sectionID})?.value || "-";

		span.brokerShare = parseFloat( (DefinedData.findFirst(dd.definedData,{tag:"Broker_Share_%",sectionID:sectionID})?.value || "100%").replaceAll("%","") );
		span.orderPercentage = (DefinedData.findFirst(dd.definedData,{tag:"Broker_Share_%",sectionID:sectionID})?.value || "-")
			+ " of " + (DefinedData.findFirst(dd.definedData,{tag:"Order_%",sectionID:sectionID})?.value || "-");

		span.splitEEAandNonEEA = "!from Stamps";
		span.endorsementNumber = "";	// TODO read endorsement number

		span.premiumType = (DefinedData.findFirst(dd.definedData,{tag:"Premium_Type",sectionID:sectionID})?.value || "-");

		let amount = (DefinedData.findFirst(dd.definedData,{tag:"Premium_Amount",sectionID:sectionID})?.value || "");
		if( amount && amount != "???") {
			span.currency = amount.substr(0,3);
			span.figs.grossWrittenPremium.setW( parseFloat( amount.substr(3).trim().replaceAll(",","")) );
		}
		amount = (DefinedData.findFirst(dd.definedData,{tag:"Premium_Amount",sectionID:sectionID})?.value || "");

		span.otherDeductions.description = DefinedData.findFirst(dd.definedData,{tag:"Deduction_Type",sectionID:sectionID})?.value || ""
		if( !span.otherDeductions.description ) {
			span.otherDeductions.description = DefinedData.findFirst(dd.definedData,{tag:"Deduction_Payable_To",sectionID:sectionID})?.value || ""
		}
 

		span.otherDeductions.percentage = DefinedData.findFirst(dd.definedData,{tag:"Deduction_%",sectionID:sectionID})?.value || "0%"
		// result.otherDeductions.figure = result.intermediate( SPANEnum.otherDeductions );// 3);

		// const netPremium = result.grossWrittenPremium.figure - result.otherDeductionsFigure;

		let mrcHeading = "TaxesPayableByInsuredAndAdministeredByInsurers";

		span.taxesAdded.description = (DefinedData.findFirst(dd.definedData,{tag:"Tax_Type",mrcHeading: mrcHeading,sectionID:sectionID})?.value || "-")
		span.taxesAdded.country = (DefinedData.findFirst(dd.definedData,{tag:"Tax_Country",mrcHeading: mrcHeading,sectionID:sectionID})?.value || "-");
		span.taxesAdded.percentage = DefinedData.findFirst(dd.definedData,{tag:"Tax_%",mrcHeading: mrcHeading,sectionID:sectionID})?.value || "0%"

		mrcHeading = "TaxesPayableByInsurersAndAdministeredByInsuredOrTheirAgent";
		span.taxesDeducted.description = (DefinedData.findFirst(dd.definedData,{tag:"Tax_Type",mrcHeading: mrcHeading,sectionID:sectionID})?.value || "-");
		span.taxesDeducted.country = (DefinedData.findFirst(dd.definedData,{tag:"Tax_Country",mrcHeading: mrcHeading,sectionID:sectionID})?.value || "-");
		span.taxesDeducted.percentage = DefinedData.findFirst(dd.definedData,{tag:"Tax_%",mrcHeading: mrcHeading,sectionID:sectionID})?.value || "0%";

		span.brokerage.percentage = DefinedData.findFirst(dd.definedData,{tag:"Brokerage_%",sectionID:sectionID})?.value || "0%"
		// result.brokerage.figure = utils.round2dps( netPremium * parseFloat(result.brokerage.percentage.replaceAll("%","")) * 0.01 );

		span.yourShare.percentage = "!from signed lines";
		// result.yourShare.figure = utils.round2dps( netPremium - ( result.brokerage.figure ) + result.taxesAddedFigure + result.taxesDeductedFigure );

		span.uwrPolicyYear = DefinedData.findFirst(dd.definedData,{tag:"Year_Of_Account",sectionID:sectionID})?.value || "-";
		
		span.uwrStampID = stamp ? stamp.uniqueID : "";
		if( span.uwrStampID ) {
			span.uwrPolicyRef = "TODO"; // DefinedData.findFirst(dd.definedData,{tag:"Insurer_Contract_Reference",sectionID:sectionID,parentIDs:stampID + "::0"})?.value || "-";
			span.uwrSignedPercentage = DefinedData.findFirst(dd.definedData,{tag:"Signed_Line_Percentage",sectionID:sectionID,parentID:span.uwrStampID})?.value || "";
		}

		// span.calculate();

		// const total = stamp == null ? span.figs.netPremium : span.figs.uwrNetPremium;
		span.instalments = SPANInstalment.fromDefinedData( dd, sectionID, NaN );

		return span;
	}
}