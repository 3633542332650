/*
{
	"type" : "PKFeedback",
	"riskID" : "ICDD218B40-F337-444B-8E34-96B76FDD26BF::FO::PALERMO::ALL",
	"sectionID" : "",
	"stampID" : "STFED8C47C-41C6-49E9-828A-F4A9DA1A6609",
	"observations" : [
		{
			"item" : "taxesAddedFigure",
			"agreed" : true,
			"comment" : "this looks about right",
			"createdAt" : "2023-07-08 10:27:00"
		},
		{
			"item" : "taxesDeductedFigure",
			"agreed" : false,
			"comment" : "I feel this should be something else",
			"figureProposed" : 1000.01,
			"createdAt" : "2023-07-08 10:28:00"
		}
	]
}
*/

import { isNumberObject } from "util/types";

export class PKFeedback {
	type = "";
	riskID = "";
	sectionID = "";
	stampID = "";
	role = "";
	userName = "";
	observations: PKObservation[] = [];

	static fromExtended( riskID : string, sectionID : string, stampID : string, efb : PKExtended ) : PKFeedback {
		const result = new PKFeedback();
		result.type = "PKFeedback";
		result.riskID = riskID;
		result.sectionID = sectionID;
		result.stampID = stampID;
		result.role = efb.role;
		result.userName = efb.userName;
		const ob = new PKObservation();
		ob.agreed = efb.agreed;
		ob.comment = efb.comment;
		ob.createdAt = efb.createdAt;
		ob.figureProposed = efb.figureProposed;
		ob.item = efb.item;
		result.observations.push( ob );
		return result;
	}

	static fromJSON( json : string ) : PKFeedback {
		const result = new PKFeedback();
		try {
			const input = JSON.parse( json );
			result.type = input.type || "";
			result.riskID = input.riskID || "";
			result.sectionID = input.sectionID || "";
			result.stampID = input.stampID || "";
			result.role = input.role || "";
			result.userName = input.userName || "";
			if( Array.isArray( input.observations ) ) {
				input.observations.forEach( ( o : any ) => { 
					result.observations.push( PKObservation.fromObject( o ) );
				});
			}	
		} catch {
			result.type = "";
		}
		return result;
	}

	getObservations( item : string ) : PKObservation[] {
		return this.observations.filter( ob => { return ob.item == item });
	}

	getExtended() : PKExtended[] {
		const role = this.role;
		const userName = this.userName;
		return this.observations.map( ob => { return new PKExtended( role, userName, ob )});
	}
}

export class PKObservation {
	item = "";
	agreed = false;
	comment = "";
	figureProposed = NaN;
	createdAt = "";
	static fromObject( input : any ) : PKObservation {
		const result = new PKObservation();
		result.item = input.item || "";
		result.agreed = input.agreed ? true : false;
		result.comment = input.comment || "";
		result.figureProposed = parseFloat(input.figureProposed?.toString() || "x");
		result.createdAt = input.createdAt || "";
		return result;
	}
}

export class PKExtended {
	role = "";
	userName = "";
	item = "";
	agreed = false;
	comment = "";
	figureProposed = NaN;
	createdAt = "";
	constructor( role : string, userName: string, observation : PKObservation ) {
		this.role = role;
		this.userName = userName;

		this.item = observation.item;
		this.agreed = observation.agreed;
		this.comment = observation.comment;
		this.figureProposed = observation.figureProposed;
		this.createdAt = observation.createdAt;		
	}
}