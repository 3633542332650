import { CombinedSets } from "../../helpers/CombinedSets";
import { Market } from "../../helpers/Market";
import { JMRC } from "../../rebrowse/jmrc";
import { MSStorer } from "../../rebrowse/multi.section.storer";

export const OPHardCoded = {
	NOSECTION : "-1",
	SECTION0 : "SECTION0"
}

export interface SelectedSection {
	index : number;
	multiSectionId : string;
	multiSectionName : string;
}

export class OPDataModel {
	market?: Market;
	// decID = "";
	facID = "";
	// decSectionID = "";
	facSectionID = "";
	// decSections : MSStorer.Root = OPDataModel.mockSectionDoc();
	facSections : MSStorer.Root = OPDataModel.mockSectionDoc();
	declarationRev = "";
	// declarationMRC? : JMRC.Root;
	facilityLineSets : CombinedSets.Root[] = [];
	// declarationLineSets : CombinedSets.Root[] = [];
	facilityGuidance: any[] = [];
	recordLinesPayload: any = {};
	callback: any;

	constructor( fn : any ) {
		this.callback = fn;
	}

	version() : string {
		return "0.0";
	}

	describe() : string[] {
		const lines: string[] = [];
		lines.push( `Corporates : ${this.market?.corporates.length || "none"}`);
		// lines.push( `Declaration MRC : ${this.declarationMRC?._id || "none"} ${this.declarationMRC?._rev || ""}`);
		// lines.push( `Declaration Sections : ${this.decSections?.sections.length || "none"}`);
		lines.push( `Facility Sections : ${this.facSections?.sections.length || "none"}`);
		lines.push( `Facility Line Sets : ${this.facilityLineSets.length}`);
		lines.push( `Facility Guidance : ${this.facilityGuidance.length}`);
		return lines;
	}

	buildPayload( declarationSectID : string, facilitySectID : string, selectedSection: SelectedSection ) {
		const now = new Date();
		const timestamp = ( now.getTime() - now.getMilliseconds() ) / 1000
		this.recordLinesPayload = {};
		this.recordLinesPayload._rev = this.declarationRev;
		this.recordLinesPayload.items = [];
		this.facilityLineSets.forEach( ls => {
			const item : any = { lines: [], uwrChannel: "", saveStamps : true };
			const line : any = {
				sectionIDs: [],
				impressions : [],
				conditions: { lineConditions: [], subjectivities: [] },
				leadUnderwriter : false,
				writtenDate: timestamp
			};	
			if( declarationSectID != OPHardCoded.SECTION0 ) {
				line.sectionIDs.push( declarationSectID );
				line.sectionInfo = selectedSection;
			}				
			ls.contents.forEach( c => {
				if( facilitySectID == OPHardCoded.SECTION0 || c.sectionIdentifiers.includes( facilitySectID ) ) {
					item.uwrChannel = c.stamperChannel;
					c.impressions.forEach( imp => {
						line.impressions.push( this.buildImpression( imp ) );
					});
				}
				item.lines.push( line );
			});
			this.recordLinesPayload.items.push( item );
		});
		const msg = `buildPayload has ${this.recordLinesPayload.items.length} items, ${this.recordLinesPayload.items[0].lines.length} lines`;
		console.log( msg );
		// this.recordLinesPayload.debugMessage = msg;
		// this.recordLinesPayload.debugTime = new Date().toString();
		// console.log( this.recordLinesPayload );
	}

	prepareLineSets( facSectionId : string ) {
		const result : any[] = [];
		this.recordLinesPayload.items.forEach( itm => {
			itm.lines.forEach( line => {
				line.impressions.forEach( imp => {
					const ob : any = { businessUnit : imp.businessUnit, 
						writtenPercentage: imp.writtenPercentage,
						stampID: imp.stampID,
						bureauMarket: imp.stampID,
						bureauMarketCode: imp.bureauMarketCode,
						bureauSubMarket: imp.bureauSubMarket,
						stampType: imp.stampType,
						shortName: imp.shortName
					};
					// ob.stamp = imp.stamp;
					// ob.stampIdentifiers = imp.stampIdentifiers;
					// ob.uwRefs = imp.uwRefs;
					// ob.yearOfAccount = imp.yearOfAccount;
					result.push( ob );	
				});
			});
		});
		return result;
	}

	filterAndSortSignedSets() {
		this.facilityLineSets = OPDataModel.filterAndSortLineSets( this.facilityLineSets, this.facSectionID );

		// this.facilityLineSets.forEach( s => {
		// 	console.log( `filterAndSortSignedSets prelude ${s.contents.length} for ${s._id}`);
		// });
		// if( this.facSectionID != OPHardCoded.SECTION0 ) {
		// 	this.facilityLineSets.forEach( s => {
		// 		console.log( `filterAndSortSignedSets initially ${s.contents.length} for ${s._id}`);
		// 		const waslen = s.contents.length;
		// 		for( let i = s.contents.length - 1; i >= 0; i-- ) {
		// 			if( !s.contents[i].sectionIdentifiers.includes( this.facSectionID ) ) {
		// 				// console.log( `filterAndSortSignedSets removing #${i} [${s.contents[i].sectionIdentifiers.join(", ")}] ${s.contents[i].impressions.map( x => { return `${x.signedLinePercentageString}%` }).join( "+")} for ${s._id}`);
		// 				s.contents.splice( i, 1 );
		// 			}
		// 		}
		// 	});	
		// }
		// this.facilityLineSets.sort( ( x, y ) => { return this.silsCompare(x,y); })
		// this.facilityLineSets.forEach( s => {
		// 	if( s.contents.length ) {
		// 		const percs = s.contents[0].impressions.map( i => { return `${i.signedLinePercentageString}%`}).join("+");
		// 		console.log( `filterAndSortSignedSets final ${s.contents.length} ${s.contents[0].role} ${s.contents[0].stampedAt} ${percs} for ${s._id}`);
		// 	} else {
		// 		console.log( `filterAndSortSignedSets final ${s.contents.length} for ${s._id}`);
		// 	}
		// });
	}

	static filterAndSortLineSets( sets : CombinedSets.Root[], sectionID : string ) : CombinedSets.Root[] {
		const result : CombinedSets.Root[] = JSON.parse(JSON.stringify( sets ) );

		result.forEach( s => {
			console.log( `filterAndSortLineSets prelude ${s.contents.length} for ${s._id}`);
		});
		if( sectionID != OPHardCoded.SECTION0 ) {
			result.forEach( s => {
				console.log( `filterAndSortLineSets initially ${s.contents.length} for ${s._id}`);
				const waslen = s.contents.length;
				for( let i = s.contents.length - 1; i >= 0; i-- ) {
					if( !s.contents[i].sectionIdentifiers.includes( sectionID ) ) {
						// console.log( `filterAndSortLineSets removing #${i} [${s.contents[i].sectionIdentifiers.join(", ")}] ${s.contents[i].impressions.map( x => { return `${x.signedLinePercentageString}%` }).join( "+")} for ${s._id}`);
						s.contents.splice( i, 1 );
					}
				}
			});	
		}
		result.sort( ( x, y ) => { return OPDataModel.linesetCompare(x,y); })
		result.forEach( s => {
			if( s.contents.length ) {
				const percs = s.contents[0].impressions.map( i => { return `${i.signedLinePercentageString}%`}).join("+");
				console.log( `filterAndSortLineSets final ${s.contents.length} ${s.contents[0].role} ${s.contents[0].stampedAt} ${percs} for ${s._id}`);
			} else {
				console.log( `filterAndSortLineSets final ${s.contents.length} for ${s._id}`);
			}
		});	
		return result;	
	}

	static countImpressions( sets : CombinedSets.Root[], sectionID : string ) : number {
		const filtered = OPDataModel.filterAndSortLineSets( sets, sectionID );
		let result = 0;
		filtered.forEach( s => {
			s.contents.forEach( c => {
				result += c.impressions.length;
			});
		});
		return result;
	}

	static linesetCompare( x : any, y : any ) {
		const xc = x.contents ? x.contents[0] : null;
		const yc = y.contents ? y.contents[0] : null;
		if( !xc || !yc ) { return 0; }
		if( xc.role != yc.role ) {
			if( xc.role == "leader" )	{ return -1; }	
			if( yc.role == "leader" )	{ return 1; }	
		}
		return xc.stampedAt.toString().localeCompare(yc.stampedAt.toString() );
	}

	buildImpression( imp ) {
		const result : any = { references: [] };
		imp.uwRefs.forEach( (ref,i) => {
			const code = imp.riskCodes.find( x => { return x.index == i } )?.code || "";
			result.references.push( { reference: ref, riskCodes: code });
		});
		result.writtenPercentage = imp.signedLinePercentageString;
		result.stampID = imp.stamp.uniqueID;
		result.bureauMarket = imp.stamp.bureauMarket;
		result.businessUnit = imp.stamp.businessUnit;
		result.bureauMarketCode = imp.stamp.bureauMarketCode;
		result.bureauSubMarket = imp.stamp.bureauSubMarket;
		result.stampType = imp.stamp.stampType;
		result.shortName = "";
		return result;
	}

	getTotalPercentage() : string {
		let totPerc = 0.0;
		this.recordLinesPayload.items.forEach( itm => {
			itm.lines.forEach( line => {
				line.impressions.forEach( imp => {
					const perc = parseFloat( imp.writtenPercentage );
					if( !isNaN(perc) ) {
						totPerc += perc;
					}
				});
			});
		});
		return totPerc.toString();
	}

	removeZeroEntries() : any {
		const result : any = JSON.parse( JSON.stringify( this.recordLinesPayload ) );
		result.items.forEach( itm => {
			itm.lines.forEach( line => {
				line.impressions = line.impressions.filter( imp => { return parseFloat(imp.writtenPercentage) > 0.0 });
			});
			itm.lines = itm.lines.filter( line => { return line.impressions.length > 0 } );
		});
		result.items = result.items.filter( itm => { return itm.lines.length > 0 });

		this.logPayload( this.recordLinesPayload, "original" );
		this.logPayload( result, "filtered" );
		return result;
	}

	logPayload( payload, msg ) 
	{
		console.log( `logPayload ${msg}`);
		payload.items.forEach( (itm,itmidx) => {
			itm.lines.forEach( (line,lidx) => {
				line.impressions.forEach( ( imp, iidx ) => {
					console.log( `item ${itmidx} : line ${lidx} : imp ${iidx} ${imp.writtenPercentage}%`)
				})
			});
			itm.lines = itm.lines.filter( line => { return line.impressions.length > 0 } );
		});
	}

	static mockProvenance() : MSStorer.Provenance {
		const result : MSStorer.Provenance = {dataHash: "",provHash: "",system: "",userID: "",version: "",writtenAt: ""};
		return result;
	}
	static mockSectionDoc() : MSStorer.Root {
		const provenance =  OPDataModel.mockProvenance()
		const result: MSStorer.Root = { _id: "", _rev: "", associatedRootID: "", channels: [], createdAt: "", updatedAt: "", type: "", sections: [], provenance:provenance};
		return result;
	}
	static mockSection() : MSStorer.Section {
		return { index: 0, leadUnderwriter: "", leadUnderwriterChannel: "", multiSectionId: OPHardCoded.SECTION0,  multiSectionName: "" }
	}
}