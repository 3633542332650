import React, { useState, useEffect, ReactElement } from 'react'
import DataTable from 'react-data-table-component'
import { utils } from '../FromElsewhere/utils'
import { riskFilter } from '../helpers/riskFilter'
import { Session } from '../Session';

const RiskColumns = [
    {
        name: 'Insured Name',
        selector: 'InsuredName',
        sortable: true,
    },
    {
        name: 'UMR',
        selector: 'umr',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'PrettyStatus',
        sortable: true,
    },
    {
        name: 'Earliest',
        selector: 'earliest',
        sortable: true,
    },
    {
        name: 'Latest',
        selector: 'latest',
        sortable: true,
    },
]

export const RiskGrid = ( { input, pickFn, singleLine = false } ) => {
    const [grail, setGrail] = useState('')
    const [summaries, setSummaries] = useState<any>( [] )

    useEffect(() => {
        setSummaries( riskFilter.match( input, grail ).map( s => {
            s.PrettyStatus = utils.prettyStatus( s.Status ); 
            return s;
        }) );
    }, [ input ])

    const onChange = (event) => {
        const value = event.target.value
        setGrail( value )
        setSummaries( riskFilter.match( input, value ) )
    }

    const ExpandedSummary = ({ data }) => {
        return (
            <ExpandedRisk items={data}/>
        )
    }
    
    const ExpandedRisk = ( { items }) => {
        const filtered = filterForExpanded( items.iterations );
        return (
            <div className='ExpandedAdviceBlock'>
                { filtered.sort( ( x, y ) => { return y.riskdate.localeCompare(x.riskdate) }).map( (item, index) => (
                        <div key={index} id={index}><span className='MyLink' onClick={() => pickFn(item)} >{utils.formatDateTime(item.riskdate)}&nbsp;
                        { utils.prettyStatus(item.Status || '')} {otherParties(item)}</span></div>
                    ))
                }
            </div>
        )
    }

    const otherParties = item => {
        if( !Array.isArray( item.channels ) || item.channels.length < 2 ) {
            return "";
        }
        const result : string[] = [];
        item.channels.forEach( c => {
            const parts : string[] = c.split( "_");
            if( parts[0].toUpperCase() != Session.corporate.companyId ) {
                result.push( parts.map( x => { return x.toUpperCase(); } ).join( " / ") );
            }
        });
        return result.join( ", ");
    }

    const filterForExpanded = iterations => {
        if( Session.corporate.role != "broker" ) {
            return iterations;
        }
        const result : any[] = [];
        iterations.forEach( item => {
            let include = true;
            if( item.id && item.id.includes( "::SIGNED::") ) { include = false; }
            if( item.id && item.id.includes( "::FO:") ) { include = false; }
            if( include ) { 
                console.log( `channels:`);
                console.log( item.channels );
                result.push( item ); 
            }

        });
        return result;
    }

    const rowClicked = row => {
        if( singleLine )
            pickFn(row.iterations[0])
    }

    return (
        <>
        {/* <div>Search for <input value={grail} onChange={onChange} placeholder='UMR or Insured Name' /></div> */}
        <DataTable
                    columns={RiskColumns}
                    data={summaries}
                    defaultSortField="InsuredName"
                    defaultSortAsc={true}
                    expandableRows={!singleLine}
                    expandableRowsComponent={<ExpandedSummary data={summaries} />}
                    onRowClicked={rowClicked}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 50, 100, 500]}
                    // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    persistTableHead
                    className={singleLine ? 'RiskGridSingleLine' : 'RiskGridExpandable'}
                />
        </>
    )
}



/*
export const Titlebar = ({ module }) => {
    const session = getSessionDetails()
    return (
        <div className="Titlebar">
            {module.header}
            <div className="Session-info">
                👤 {session.account.userName}
            </div>
        </div>
    )
}
*/