import React, { useState, useEffect } from 'react'
import { CombinedSets } from "../../helpers/CombinedSets";
import { utils } from '../../FromElsewhere/utils';
import { MSStorer } from '../../rebrowse/multi.section.storer';
import { SDDataModel } from './SDDataModel';

export const LineSetViewer = ( { sddm }) => {
	const signedSets = sddm.facilityLineSets;
	const sections = sddm.facSections;
	return (
		<>
		<div>
			{signedSets.map( (s,idx) => { 
				return( <LineSetItem key={idx} lineset={s} sections={sections} /> )
			})}
		</div>
		</>
	)
}
const LineSetItem = ( { lineset, sections } ) => {
	const x : CombinedSets.Root = lineset;
	return (
		<>
		<span className='LightGray SmallText'>{x._id}</span><br/>
		{x.contents.map( (c,idx) => { return( <LineSetContent key={idx} content={c} sections={sections} />)})}
		</>
	)
}


const LineSetContent = ( { content, sections } ) => {
	const x : CombinedSets.Content = content;
	const describeSections = ( sectionIdentifiers, sections ) => {
		const x: MSStorer.Root = sections;
		const parts: string[] = [];
		sectionIdentifiers.forEach( s => {
			const section = x.sections.filter( s2 => { return s2.multiSectionId == s })[0];
			parts.push( s.toString() + ":" + section?.multiSectionName || "Unknown" );
		});
		return parts.join( ", ");
	}
	return (
		<>
		{x.businessUnit} {utils.makePlural(x.impressions.length,"impression")}
		&nbsp;<b>{SDDataModel.describeRole(x.role)}</b>
		&nbsp;on {utils.makePlural(x.sectionIdentifiers.length,"section")} {describeSections(x.sectionIdentifiers,sections)}<br/>
		<ul>
		{x.impressions.map( (c,idx) => { return( <LineSetImpression key={idx} impression={c}/>)})}
		</ul>
		</>
	)
}
const LineSetImpression = ( { impression } ) => {
	const x : CombinedSets.Impression = impression;
	return (
		<>
		<li className='SmallText' >{x.signedLinePercentageString}% {x.stamp.bureauMarket} {x.stamp.bureauMarketCode} {x.stamp.businessUnit}
		&nbsp;<span className='LightGray'>{x.stamp.uniqueID}</span>
		</li>
		</>
	)
}