import React, { useState, useEffect } from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap'
import { Query, whitespace } from '../../helpers/whitespace'
import './Testers.css'
import { PresetEndpoints } from './PresetEndpoints'

export const EndpointList = ( props : any ) => {
    return (
        <select className='FullWidth' onChange={props.callback}>
            {props.values.map( (item:any, idx:any) => { 
                return <option key={idx} value={item.url}>{item.description} : {item.url}</option>
            })}
        </select>
    )
}

export const Testers = () => {
    const [url, setURL] = useState( '/api/user/myDetails' )
    const [payload, setPayload] = useState( '' )
    const [comments, setComments] = useState<string[]>( [] )
    const [jsonResult, setJSONResult] = useState( '' )
    const [IC, setIC] = useState( '' )
    const [REV, setREV] = useState( '' )
    const [MRCHEADING, setMRCHEADING] = useState( '' )

    const onChangeURL = (event) => {
        showChosenEndpoint( event.target )
    }
    const listCallback = ( ob ) => {
        showChosenEndpoint( ob.target )
    }

    const changePayload = ( event ) => {
        setPayload( event.target.value )
    }

    const showChosenEndpoint = ( ob ) => {
        console.log( ob.value )
        setURL( ob.value )
        const endpoint = PresetEndpoints.filter( x => x.url === ob.value )[0];
        if( endpoint ) {
            console.log( endpoint );
            setPayload( endpoint?.payload ? JSON.stringify( endpoint.payload, null, 2 ) : "" )
            setComments( endpoint?.comments || [] )    
        }
    }
    const presetEndpoints = [
        { 
            url: '/api/user/myDetails', 
            description: 'Details of the user including teams' 
        },
        { 
            url: '/api/risks', 
            description: 'List of all risks' 
        }
    ]

    const replaceVariables = ( input ) => {
        return input.replaceAll( '$IC', IC ).replaceAll( '$MRCHEADING', MRCHEADING )
    }
    const setPayloadToLastOutput = () => {
        setPayload( jsonResult );
    }

    const fixJSONorCSV = ( response ) => {
        if( typeof( response ) == "string") {
            return response;            
        } else {
            return JSON.stringify( response, null, 4 );
        }
    }

    const callURL = () => {
        const filled_url = replaceVariables(url);
        let payload_to_send = null;
        if( payload ) {
            const filled_payload = payload.replaceAll( "[[REV]]", REV );
            payload_to_send = JSON.parse( filled_payload );
        }
        const method = payload_to_send ? Query.POST : Query.GET;

        console.log( filled_url );
        console.log( payload_to_send );

        whitespace(method, filled_url, payload_to_send ).then( (response) => {
            let result = ''
            if( response?.data ) {
                result = fixJSONorCSV( response.data );
            } 
            else if( response ) {
                result = fixJSONorCSV( response );
            } 
            else {
                result = JSON.stringify( { url : replaceVariables(url), msg : 'Error'}, null, 4 )
            }
            setJSONResult( result )
        })
    }
    return (
        <>
        <Container>
            <Row>
                <Col xs={12}><div className='DlgSubTitle'>Presets</div></Col>
            </Row>
            <Row>
                <Col xs={12}><EndpointList values={PresetEndpoints} callback={listCallback} /></Col>
            </Row>
            <Row>
                <Col xs={12}><div className='DlgSubTitle'>Endpoint</div></Col>
            </Row>
            <Row>
                <Col xs={12}><input className='FullWidth' value={url} onChange={onChangeURL}/>
                { comments.map( comment => <div className='EndpointComment'>{comment}</div> ) }
                </Col>
            </Row>
            <Row>
                <Col xs={12}><div className='DlgSubTitle'>Variables</div></Col>
            </Row>
            <Row>
                <Col xs={2}>IC</Col>
                <Col xs={10}><input className='FullWidth' value={IC} onChange={(event) => setIC(event.target.value)}/></Col>
            </Row>
            <Row>
                <Col xs={2}>REV</Col>
                <Col xs={10}><input className='FullWidth' value={REV} onChange={(event) => setREV(event.target.value)}/></Col>
            </Row>
            <Row className='Above1'>
                <Col xs={2}>MRCHEADING</Col>
                <Col xs={10}><input className='FullWidth' value={MRCHEADING} onChange={(event) => setMRCHEADING(event.target.value)}/></Col>
            </Row>
            {payload &&
            <>
            <Row>
                <Col xs={12}><div className='DlgSubTitle'>Payload <span className='MyLink' onClick={() => setPayloadToLastOutput()}>Copy last output</span> </div></Col>
                <Col xs={12}><div className='Above12 JSONResult'>
                    <textarea className='PayloadTA' value={payload} onChange={changePayload} />
                    </div>
                </Col>
            </Row>
            </>
            }
        
        </Container>
        
        <div className='Above12'>
        <Button onClick={() => callURL()}>Run</Button>
        &nbsp;<Button onClick={() => setPayload("{}")}>Empty Object Payload</Button>
        &nbsp;<Button onClick={() => setPayload("[]")}>Empty Array Payload</Button>
        &nbsp;<Button onClick={() => setPayload("")}>Clear Payload</Button>
        </div>
        <div className='Above12 JSONResult'>
            <pre>
                {jsonResult}
            </pre>
        </div>
        </>
    )
}