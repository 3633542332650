import { stat } from "fs";
import { CombinedSets } from "../../helpers/CombinedSets";
import { Market } from "../../helpers/Market";
import { JMRC } from "../../rebrowse/jmrc";
// import { Market } from "../../helpers/Market";
// import { WhitespaceAPI } from "../../helpers/whitespace";
import { MSStorer } from "../../rebrowse/multi.section.storer";
import { ShowInstruction } from "./ShowInstruction";

export const SIHardCoded = {
	UNCHOSEN: "UNCHOSEN",
	LETUWRCHOOSE: "LETUWRCHOOSE",
	noFacGuidanceYet: "noFacGuidanceYet",
	shownToLeader : "shownToLeader",
	SECTION0 : "SECTION0"
}


export interface SDTFCarrier {
	role: string
	show: boolean
	suggestedStampIDs: string[]
	valueIsLeaderLineGuidance: boolean
	value: string
}

export interface SDTFSection {
    sectionID?: string;
    facilitySectionID?: string;
    carriers: { [key: string]: SDTFCarrier };
    leader?: boolean;
}

export interface SDTFPayload {
	declarationID : string
	facilityID: string
	_rev: string
	sections: SDTFSection[]
}

export interface BNFFacilityID {
	facilityID : string
	sectionID? : string
}

export interface BNFPayload {
	declarationID: string
	facilityID: BNFFacilityID
	sectionID?: string
	// suggestedStampID: any
	suggestedStampID: { [key: string]: string }
}

export class SDDataModel {
	market?: Market;
	decID = "";
	facID = "";
	decSectionID = "";
	facSectionID = "";
	decSections : MSStorer.Root = SDDataModel.mockSectionDoc();
	facSections : MSStorer.Root = SDDataModel.mockSectionDoc();
	declarationMRC? : JMRC.Root;
	facilityLineSets : CombinedSets.Root[] = [];
	facilityGuidance: any[] = [];
	callback: any;

	constructor( fn : any ) {
		this.callback = fn;
	}

	version() : string {
		return "0.0";
	}

	describe() : string[] {
		const lines: string[] = [];
		lines.push( `Corporates : ${this.market?.corporates.length || "none"}`);
		lines.push( `Declaration MRC : ${this.declarationMRC?._id || "none"} ${this.declarationMRC?._rev || ""}`);
		lines.push( `Declaration Sections : ${this.decSections?.sections.length || "none"}`);
		lines.push( `Facility Sections : ${this.facSections?.sections.length || "none"}`);
		lines.push( `Facility Line Sets : ${this.facilityLineSets.length}`);
		lines.push( `Facility Guidance : ${this.facilityGuidance.length}`);
		return lines;
	}

	buildSDTF() : any {
		const result_sdtf : any = { declarationID: "", facilityID: "", _rev: "", sections: [] }; 
		result_sdtf.declarationID = this.declarationMRC?._id || "";
		result_sdtf._rev = this.declarationMRC?._rev || "";
		result_sdtf.facilityID = this.facID;
		result_sdtf.sections = [];

		this.facSections.sections.forEach( fs => {
			this.decSections.sections.forEach( ds => {
				const carriers : any = {};
				this.facilityLineSets.forEach( zi => {
					zi.contents.forEach( zc => {
						if( zc.sectionIdentifiers.includes( fs.multiSectionId ) || fs.multiSectionId == SIHardCoded.SECTION0 ) {
							const carrier : SDTFCarrier = { role: zc.role, show: false, suggestedStampIDs:[], value: "0", valueIsLeaderLineGuidance: false }
							carriers[ zc.stamperChannel ] = carrier;
							// console.log( `pickFacility f:${fs.multiSectionId} d:${ds.multiSectionId} ${zc.stamperChannel} / ${zc.role}`);	
						}
					})
				})
				const section : SDTFSection = { carriers: carriers, leader: false, sectionID: ds.multiSectionId, facilitySectionID: fs.multiSectionId };
				result_sdtf.sections.push( section );												
			});
		});
		this.callback( `SDTF built with ${result_sdtf.sections.length} sections`);
		return result_sdtf;
	}

	describeState(declarationSectID : string, facilitySectID : string) : string {
		if( this.facilityGuidance.length == 0 ) {
			return "No Facility Guidance yet";
		}
		const role = this.getGuidanceRole( declarationSectID, facilitySectID );
		if( !role ) {
			return `No guidance for declarationSectID ${declarationSectID} on facility section ${facilitySectID}`;
		}
		return `State is ${role.state}, shown to leader at ${role.shownToLeaderAt}`;
	}

	getGuidanceState( declarationSectID : string, facilitySectID : string ) : string {
		const role = this.getGuidanceRole( declarationSectID, facilitySectID );
		if( !role ) {
			return SIHardCoded.noFacGuidanceYet;
		}
		return role.state;
	}

	getGuidanceRole( declarationSectID : string, facilitySectID : string ) : any {
		// FROM THE BUS - if facilitySectID IS SECTION0 look for no section; check for facility id in the fg._id
		const pattern =  "::FGUI::" + this.facID + ( facilitySectID == SIHardCoded.SECTION0 ? "" : "__section__" + facilitySectID ) + "$";
		console.log( `getGuidanceRole seeking ${pattern} amongst ${this.facilityGuidance.length} FGUIs : ` + this.facilityGuidance.map( fg => { return fg._id }).join( " : "));
		const rgx = RegExp( pattern );	// `section__${facilitySectID}` );
		if( this.facilityGuidance.length == 0 ) {
			return null;
		}
		const guidance = this.facilityGuidance.filter( fg => { return fg._id.match( rgx ); })[0];
		if( guidance ) {
			const role = guidance.roles.filter( (r:any) => { return ( r.sectionID == declarationSectID || !r.sectionID ); } )[0];
			console.log( JSON.stringify( role, null, 4 ));
			return role;
		}
		return null;
	}

	summariseSDTF() : string[] {
		const lines: string[] = [];
		const sdtf = this.buildSDTF();
		sdtf.sections.forEach( (s:SDTFSection) => {
			lines.push( `Sections s:${s.sectionID} f:${s.facilitySectionID} l:${s.leader}`);
			if( s.sectionID == this.decSectionID && s.facilitySectionID == this.facSectionID ) {
				Object.keys(s.carriers).forEach( key => {
					const c = s.carriers[key];
					lines.push( ` - Carrier ${key} ${c.value}% ${c.role}`);
				});	
			}
		});
		return lines;
	}

	requiresTabs() : boolean {
		return this.decSections.sections.length > 1;
	}
	getTabLabels() : string[] {
		return this.decSections.sections.map( s => { return `${s.multiSectionId}: ${s.multiSectionName}`});
	}

	hasBeenShownToLeader( decSectionID : string, facSectionID : string ) {
		const role = this.getGuidanceRole( decSectionID, facSectionID );
		if( role )
			return true;
		// if( this.fgui.roles ) {
		// 	const role = this.fgui.roles.filter( r => { return( r.sectionID == decSectionID.toString() ) })[0];
		// 	return role ? true : false;
		// }
		return false;
	}

	prepareInstructions( decSectionID : string, facSectionId : string ) {	// , facRole
		const state = this.getGuidanceState( decSectionID, facSectionId );
		console.log( `prepareInstructions d:${decSectionID} f:${facSectionId} state:${state}`);	// facRole:${facRole?.state || "no facRole.state"}`)
		const leaders : ShowInstruction[] = [];
		const agparties : ShowInstruction[] = [];
		const notparties : ShowInstruction[] = [];

		const ggRoles : any = this.getGuidanceRole( decSectionID, facSectionId );
		console.log( "prepareInstructions getGuidanceRole picked up");
		console.log( JSON.stringify( ggRoles ));

		const sTable : any = {};
		if( state != "shownToLeader" ) {
			this.facilityLineSets.forEach( s => {
				const forThisSection = facSectionId == SIHardCoded.SECTION0 ? s.contents : s.contents.filter( s => { return s.sectionIdentifiers.includes( facSectionId )});
				let proposedLineGuidance = -1;
				forThisSection.forEach( c => { 
					// if( this.fgui.roles ) {
					// 	console.log( JSON.stringify( this.fgui.roles, null, 4 ));
					// 	const role = this.fgui.roles.filter( r => { return( r.sectionID == decSectionID.toString() ) })[0];
					// 	if( role ) {
					// 		// console.log( `FGUI supplies this. Looking for ${c.stamperChannel} in additionalCarrierInfo`);
					// 		// console.log( JSON.stringify( role, null, 4 ));
					// 		const info = role.additionalCarrierInfo[ c.stamperChannel ];
					// 		if( info && info.proposedLineGuidance ) {
					// 			proposedLineGuidance = info.proposedLineGuidance || -1;
					// 			console.log( `proposedLineGuidance set to ${proposedLineGuidance}`);
					// 		} 
					// 	}
					// }

					if( ggRoles ) {
						const info = ggRoles.additionalCarrierInfo[ c.stamperChannel ];
						if( info && info.proposedLineGuidance ) {
							proposedLineGuidance = info.proposedLineGuidance || -1;
							console.log( `proposedLineGuidance read from role2 set to ${proposedLineGuidance}`);
						} 
						const cRole = ggRoles.carriers[ c.stamperChannel ];
						if( cRole ) {
							console.log( `changing c.role ${c.role} to ${cRole} because ` + JSON.stringify(ggRoles));
							c.role = cRole;
						}
					}
					console.log( `prepareInstructions:${c.businessUnit}=${c.role}:proposedLineGuidance=${proposedLineGuidance}`);	//${facilityRole != null ? facilityRole.state : "null facilityRole" } `);
					const totperc = c.impressions.reduce( (x,y) => { return x + parseFloat(y.signedLinePercentageString)}, 0.0 );
					console.log( `adding ${c.stamperChannel} ${c.role} ${totperc}=sum(${c.impressions.map(i=>{return i.signedLinePercentageString}).join(",")})`);
					sTable[ c.stamperChannel ] = SIHardCoded.UNCHOSEN;

					const inst = this.makeInstruction( c, state, proposedLineGuidance );

					if( c.role == "leader" ) {
						if( !this.hasBeenShownToLeader( decSectionID, facSectionId ))
							leaders.push( inst );
					} else if( c.role == "agreementParty") { 
						agparties.push( inst ); 
					} else { 
						notparties.push( inst ); 
					}
				});	
			});
		}
		const iarray = [ ... leaders, ... agparties, ... notparties ];
		console.log( `prepareInstructions now has ${iarray.length} instructions`);
		return iarray;
	}

	makeInstruction( c : CombinedSets.Content, state : string, proposedLineGuidance : number ) : ShowInstruction {
		const heading = ( this.market?.corpFromChannel( c.stamperChannel)?.name || "" )
			+ " / "
			+ ( this.market?.teamFromChannel( c.stamperChannel)?.name || "" )
			+ " : "
			+ ( SDDataModel.describeRole( c.role ));
		return new ShowInstruction( c, heading, state, proposedLineGuidance );
	}

	static describeRole( role : string ) : string {
		if( role == "leader" )	return "Leader";
		if( role == "agreementParty" )	return "Agreement Party";
		if( role == "notifyParty" )	return "Notify Party";
		if( role == "nonNotifyParty" )	return "Non-Notify Party";
		if( role == "doesNotApply" )	return "Does Not Apply";
		return role;
	}

	static mockProvenance() : MSStorer.Provenance {
		const result : MSStorer.Provenance = {dataHash: "",provHash: "",system: "",userID: "",version: "",writtenAt: ""};
		return result;
	}
	static mockSectionDoc() : MSStorer.Root {
		const provenance =  SDDataModel.mockProvenance()
		const result: MSStorer.Root = { _id: "", _rev: "", associatedRootID: "", channels: [], createdAt: "", updatedAt: "", type: "", sections: [], provenance:provenance};
		return result;
	}
	static mockSection() : MSStorer.Section {
		return { index: 0, leadUnderwriter: "", leadUnderwriterChannel: "", multiSectionId: SIHardCoded.SECTION0,  multiSectionName: "" }
	}
}

