import { Corporate, Team } from "../Session";
// import { WhitespaceAPI } from "./whitespace"

export class Market {
	corporates: Corporate[] = [];

	static makeUsingCorporates( content : Corporate[] ) {
		const result = new Market();
		result.corporates = content.slice();
		return result;
	}

	findCorpForStamp = ( stampID : string ) : Corporate | null => {
		let result : Corporate | null = null;
		this.corporates.forEach( c => {
			const stamp = c.stamps?.filter( s => { return s.uniqueID == stampID; })[0];
			if( stamp && result == null ) {
				result = c;
			}
		});
		return result;
	}
	
	// fetch = async () : Promise<number> => {
	// 	return this.api( "/api/shared/corporate").then( response => {
	// 		this.corporates = response.data;
	// 		console.log( `/api/shared/corporate filled in ${this.corporates.length} corporates`);
	// 		return this.corporates.length;
	// 	});
	// }

	corpFromChannel = ( channel : string ) : Corporate | null => {
		const corpID = channel.split( "_" )[0].toUpperCase();
		return this.corporates.filter( c => { return c.companyId == corpID })[0];
	}

	teamFromChannel = ( channel : string ) : Team => {
		let retval : Team = { teamId: "", channel: "", name: ""}
		this.corporates.forEach( c => {
			c.teams.forEach( t => {
				if( t.channel == channel ) {
					retval = t;
				}
			})
		})
		return retval;
	}
}