import { DefinedData, DDExport, DDDataPoint } from '../../FromElsewhere/DefinedData';

export class MRCV3TestOutcome {
	static Pass = "Pass";
	static Fail = "Fail";
	static OptionalFail = "OptionalFail";
	static OptionalSuccess = "OptionalSuccess";
	static OptionalAbsent = "OptionalAbsent";
	static ConditionalAbsent = "ConditionalAbsent";
	static NotApplicable = "NotApplicable";
	static Unknown = "Unknown";
}

export class MRCV3Requirement {
	static Blank = "Blank";
	static Optional = "Optional";
	static Conditional = "Conditional";
	static Mandatory = "Mandatory";
}
/* 10 combinations found (of possible 16)
Blank / Blank
Conditional / Blank (= Conditional/Mandatory #113 ContractualExchangeRate, #218 State of Filing)
Conditional / Conditional
Conditional / Mandatory
Conditional / Optional (#7 Sections)
Mandatory / Blank
Mandatory / Mandatory
Mandatory / Mandatory-Conditional (Settlement Due Date)
Optional / Conditional
Optional / Optional
*/





export class MRCV3ByHeading {
	constructor( heading : string ) {
		this.mrcHeading = heading;
	}
	mrcHeading : string;
	tests: MRCV3Test[] = [];
	add( test : MRCV3Test ) {
		this.tests.push( test );
	}
}

export class MRCV3Test {

	constructor( heading : string, tag : string, headingRequirement : string, tagRequirement : string, rowNo : number ) {
		this.mrcHeading = heading;
		this.tag = tag;
		// this.allowMultiple = options.allowMultiple || false;
		this.headingRequirement = headingRequirement;
		this.tagRequirement = tagRequirement;
		this.rowNo = rowNo;
	}
	mrcHeading : string;
	tag : string;
	headingRequirement : string;
	tagRequirement : string;
	rowNo : number;
	value = "";
	comment = "";
	outcome = MRCV3TestOutcome.Unknown;

	getReq() : string {
		return ( this.headingRequirement[0] || "-") + "," + ( this.tagRequirement[0] || "-")
	}
	check( ddExport : DDExport, mrcHeadings : string[] ) {
		const headList = this.mrcHeading.split( "," ).filter( x => { return x.trim().length > 0 }).map( x => { return x.trim().replaceAll(" ","") } );
		const tagList = this.tag.split( "," ).filter( x => { return x.trim().length > 0 }).map( x => { return x.trim() } );
		const tested : any = [];
		const verbose = [ 250, 251 ];

		headList.forEach( heading => {
			tagList.forEach( tag => {
				const t = { heading: heading, tag: tag, outcome : "", value : "", comment: ""}
				const grail = { mrcHeading : heading, tag: tag, tidy: true };
				if( !mrcHeadings.includes( heading ) && this.headingRequirement == "Optional") {
					t.outcome = MRCV3TestOutcome.OptionalAbsent;
					t.value = "-";
					t.comment = `No heading ${heading}`;
				} else {
					const matches = DefinedData.findAll( ddExport.definedData, grail );
					if( matches.length == 0 ) {
						if( this.tagRequirement == MRCV3Requirement.Conditional ) {
							t.outcome = MRCV3TestOutcome.ConditionalAbsent;
							t.value = "-";
							t.comment = `No tag ${tag}`;
						} else {
							t.outcome = this.setFail();
							t.value = "** MISSING **";
							t.comment = "No defined data tag";	
						}
					}
					else if( matches.length == 1 ) {
						t.outcome = MRCV3TestOutcome.Pass;
						t.value = matches[0].value;
						t.comment = "OK";
					}
					else {
						t.outcome = MRCV3TestOutcome.Pass;
						t.comment = `${matches.length} values`;
						t.value = matches.map( m => { return m.value } ).join( ", ");
					}
				}
				if( verbose.includes( this.rowNo ) ) {
					console.log( t );
				}
				tested.push( t );
			});	
		});
		if( tested.length == 1 ) {
			this.outcome = tested[0].outcome;
			this.comment = tested[0].comment;
			this.value = tested[0].value;

			if( this.tag == "N/A" && this.outcome == MRCV3TestOutcome.Fail ) {
				this.outcome = MRCV3TestOutcome.NotApplicable;
			}
		}
		else {
			const countPass = tested.filter( t => { return t.outcome == MRCV3TestOutcome.Pass }).length;
			const countOptionalFail = tested.filter( t => { return t.outcome == MRCV3TestOutcome.OptionalFail }).length;
			if( countPass > 0 )		this.outcome = MRCV3TestOutcome.Pass;
			else if( countOptionalFail > 0 ) this.outcome = MRCV3TestOutcome.OptionalFail;
			else this.outcome = MRCV3TestOutcome.Fail;

			this.comment = tested.map( t => { return t.comment }).join( ", ");
			this.value = tested.map( t => { return t.value }).join( ", ");
		}
	}

	setFail() : string {
		if( this.headingRequirement == "Optional" && this.tagRequirement == "Optional") {
			return MRCV3TestOutcome.OptionalFail;
		}
		return MRCV3TestOutcome.Fail;
	}


}