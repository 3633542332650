import { PageLoading, NoResultsFound } from '../../components/Loading'
import dayjs, { Dayjs } from "dayjs";
import React, { useState, useEffect } from 'react'
import { RiskGrid } from '../../components/RiskGrid';
import { WhitespaceAPI, whitespace, Query } from '../../helpers/whitespace';
import { riskFilter } from '../../helpers/riskFilter';
import { OtherLogo } from '../../components/CorporateLogo' 
import { DefinedData, DDDataPoint } from '../../FromElsewhere/DefinedData';
import { Modal, Col, Row, Button, Tab, Tabs, Form, Container } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications'
import { Session } from "../../Session";
import './Polka.css'
import * as xml2js from 'xml2js';
import { PKFigure } from './PKFigure';
import { SPANEnum, SPANAdvice, SPANSectionCarrierPair } from './PaymentAdvice';
import { utils } from '../../FromElsewhere/utils';
import { Market } from '../../helpers/Market';
import { PKExtended, PKFeedback, PKObservation } from './PaymentFeedback';
import { FeedbackDialog } from './FeedbackDialog';

const TEST_XML = `<ns1:input xmlns:ns1="ns1:test" xmlns:ns2="ns2:test">
<ns1:element id="1">
	<ns2:field1>1</ns2:field1>
	<ns2:field2>2</ns2:field2>
	<ns2:field3>3</ns2:field3>
</ns1:element>
<ns1:element id="2">
	<ns2:field1>4</ns2:field1>
	<ns2:field2>5</ns2:field2>
	<ns2:field3>6</ns2:field3>
</ns1:element>
</ns1:input>
`;

let market : Market;

export const Polka = ({ refresh }) => {
	const [loaded, setLoaded] = useState(false)
	const [negotiation,setNegotiation] = useState(false);
    const [risks, setRisks] = useState<any[]>([])
    const [xmlInput, setXMLInput] = useState(TEST_XML);
	const [jsonOutput, setJSONOutput] = useState("");
    const [selectedRisk, setSelectedRisk] = useState<any>( null );
	const [definedData, setDefinedData] = useState<any>( null );
	const [activeTabKey, setActiveTabKey] = useState("chooseRisk");
	const [scpArray, setSCPArray] = useState<SPANSectionCarrierPair[]>([]);
	const [span, setSPAN] = useState( new SPANAdvice() );
	const [ carrierCompanyId, setCarrierCompanyId ] = useState('');
	const [allFeedback, setAllFeedback] = useState<PKFeedback[]>([]);
	const [spanFeedback, setSPANFeedback] = useState<PKExtended[]>([]);
	// const [comment, setComment] = useState("");

	const [showFeedbackDialog,setShowFeedbackDialog] = useState(false);
	const [dlgItem,setDlgItem] = useState("");
	const [dlgStartFigure, setDlgStartFigure] = useState( NaN );
	const [dlgFeedbacks,setDlgFeedbacks] = useState<PKExtended[]>([]);

	useEffect(() => {
		WhitespaceAPI.get( "/api/shared/corporate").then( response => {
			market = Market.makeUsingCorporates( response.data );
				WhitespaceAPI.get( '/api/summary').then(response => {
				setRisks( riskFilter.groupRisksSummary( response.data ))
				setLoaded(true);
			});
		});
    }, [refresh])

	const pickRisk = ( risk ) => {
        WhitespaceAPI.get(`/api/risks/${risk.id}/getExtendedMRC`).then( response => {
            setSelectedRisk( response.data );
			const rootID = utils.rootIC( risk.id );
			WhitespaceAPI.get( `/api/risks/${rootID}/related/RWComment`).then( response => {
				processFeedbackJSON( rootID, response.data );
				WhitespaceAPI.get( `/api/v22.04/data/${risk.id}`).then( response => {
					setDefinedData( response.data );
	
					let array = SPANSectionCarrierPair.fromDefinedData( response.data, market );
					if( Session.corporate.role == "underwriter") {
						array = array.filter( itm => { return itm.companyId == Session.corporate.companyId });
					}
					setSCPArray( array );
					if( array.length > 0 ) {
						setSPAN( SPANAdvice.fromDefinedData( response.data, "", "", "", null ));
						setActiveTabKey( "chooseSPAN");
					} else {
						setSPAN( SPANAdvice.fromDefinedData( response.data, "", "", "", null ));
						setActiveTabKey( "reviewAdvice");
					}
				});
			});
        });
    }

	const refreshEverything = () => {
		const riskid = selectedRisk.platformReferences.RiskID;
        WhitespaceAPI.get(`/api/risks/${riskid}/getExtendedMRC`).then( response => {
			const selRisk : any = response.data;
            setSelectedRisk( selRisk );
			const rootID = utils.rootIC( riskid );
			WhitespaceAPI.get( `/api/risks/${rootID}/related/RWComment`).then( response => {
				const feedbacks = processFeedbackJSON( rootID, response.data );
				WhitespaceAPI.get( `/api/v22.04/data/${riskid}`).then( response => {
					const defData : any = response.data;
					setDefinedData( defData );
	
					let array = SPANSectionCarrierPair.fromDefinedData( response.data, market );
					if( Session.corporate.role == "underwriter") {
						array = array.filter( itm => { return itm.companyId == Session.corporate.companyId });
					}
					setSCPArray( array );
					if( array.length > 0 ) {
						let idx = -1;
						array.forEach( (itm,i) =>{
							if( itm.sectionID == span.sectionID && itm.companyId == span.uwrCompanyId && itm.stamp?.uniqueID == span.stamp?.uniqueID ) {
								idx = i;
							}
						});
						if( idx >= 0 ) {
							console.log( `refreshEverything idx=${idx} from ${array.length} scp items`);
							chooseSPAN( idx, selRisk, defData, feedbacks, array );
						}
					} else {
						setSPAN( SPANAdvice.fromDefinedData( response.data, "", "", "", null ));
					}
				});
			});
        });
	}

	// const refreshEverything = () => {
	// 	const rootID = utils.rootIC( selectedRisk.platformReferences.RiskID );
	// 	console.log( `refreshEverything on ${rootID}`);
	// 	WhitespaceAPI.get( `/api/risks/${rootID}/related/RWComment`).then( response => {
	// 		const feedbackSet = processFeedbackJSON( rootID, response.data );
	// 		filterFeedbacks( feedbackSet, span );
	// 	});
	// }

	const processFeedbackJSON = ( rootID : string, data : any ) : PKFeedback[] => {
		const feedbacks : PKFeedback[] = [];
		if( data && Array.isArray(data) ) {
			data.forEach( x => {
				if( x.comment ) {
					const feedback = PKFeedback.fromJSON( x.comment );
					if( feedback.riskID.startsWith( rootID ) ) {
						feedbacks.push( feedback );
					}
				}
			});
			console.log( `RWComment : ${feedbacks.length} / ${data.length} comments loaded as feedback`);
		}
		setAllFeedback( feedbacks );	
		return feedbacks;	
	}

	const chooseSPAN = ( idx, selRisk : any = null, defdata : any = null, feedbacks : PKFeedback[] | null = null, array : SPANSectionCarrierPair[] | null = null ) => {
		const scp = ( array === null ? scpArray[idx] : array[idx] );
		const mySelectedRisk = ( selRisk == null ? selectedRisk : selRisk );
		const myDefData = ( defdata == null ? definedData : defdata );
		const myFeedbacks = ( feedbacks == null ? allFeedback : feedbacks );
		const span0 = SPANAdvice.fromDefinedData( myDefData, scp.sectionID, scp.companyId, scp.companyName, scp.stamp );
		const riskName = mySelectedRisk.control?.insuredName || "-";
		const bkrChannel = mySelectedRisk.control?.placingBrokerChannel || "-";
		let uwrChannel = "";
		if( span0.stamp && mySelectedRisk.signedLineSets) {
			mySelectedRisk.signedLineSets.forEach( sls => {
				sls.contents.forEach( c => {
					c.impressions.forEach( imp => {
						if( imp.stamp.uniqueID == ( span0.stamp?.uniqueID || "x" ) ) {
							uwrChannel = c.stamperChannel;
						}
					});
				});
			});
		}
		console.log( `chooseSPAN :${riskName}:${bkrChannel}:${uwrChannel}:`)
		span0.setExtendedFields( riskName, bkrChannel, uwrChannel );
		filterFeedbacks( myFeedbacks, span0 );
		setSPAN( span0 );
		setCarrierCompanyId( scp.companyId );
		setActiveTabKey( "reviewAdvice");
	}

	const filterFeedbacks = ( feedbackSet : PKFeedback[], span : SPANAdvice ) => {
		let feedbacks : PKExtended[] = [];

		if( span.stamp ) {
			console.log( `Checking ${allFeedback.length} feedbacks for ${span.riskIC} / ${span.sectionID} / ${span.stamp.uniqueID}`);
			feedbackSet.forEach( f => { console.log( `Check ${f.riskID} / ${f.sectionID} / ${f.stampID}`)});
			const matching = feedbackSet.filter( f => { return utils.rootIC(f.riskID) == utils.rootIC(span.riskIC) && f.sectionID == span.sectionID && f.stampID == ( span.stamp?.uniqueID || "") });			
			matching.forEach( m => {
				feedbacks = [ ...feedbacks, ...m.getExtended() ];
			});
			// console.log( `Checked ${matching.length} of ${allFeedback.length} passed`);
		}
		span.processObservations( feedbacks.map( x => { return PKObservation.fromObject( x ) } ) );
		setSPANFeedback( feedbacks );
		console.log( `filterFeedbacks sending ${feedbacks.length} feedbacks to setSPANFeedback`);

	}

	// const ddValue = ( tag ) => {
	// 	if( definedData && definedData.definedData && definedData.definedData.length > 0 ) {
	// 		const dp : DDDataPoint | null = DefinedData.findFirst( definedData.definedData, { tag: tag } );
	// 		return dp?.value || "-";
	// 	}
	// 	return "-";
	// }

	const changeInput = ( event ) => {
        setXMLInput( event.target.value )
    }

	const parseInput = () => {
		const ob : any = parseXMLToObject( xmlInput );
		setJSONOutput( JSON.stringify( ob, null, 4 ) );
	}

	const parseXMLToObject = ( xmlText : string ) : any => {
		let retval : any = {};
		const configOptions = { trim: true, attrkey: "myAttributes", explicitArray: false }; 
		try {
			xml2js.parseString(xmlText, configOptions, function (err, result) {
				if(err) console.log(`ERROR:${err}`);
				retval = result;
			});
		} catch (e) {
			console.log(`EXCEPTION:${e}`);
		}
		return retval;
	}

	const ProposedFig = ( { figure } ) => {
		if( !isNaN( figure.O() ) ) {
			const classNames = "FullWidth DebugBackground StrikeThrough RightAlign" + ( figure.O() < 0 ?  " NegativeFigure" : "" );
			return ( <div className={classNames}>{utils.fmt2ps(figure.O())}</div> )
		}
		if( !isNaN( figure.P() ) ) {
			const classNames = "FullWidth DebugBackground Italics RightAlign" + ( figure.P() < 0 ?  " NegativeFigure" : "" );
			return ( <div className={classNames}>{utils.fmt2ps(figure.P())}</div> )
		}
		return ( <div>&nbsp;</div> )
	}

	const MyFig = ( { figure } ) => {
		const classNames = "FullWidth DebugBackground RightAlign" + ( figure.W() < 0 ?  " NegativeFigure" : "" );
		return ( <div className={classNames}>{utils.fmt2ps(figure.W())}</div> )
	}

	const DiscussableRow = ( { title, subtitle, detail, figure, item, bg = false } ) => {
		const classNames = bg ? "SubtleBackground0" : "SubtleBackground1";
		if( subtitle && detail ) {
			return (
				<Row className={classNames}>
					<Col className='col-md-2 ItemTitle'>{title}</Col>
					<Col className='col-md-3'>{subtitle}</Col>
					<Col className='col-md-2'>{detail}</Col>
					<Col className='col-md-2'>{negotiation && <ProposedFig figure={figure} />}</Col>
					<Col className='col-md-2'><MyFig figure={figure} /></Col>
					<Col className='col-md-1'>{ negotiation && item && <FeedbackAlert item={item} /> }</Col>
				</Row>
			)
		} else if( subtitle ) {
			return (
				<Row className={classNames}>
					<Col className='col-md-2 ItemTitle'>{title}</Col>
					<Col className='col-md-5'>{subtitle}</Col>
					<Col className='col-md-2'>{negotiation && <ProposedFig figure={figure} />}</Col>
					<Col className='col-md-2'><MyFig figure={figure} /></Col>
					<Col className='col-md-1'>{ negotiation && item && <FeedbackAlert item={item} /> }</Col>
				</Row>
			)
		} else {
			return (
				<Row className={classNames}>
					<Col className='col-md-5 ItemTitle'>{title}</Col>
					<Col className='col-md-2'>{detail}</Col>
					<Col className='col-md-2'>{negotiation && <ProposedFig figure={figure} />}</Col>
					<Col className='col-md-2'><MyFig figure={figure} /></Col>
					<Col className='col-md-1'>{ negotiation && item && <FeedbackAlert item={item} /> }</Col>
				</Row>
			)
		}
	}

	const showDialog = ( item : string, feedbacks : PKExtended[] ) => {
		setDlgItem( item );
		const fig : PKFigure = span.figs[item];
		if( !fig ) {
			console.error( `showDialog no figure ${item}`);
			return;
		}
		setDlgStartFigure( fig.W() );
		setDlgFeedbacks( feedbacks );
		setShowFeedbackDialog( true );
	}

	const FeedbackAlert = ( { item } ) => {
		const feedbacks = spanFeedback.filter( f => { return f.item == item });
		feedbacks.sort( (a,b) => { return a.createdAt.localeCompare( b.createdAt )});
		const lastRole = feedbacks.length == 0 ? "" : feedbacks[ feedbacks.length - 1 ].role;
		let cName = feedbacks.length > 0 ? "FeedbackDisagree" : "FeedbackNone";
		if( feedbacks.filter( fb => { return fb.agreed; }).length > 0 ) {
			cName = "FeedbackAgree";
		}
		const classNames = "FeedbackAlert " + cName;
		return ( <span className={classNames} onClick={()=> showDialog(item,feedbacks)}>Feedback</span> )
	}

	const CloseDialog = () => {
		setShowFeedbackDialog( false );
	}

	const printSPAN = () => {
		console.log( JSON.stringify( span ) );
	}

	const saveFeedback = ( agreed : boolean, figure : number, comment : string ) => {
		setShowFeedbackDialog( false );
		console.log( `saveFeedback ${agreed} ${figure} ${comment}`);
		const ob = new PKObservation();
		ob.agreed = agreed;
		ob.comment = comment;
		ob.figureProposed = figure;
		ob.item = dlgItem;
		ob.createdAt = utils.formatDateTime( new Date(), "YYYY-MM-DD HH:mm:ss");
		const efb = new PKExtended( Session.userDetails.role, Session.userDetails.username, ob);

		// const feedbacks = [...spanFeedback,efb];
		// setSPANFeedback( feedbacks );

		const fb = PKFeedback.fromExtended( span.riskIC, span.sectionID, span.stamp?.uniqueID || "", efb );

		const payload = { riskID : utils.rootIC(span.riskIC), text : JSON.stringify( fb ), recipient : Session.userDetails.role == "broker" ? span.uwrChannel : span.bkrChannel };
		WhitespaceAPI.post( "/api/comments", payload ).then( response => {
			console.log( JSON.stringify( response.data ) );
			refreshEverything();
		});
	}

	return (
		<>
        <Tabs activeKey={activeTabKey} onSelect={(event) => setActiveTabKey(event || "chooseRisk")}>
            <Tab eventKey="chooseRisk" title="Choose Risk" className='BorderedTab'>
				{!loaded && <PageLoading />}
				{loaded && !risks.length && <NoResultsFound />}
				<div className={`Fadein-wrapper ${risks.length && 'load'}`}>
					<RiskGrid input={risks} pickFn={pickRisk} />
				</div>
			</Tab>
			<Tab eventKey="chooseSPAN" title="Choose SPAN" className='BorderedTab'>
				<div>Whitespace IC : {span.riskIC}</div>
				<div>UMR : {span.policyUMR}</div>
				<div>Insured : {span.insuredName}</div>
				<div>{scpArray.length} distinct section / carrier pairs</div>
				<div>{scpArray.map( (scp,idx) => { 
					return (
						<div key={idx}>
						<div><span className='MyLink' onClick={() => chooseSPAN(idx)}>{scp.sectionID} {scp.sectionName} {scp.companyId} {scp.companyName} {scp.signedPercentage}</span></div>
						{ scp.stamp && 
							<div className='LightGray SmallText' key={1000+idx}>&nbsp;&nbsp;&nbsp;&nbsp;{scp.stamp?.bureauMarket} {scp.stamp?.bureauMarketCode} {scp.stamp?.bureauSubMarket} {scp.stamp?.businessUnit}</div>
						}
						</div>
					)
				})}</div>
			</Tab>
            <Tab eventKey="reviewAdvice" title="Review Advice" className='BorderedTab'>
			<Container>
					<Row>
						<Col className='col-md-4'>
							<div className='CarrierLogo'><OtherLogo companyId={carrierCompanyId} /></div>
						</Col>
						<Col className='col-md-8'>
						<Container>
							<Row>
								<Col className='col-md-12 LightGray'>{span.riskIC} {span.bkrChannel} {span.uwrChannel}</Col>
							</Row>
							<Row>
								<Col className='col-md-6 ItemTitle'>Policy UMR</Col>
								<Col className='col-md-6'>{span.policyUMR}</Col>
							</Row>
							<Row>
								<Col className='col-md-6 ItemTitle'>Broker Policy Ref</Col>
								<Col className='col-md-6'>{span.brokerPolicyRef}</Col>
							</Row>
							<Row>
								<Col className='col-md-6 ItemTitle'>Underwriter Policy Ref</Col>
								<Col className='col-md-6'>{span.uwrPolicyRef}</Col>
							</Row>
							<Row>
								<Col className='col-md-6 ItemTitle'>U/W Year</Col>
								<Col className='col-md-6'>{span.uwrPolicyYear}</Col>
							</Row>
							<Row>
								<Col className='col-md-6 ItemTitle'>Policy Inception Date</Col>
								<Col className='col-md-6'>{span.inceptionDate}</Col>
							</Row>
							<Row>
								<Col className='col-md-6 ItemTitle'>SPAN Date</Col>
								<Col className='col-md-6'>{span.adviceDate}</Col>
							</Row>
							<Row>
								<Col className='col-md-6 ItemTitle'>SPAN Sequence No:</Col>
								<Col className='col-md-6'>{span.sequenceNumber}</Col>
							</Row>
						</Container>
						</Col>
					</Row>

					<Row className='Above12'>
						<Col className='col-md-3 ItemTitle'>Policy Name</Col>
						<Col className='col-md-9'>{span.riskName}</Col>
					</Row>
					<Row>
						<Col className='col-md-3 ItemTitle'>Insured Name</Col>
						<Col className='col-md-9'>{span.insuredName}</Col>
					</Row>
					<Row>
						<Col className='col-md-3 ItemTitle'>Policy Period</Col>
						<Col className='col-md-9'>{span.inceptionDate} to {span.expiryDate}</Col>
					</Row>
					<Row>
						<Col className='col-md-3 ItemTitle'>Policy Type</Col>
						<Col className='col-md-9'>{span.policyType}</Col>
					</Row>
					<Row>
						<Col className='col-md-3 ItemTitle'>Order %</Col>
						<Col className='col-md-9'>{span.orderPercentage}</Col>
					</Row>
					<Row>
						<Col className='col-md-3 ItemTitle'>Class of Business</Col>
						<Col className='col-md-9'>{span.classOfBusiness}</Col>
					</Row>
					<Row>
						<Col className='col-md-3 ItemTitle'>Company</Col>
						<Col className='col-md-9'>{span.uwrCompanyName} <span className='LightGray'>{span.uwrCompanyId}</span></Col>
					</Row>					
					<Row>
						<Col className='col-md-3 ItemTitle'>Stamp</Col>
						<Col className='col-md-9'>
							{span.stamp && 
								<>
								<span>{span.stamp.bureauMarket} {span.stamp.bureauMarketCode} {span.stamp.bureauSubMarket} {span.stamp.businessUnit}</span>
								</>
							}
						</Col>
					</Row>
					<Row>
						<Col className='col-md-3 ItemTitle'>Endorsement Number</Col>
						<Col className='col-md-9'>{span.endorsementNumber}</Col>
					</Row>
					<Row>
						<Col className='col-md-3 ItemTitle'>Premium Type</Col>
						<Col className='col-md-9'>{span.premiumType}</Col>
					</Row>
					<Row >
						<Col className='col-md-3 ItemTitle'>Section</Col>
						<Col className='col-md-9'>{span.sectionID} {span.sectionName}</Col>
					</Row>

					<Row className='Above12 Below12'>
						<Col className='col-md-5 ItemTitle'>&nbsp;</Col>
						<Col className='col-md-2'>&nbsp;</Col>
						<Col className='col-md-2'><div className='FullWidth DebugBackground RightAlign'>{negotiation && <><span className='StrikeThrough'>Original</span> <span className='Italics'>Proposed</span></>}</div></Col>
						<Col className='col-md-2'><div className='FullWidth DebugBackground RightAlign'>{span.currency}</div></Col>
						<Col className='col-md-1'><span className='MyLink' onClick={refreshEverything}>Refresh</span></Col>
					</Row>
					<DiscussableRow title='Gross Written Premium' subtitle='' detail='' figure={span.figs.grossWrittenPremium} item='grossWrittenPremium'/>
					<DiscussableRow bg={true} title='Pro-Rated for Period' subtitle={`${span.inceptionDate} to ${span.expiryDate} : ${span.percentageOfYear()} of year`} detail='' figure={span.figs.gwpProRatedForPeriod} item='gwpProRatedForPeriod'/>
					<DiscussableRow title='Adjusted for Order' subtitle='' detail='' figure={span.figs.gwpAdjustedForOrder} item='gwpAdjustedForOrder'/>
					<DiscussableRow bg={true} title='Other Deductions' subtitle={span.otherDeductions.description} detail={span.otherDeductions.percentage} figure={span.figs.otherDeductionsFigure} item='otherDeductionsFigure'/>
					<DiscussableRow title='Taxes Added' subtitle={span.taxesAdded.country + ' ' + span.taxesAdded.description} detail={span.taxesAdded.percentage} figure={span.figs.taxesAddedFigure} item='taxesAddedFigure'/>
					<DiscussableRow bg={true} title='Taxes Deducted' subtitle={span.taxesDeducted.country + ' ' + span.taxesDeducted.description} detail={span.taxesDeducted.percentage} figure={span.figs.taxesDeductedFigure} item='taxesDeductedFigure' />
					<DiscussableRow title='After Taxes' subtitle='' detail='' figure={span.figs.gwpAfterTax} item='gwpAfterTax' />
					<DiscussableRow bg={true} title='Brokerage' subtitle='' detail={span.brokerage.percentage + " on " + utils.fmt2ps(span.figs.gwpDiscounted.W())} figure={span.figs.brokerageFigure} item='brokerageFigure' />
					<DiscussableRow title='Net Premium' subtitle='' detail='' figure={span.figs.netPremium} item='netPremium' />
					<DiscussableRow bg={true} title='Underwriter Share' subtitle='Signed line percentage' detail={span.uwrSignedPercentage} figure={span.figs.uwrNetPremium} item='uwrNetPremium' />

					{span.instalments.map( (si,idx) => { 
					return (
						<DiscussableRow bg={idx % 2 == 1}  title={'Instalment ' + si.index} subtitle={si.date} detail={si.Instalment_Percentage + " of " + utils.fmt2ps(si.total)} figure={si.figure} item='' />
					)
					})}
					{/* <Row className='Above12'>
						<Col className='col-md-2'><Button className='FullWidth'>Agree</Button></Col>
						<Col className='col-md-2'><Button className='FullWidth'>Disagree</Button></Col>
						<Col className='col-md-1'>Comment</Col> 
						<Col className='col-md-7'><input type='text' className='FullWidth' value={comment} onChange={(e) => setComment(e.target.value)}/></Col>
					</Row> */}
					<div className='Above12 SmallText'>
						Negotiation is {negotiation ? "On" : "Off"} : {!negotiation && <span className='MyLink' onClick={() => setNegotiation(true)}>Enable Negotiation</span>}{negotiation && <span className='MyLink'onClick={() => setNegotiation(false)}>Disable Negotiation</span>}
						: <span className='MyLink' onClick={printSPAN}>Print SPAN</span>
					</div>
				</Container>
            </Tab>
            <Tab eventKey="testXML" title="Test XML Input" className='BorderedTab'>
				<div>
					<Button onClick={parseInput}>Parse Input</Button>
				</div>
				<div className='Above12'>
					<textarea className='InputTA' value={xmlInput} onChange={changeInput} ></textarea>
				</div>
				<div className='Above12'>
					<textarea className='InputTA' value={jsonOutput}></textarea>
				</div>        
            </Tab>
        </Tabs>
		{showFeedbackDialog && 
		<FeedbackDialog item={dlgItem} startFigure={dlgStartFigure} feedbacks={dlgFeedbacks} closeFn={CloseDialog} saveFn={saveFeedback}/>
		}
		</>
    )
}