import { useState } from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap'
import { utils } from "../../FromElsewhere/utils"
import { Query, whitespace, WhitespaceAPI } from "../../helpers/whitespace"

import './BulkPDFs.css'

interface MyItem {
	umr: string;
	signedIC : string;
	status : string;
	className : string;
}

const AllItems : MyItem[] = [];

export const BulkPDFs = () => {
    const [umrlist, setUMRList] = useState("");	// 'B0999JC2012231402 B09990301240937 B0999NOSUCHRISK B0999JC2811231631' )
	const [sayuserMessage, sayuser] = useState("This module downloads PDFs for Signed Risks");
	const [items, setItems] = useState<MyItem[]>( [] );
	const timeout = 5000;

    const changeUMRList = ( event ) => {
        setUMRList( event.target.value )
    }

	const run = () => {
		const rgx = new RegExp( /\bB\d\d\d\d[A-Z0-9]+/, "gi" );
		const matches = umrlist.match( rgx );
		if( !matches ) {
			sayuser( "No UMRs found in the input");
			return;
		}
		sayuser( `${matches.length} ${utils.makePlural( matches.length, "UMR")} found`);
		AllItems.length = 0;
		matches.forEach( umr => {
			const item : MyItem = { umr: umr, signedIC : "", status : "Fetching", className : "NormalCell" };
			AllItems.push( item );
		});
		setItems( AllItems );
		AllItems.forEach( ( itm, idx ) => {
			console.log( `doing ${itm.umr} after ${timeout * idx}`);
			setTimeout( function() {
				callSummary( itm );
			}, timeout * idx );
		});
	}

	const callSummary = ( itm : MyItem ) => {
		const payload = { umrSearch : itm.umr };
		const url = "/api/summary";
		WhitespaceAPI.post( url, payload ).then( response => {
			if( !response.data || response.data.length == 0 ) {
				updateError( itm, "UMR not found" );
			} else {
				let signedIC = "";
				response.data.forEach( summary => {
					summary.docs.forEach( doc => {
						if( doc.type == "RWPlacing" && doc.status == "Signed" ) {
							signedIC = doc.id;
						}	
					});
				});
				if( signedIC ) {
					itm.signedIC = signedIC;
					updateStatus( itm, `Making PDF for ${signedIC}` );
					makePDF( itm );
				} else {
					updateError( itm, `${response.data.length} items but none is a signed risk` );
				}	
			}
		});
	}

	const makePDF = ( itm : MyItem ) => {
		const url = `/export/pdf/${itm.signedIC}`;
		const payload = { 
			hideLogo:false,
			includeUMRInHeader:false,
			includeSections:["RiskDetails","Information","SubscriptionAgreement","FiscalAndRegulatory","BrokerRemunerationAndDeductions","SecurityDetails"],
			alignToBottomOfHeadings:false,
			exportContract:true,
			exportCarrierTerms:false,
			includeAuthDetails:true,
			includeButtonStampInFooter:true,
			appendPDFAttachments:[],
			prependPDFAttachments:[],
			fontStyle:"serif",
			exportDefinedData:false,
			definedDataSettings :{ apiVersion:"v23.09", tagSet:"MRCV3"}
		};
		whitespace( Query.BLOB, url, payload ).then( async response => {
			try {
				if( !response.data ) {
					updateError( itm, "No response.data" );
				} else {
					updateStatus( itm, "PDF downloaded" );
					savePDF( itm, response.data );
				}
			} catch( err ) {
				console.log( err );
				updateError( itm, `Export failed for ${itm.signedIC}` );
			}
		});

	}

	const savePDF = ( itm : MyItem, content : Blob ) => {
		const link = document.createElement('a');
		const filename = `${itm.umr}.pdf`;
		const file = new Blob( [content], {
			type: "application/pdf"
		});
		link.href = URL.createObjectURL( file );
		link.download = filename;
		link.click();
		updateSuccess( itm, `${filename} saved`);
	}

	const updateError = ( itm : MyItem, status : string ) => {
		updateStatus( itm, status, "ErrorCell" );
	}
	const updateSuccess = ( itm : MyItem, status : string ) => {
		updateStatus( itm, status, "SuccessCell" );
	}
	const updateStatus = ( itm : MyItem, status : string, className : string = "" ) => {
		const newArray : MyItem[] = [];
		AllItems.forEach( x => {
			if( x.umr == itm.umr ) {
				x.status = status;
				if( className ) {
					x.className = className;
				}
			}
			newArray.push( x );
		});
		setItems( newArray );
		AllItems.length = 0;
		newArray.forEach( x => {
			AllItems.push( x );
		});
	}

	const ItemDisplay = ( { item, idx } ) => {
		const className = `col-md-10 ${item.className}`;
		return (
			<Row key={idx} ><Col className='col-md-2'>{item.umr}</Col><Col className={className}>{item.status}</Col></Row>
		)
	}

	return (
        <>
		<div className='WarningBanner'>{sayuserMessage}</div>
		<div><textarea className='UMRList' value={umrlist} onChange={changeUMRList} placeholder='Paste in the UMRs of Signed Risks to export' /></div>        
		<div>
			<Button onClick={run}>Run</Button>
		</div>
		<div>
			<Container className='Above12'>
			{items.map( ( item, idx ) => <ItemDisplay item={item} key={idx} idx={idx}/>)}	
			</Container>
		</div>
		</>
    )
}