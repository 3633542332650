import React, { useState, useEffect } from 'react'
import { NoResultsFound, PageLoading } from "../../components/Loading"
import { RiskGrid } from "../../components/RiskGrid"
import { SummaryFilter } from "../../components/SummaryFilter"
import { NotificationManager } from 'react-notifications'
import { utils } from "../../FromElsewhere/utils"
import { Query, whitespace, WhitespaceAPI, localresource } from "../../helpers/whitespace"
import { riskFilter } from "../../helpers/riskFilter"
import { Button, Modal } from "react-bootstrap";
import PDFMerger from 'pdf-merger-js/browser';
import axios from 'axios'
import { buildEOC, formatSignedLinesInner } from '../EOC/eocBuilding';

export const CombinedEOC = () => {
    const [loaded, setLoaded] = useState(true)
    const [risks, setRisks] = useState<any[]>([])
	const [riskID, setRiskID] = useState("")
	const [umr, setUMR] = useState("")
	const [insured, setInsured] = useState("")
	const [myPopup, setMyPopup] = useState<boolean>(false)

    const doSummary = ( grail ) => {
        grail = grail.trim();
        NotificationManager.success( grail ? `Fetching for ${grail}` : "Fetching first 60 risks");
        const payload: any = { statuses : [ "Signed","Endorsement","EndorsementPending" ] };
        if( grail ) {
            if( utils.validUMR( grail ) ) {
                payload.umrSearch = grail;
            } else {
                payload.nameSearch = grail;
            }
        }
        setRisks( [] );
        setLoaded( false );
        whitespace(Query.POST, '/api/summary', payload ).then(response => {
            console.log( "POST /api/summary complete");
            setRisks( riskFilter.groupRisksSummary( response.data ));
            setLoaded(true);
        })
    }

	useEffect(() => {
        setRisks([])
        setLoaded(false)
        const payload: any = { statuses : [ "Signed","Endorsement","EndorsementPending" ] };
        whitespace(Query.POST, '/api/summary', payload ).then(response => {
            setRisks( riskFilter.groupRisksSummary( response.data ));
            setLoaded(true);
        })
    }, [])

	const pickRisk = ( risk ) => {
        const signedID = utils.rootIC( risk.id ) + "::SIGNED";
		WhitespaceAPI.get( `/api/risks/${signedID}`).then( response => {
			if( response.data && response.data.control ) {
				console.log( response.data.control );
				setInsured( response.data.control.insuredName );
				setUMR( response.data.control.umr );
				popupSelectedRisk( signedID );
			}
		});
    }

    const popupSelectedRisk = id => {
		if(!myPopup) setMyPopup(true)
		setRiskID( id );
	}

    // const doTest = async () => {

    // }

    const blobarr: Blob[] = [];
    let outputFile = "";

    const doDownload = () => {
        NotificationManager.success( `Downloading ${umr}`);
        outputFile = `${umr.trim()} Combined EOC`;
        blobarr.length = 0;

        try {
            const url = "/CompassCoverSheet.pdf";
            NotificationManager.success( `Calling ${url} ...` );
            localresource( Query.BLOB, url ).then( async response => {
                if( !response.data ) {
                    NotificationManager.success( `No response.data from ${url}` );
                } else {                        
                    blobarr.push( response.data );
                    addPage2();
                    
                }
            });
        } catch( err ) {
            console.log( err );
            NotificationManager.error( `Process failed - see console`)
        }
    }

    const addPage2 = () => {
        try {
            const url = "/CompassCertificatePage.pdf";
            NotificationManager.success( `Calling ${url} ...` );
            localresource( Query.BLOB, url ).then( async response => {
                if( !response.data ) {
                    NotificationManager.success( `No response.data from ${url}` );
                } else {                        
                    blobarr.push( response.data );
                    addRiskDetails();
                }
            });
        } catch( err ) {
            console.log( err );
            NotificationManager.error( `Process failed - see console`)
        }
    }

    const addRiskDetails = () => {
        const payload = {
			hideLogo : false,
			includeUMRInHeader : true,
			includeSections : ["RiskDetails"],
			alignToBottomOfHeadings: false,
			exportContract : true,
			exportCarrierTerms : true,
			includeButtonStampInFooter : true,
			appendPDFAttachments : [],
			prependPDFAttachments : []
		};
        const url = `/export/pdf/${riskID}`;
        NotificationManager.success( `Calling ${url} ...` );
        whitespace( Query.BLOB, url, payload ).then( async response => {                    
            if( !response.data ) {
                NotificationManager.success( `No response.data from ${url}` );
            } else {
                blobarr.push( response.data );
                addInsurerTable();
            }
        });
    }

    // https://pdfmake.github.io/docs/0.1/document-definition-object/styling/
    const addInsurerTable = () => {
        const signedID = utils.rootIC( riskID ) + "::SIGNED";
        try {
            const url = `/api/risks/${signedID}/getExtendedMRC`;
            NotificationManager.success( `Calling ${url} ...` );
            whitespace(Query.GET, url ).then( response => {
                console.log( response.data );
                const risk = response.data;
                const variables = {
                    leadUnderwriter: ""
                }
                const signedLineSets: Array<unknown> = []
                risk.signedLineSets.map(signedLineSet => signedLineSet.contents.map(content => signedLineSets.push([content])));
                const signedLinesBlock = formatSignedLinesInner( risk, signedLineSets, null, variables, null );
                console.log( signedLinesBlock );

                const template = {
                    pageMargins: [40, 90, 40, 50],
                    content : [
                        {
                            text: "SECURITY",
                            fontSize: 12,
                            // decoration: "underline",
                            bold: true,
                            margin: [0, 20, 20, 20]
                        }
                    ]
                }
                signedLinesBlock.map( b => { template.content.push( b )});
                const para : any = {
                    margin: [0, 60, 0, 0],
                    text : "This Security Schedule has been prepared by us for your ease of reference to identify the subscribing Underwriters and their respective participations on the above referenced insurance contract."
                }
                template.content.push( para );

                const pdfmakeurl = 'https://dev.whitespace.co.uk/pdfmake/'; // "https://www.whitespaceplatform.com/pdfmake/"; <-- AJC: failed for me 1/11/2022 
                axios.post( pdfmakeurl, template, { 
                    responseType: 'arraybuffer',
                    headers: { 
                        'Accept': '*/*',
                        'Content-type': 'application/json',
                    }
                })
                .then(response => {
                    blobarr.push( response.data );
                    makeOutput();
                })
                .catch(function (error) {
                    console.error(error)
                    return (error.message === 'Network Error') ? 'Network Error' : false
                })
            })
        } catch( err ) {
            console.log( err );
            NotificationManager.error( `Process failed - see console`)
        }        
    }

    const makeOutput = async () => {
        const merger = new PDFMerger();
        for (const file of blobarr) {
            await merger.add(file);
        }
        await merger.save(outputFile); 
        NotificationManager.success( `Saved ${outputFile}.pdf` );    
    }

    const MyPopup = () => {
		return (
			<Modal size="lg" show={myPopup} onHide={() => setMyPopup(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Download EOC</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>UMR: {umr}</div>
					<div>Insured: {insured}</div>
					<div className='LightGray'>{riskID}</div>
					<div className='Above12'><Button onClick={doDownload}>Download</Button></div>
					{/* <div className='Above12'><Button onClick={doTest}>Test</Button></div> */}
				</Modal.Body>
				<Modal.Footer>
					<b>ALPHA Release</b> for testing only
				</Modal.Footer>
			</Modal>
		)
	}

	return (
        <>
        <SummaryFilter onSearch={doSummary}></SummaryFilter>
        {!loaded && <PageLoading/>}
        {loaded && !risks.length && <div className="page-notice"><NoResultsFound />No Signed Risks</div>}
        <div className={`Fadein-wrapper ${risks.length && 'load'}`}>
            {riskID && MyPopup()}
            <RiskGrid input={risks} pickFn={pickRisk} singleLine={true} />
        </div>
        </>

    )
}