import React, { useState, useEffect } from 'react'
import { whitespace, Query } from '../../helpers/whitespace'
import { CLIENT } from "../../constants/client"
import { setMUID, RefreshSubscribers, LoadSessionDetails } from '../../App'
import { Session } from '../../Session'

export const Contents = () => {
    const [companyId, setCompanyId] = useState( Session.corporate.companyId );
    const [companyName, setCompanyName] = useState( Session.corporate.name );
    const [ SUMOAccounts, setSUMOAccounts ] = useState<any>([])
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        refreshData();
        if( !RefreshSubscribers.find( x => { return x.id == "Contents" } ) ) {
            RefreshSubscribers.push( { id: "Contents", fn: refreshMe } );
        }
    }, [])

    const refreshData = () => {
        const call = whitespace(Query.POST, '/auth/token')
        Promise.all([ call ]).then( response => {
            if( response[0]?.data ) {
                if( response[0].data.userID ) {
                    setSUMOAccounts( [] )
                    allDataLoaded();
                } 
                if( response[0].data.userIDs ) {
                    holdCorporates(response[0].data.userIDs)
                }
            }
        })
    }

    const holdCorporates = (userIDs) => {
        const call = whitespace(Query.GET, '/api/shared/corporate')
        const accounts : any[] = []
        Promise.all([ call ]).then( response => {
            if( response[0]?.data ) {
                const corps = response[0]?.data
                corps.map( (corp) => {
                    corp.members.map( (member ) => {
                        if( userIDs.includes( member.uniqueID ) ) {
                            const account = { companyId: corp.companyId, name: corp.name, uniqueID: member.uniqueID }
                            accounts.push( account )
                        }
                    })
                })
                setSUMOAccounts( accounts );
                allDataLoaded();
            }
        })
    }

    const allDataLoaded = () => {
        setCompanyId( Session.corporate.companyId );
        setCompanyName( Session.corporate.name );
        setLoaded( true );
    }

    const refreshMe = () => {
        console.log( `Contents refreshMe`);
        setCompanyId( Session.corporate.companyId );
        setCompanyName( Session.corporate.name );
    }

    const renderContents = () => {
        const contents = CLIENT.contents.length ? CLIENT.contents : [
            "This toolkit provides additional functionality for the users of the Whitespace Platform."
        ]
        return contents.map( (item,idx) => <div key={idx}>{item}<p/></div> )
        
    }

    const sumoList = () => {
        if( SUMOAccounts.length <= 1 ) {
            return ( <div></div> )
        }
        return (
            <>
            <div className='Above12 Below12'>You are a user with {SUMOAccounts.length} companies. Click one to switch company.</div>
            {SUMOAccounts.sort( (x,y) => { return x.name.localeCompare( y.name ); }).map( (ac,idx) => (
                <div className='MyLink' key={idx} onClick={() => handleSwitchTo(ac) }>{ac.name}</div>
            ))}
            </>
        )
    }

    const handleSwitchTo = account => {
        setMUID( account.uniqueID );
        LoadSessionDetails();
    }

    return (
        <>
        { renderContents() }
        <div>You are logged in as a user at <b>{companyName}</b></div>
        { !loaded && <div>Fetching data...</div>}
        { loaded && sumoList() }
        </>
    )
}