import React from 'react';
import {CSSProperties, ReactElement} from 'react'
import {LineItemElement, LineItemTextSpan, LineItemElementType, ListStyle, LineItemTable} from './line-item'

export interface LineItemViewProps {
    items: LineItemElement[];
    child: boolean;
    listStyle: string;
}

export function LineItemView(props: LineItemViewProps): ReactElement {

    const renderSpan = (span: LineItemTextSpan) => {
        if(span.isPlaceholder) {
            return (
                <span className="preserve-spaces ph-highlight">{span.text}</span>
            )
        } else {
            return (
                <span className="preserve-spaces">{span.text}</span>
            )
        }
    }

    const renderItem = (item: LineItemElement): ReactElement => {
        if(item.type === LineItemElementType.Paragraph) {
            const spans = item.spans!.map((s: LineItemTextSpan, idx) => renderSpan(s))
            return (
                <div className="para">
                    {spans}
                </div>
            )
        } else if(item.type === LineItemElementType.Tree) {
            return (
                <LineItemView items={item.children!} listStyle={item.style!} child={true} />
            )
        } else/* if(item.type === LineItemElementType.Table) */{

            const rows = item.table!.rows.map((row, i) => {
                const cells = row.cells.map((cell, j) => {
                    const cellStyle = getCellStyle(item.table!, i, j)
                    return (
                        <td key={j} className="cell">
                            <div className="cell-div" style={cellStyle}>{cell}</div>
                        </td>
                    )
                })

                return (
                    <tr key={i}>
                        {cells}
                    </tr>
                )
            })

            return (
                <div className="line-item-table-container">
                    <table className="line-item-table">
                        {rows}
                    </table>
                </div>
            )
        }
    }

    if(props.child) {

        const renderListItem = (item: LineItemElement) => {
            const subItem = renderItem(item)

            if(item.type === LineItemElementType.Tree) {
                return (
                    <li data-bullet={item.bullet!} className="hide-bullet">
                        {subItem}
                    </li>
                )
            } else {
                return (
                    <li data-bullet={item.bullet!}>
                        {subItem}
                    </li>
                )
            }
        }

        const listItems = props.items.map(i => renderListItem(i))

        switch(props.listStyle) {
            case ListStyle.ordered:
                return (
                    <ol>{listItems}</ol>
                )
            case ListStyle.unordered:
                return (
                    <ul>{listItems}</ul>
                )
        }

        return (
            <div className="indented">
                {listItems}
            </div>
        )
    }

    const items = props.items.map(item => renderItem(item))
    return (
        <> {items} </>
    )
}

function getCellStyle(table: LineItemTable, rowIndex: number, colIndex: number): CSSProperties {

    let width = table.jmrcTable.widths ? table.jmrcTable.widths[colIndex] : 0
    let height = table.rows ? table.rows[rowIndex].height : 0

    if (!width || width === 1) {
        if (table.colSpecs) {
            if (colIndex + 1 < table.colSpecs.length) {
                width = table.colSpecs[colIndex + 1].x - table.colSpecs[colIndex].x
                width = width * 1.5
            }
        }
    }

    if (!height || height === 1) {
        if (table.rowSpecs) {
            if (rowIndex + 1 < table.rowSpecs.length) {
                height = table.rowSpecs[rowIndex + 1].y - table.rowSpecs[rowIndex].y
                height = height * 1.333
            }
        }
    }

    const style: any = {}

    if(table.colSpecs) {
        const spec = table.colSpecs[colIndex]
        if(spec && spec.alignment) {
            style['text-align'] = spec.alignment === 'centre' ? 'center' : spec.alignment
        }
    }

    if(height < 16) {
        height = 16
    }

    if(height > 1){
        style['min-height'] = `${height - 5}px`
    }

    if(width > 1){
        // not min-width, rows expanded but columns stay as specified.
        style['width'] = `${width - 10}px`
    }

    return style
}