import exp from "constants";
import { DataRuleCondition } from "./DataRuleCondition";
import { DataRuleEquation } from "./DataRuleEquation";
import { DataRuleStatement } from "./DataRuleStatement";
import { DataRuleTranslator } from "./DataRuleTranslator";
import { DataRuleSet } from "./DataRules";
import { DataRuleFieldMapper } from "./DataRulesFieldMapper";
import { DDDataPoint, DDExport, DefinedData } from "../FromElsewhere/DefinedData";
import { utils } from "../FromElsewhere/utils";
import { Evaluation } from "./Evaluation";
import { DataRuleFactory } from "./DataRuleFactory";

export class DataRuleEvaluator {
	static verbose = false;
	static rgxSectionMessage = new RegExp( "Section (?<sectionID>[0-9]+) (?<suffix>.*)" );
	static mayLog = ( ob : any ) => {
		if( DataRuleEvaluator.verbose ) {
			if( typeof(ob) == "object") {
				console.log( JSON.stringify( ob, null, 4 ));
			} else {
				console.log( ob );
			}
		}
	}

	ddExport : DDExport;
	translator : DataRuleTranslator;
	fieldMapper : DataRuleFieldMapper;
	sectionID: string = "";
	mayLog = DataRuleEvaluator.mayLog;
	constructor( ddExport : DDExport, translator : DataRuleTranslator, fieldMapper : DataRuleFieldMapper ) {
		this.ddExport = ddExport;
		this.translator = translator;
		this.fieldMapper = fieldMapper;
	}

	static makeEvaluation( result : string ) : Evaluation {
		return { result: result, failMessages: [] }
	}
	static makeFail( message : string ) : Evaluation {
		return { result: "F", failMessages: [ message ] }
	}
	resetRule( rule  : DataRuleSet ) {
		this.resetCondition( rule.condition );
	}
	resetCondition( condition: DataRuleCondition ) {
		condition.evaluated = undefined;
		condition.statements.forEach( x => { x.evaluated = "?"; })
		condition.equations.forEach( x => { x.evaluated = undefined; });
		condition.subConditions.forEach( x => { this.resetCondition( x ); });
	}

	evaluate( rule : DataRuleSet ) : Evaluation {
		if( ["MANDATORY","REQUIRED"].includes(rule.rawValue.trim().toUpperCase() ) ) {
			return DataRuleEvaluator.makeEvaluation("T");
		}
		if( this.ddExport.multiSectionDefinition.length == 0 ) {
			return this.evaluateCondition( rule.condition );
		}
		// this.mayLog( `Checking ${this.ddExport.multiSectionDefinition.length} sections, stop on first failure`);
		let result : Evaluation | null = null;
		this.ddExport.multiSectionDefinition.forEach( (s:any) => {
			this.sectionID = s.sectionID;
			this.resetRule( rule );
			const evn = this.evaluateCondition( rule.condition );
			// this.mayLog( `Section ${this.sectionID} got ${evn.result}`);
			if( evn.result == "F" ) {
				this.prefixMessages( evn, `Section ${s.sectionID} `);
				if( result ) {
					this.copyMessages( result, evn );
				} else {
					result = evn;
				}
			}
		});
		if( result ) {
			this.coalesceSectionMessages( result);
			this.mayLog( JSON.stringify( result, null, 4 ));
			return result;
		}
		return DataRuleEvaluator.makeEvaluation("T"); 
	}

	coalesceSectionMessages( evn: Evaluation ) {
		interface sMessage { suffix : string, sectionIDs : string[] }
		const reduced : sMessage[] = [];
		evn.failMessages.forEach( msg => {
			const m = msg.match( DataRuleEvaluator.rgxSectionMessage );
			if( m && m.groups ) {
				const x = reduced.find( itm => { return itm.suffix == m.groups?.suffix });
				if( x ) {
					x.sectionIDs.push( m.groups?.sectionID || "-" );
				} else {
					reduced.push( { suffix : m.groups?.suffix || "-", sectionIDs: [ m.groups?.sectionID || "-" ]});
				}
			} else {
				reduced.push( { suffix: msg, sectionIDs: [] });
			}
		});
		evn.failMessages = [];
		reduced.forEach( itm => {
			if( itm.sectionIDs.length == 0 ) {
				evn.failMessages.push( itm.suffix );
			} else if( itm.sectionIDs.length == 1 ) {
				evn.failMessages.push( `Section ${itm.sectionIDs[0]} ${itm.suffix}` );
			} else {
				evn.failMessages.push( `Sections ${itm.sectionIDs.join(", ")} ${itm.suffix}` );
			}
		});
	}

	prefixMessages( evn: Evaluation, prefix : string ) {
		for( let i = 0, len = evn.failMessages.length; i < len; i++ ) {
			evn.failMessages[i] = prefix + evn.failMessages[i];
		}
	}
	copyMessages( evnTarget : Evaluation, evnSource : Evaluation ) {
		evnSource.failMessages.forEach( msg => {
			evnTarget.failMessages.push( msg );
		});
	}
	
	evaluateEquation( eqn : DataRuleEquation ) : Evaluation {
		if( eqn.evaluated && eqn.evaluated.result != "?" ) {
			return eqn.evaluated;
		}
		if( eqn.operator == "HAS TAG" || eqn.operator == "HAS ALL TAGS" || eqn.operator == "HAS SOME OF THESE TAGS" || eqn.operator == "HAS NO TAGS" ) {
			let count = 0, found = 0;
			const missingTag : string[] = [];
			eqn.expectedValues.forEach( tag => {
				const dp = this.findFirst2( eqn.fieldName, tag );
				// this.mayLog( `evaluateEquation ${eqn.operator} ${this.sectionID} finds ${dp ? JSON.stringify(dp) : "nothing" }`);
				count++;
				if( dp ) {
					found++;
				} else {
					missingTag.push( tag );
				}
			});
			if( eqn.operator == "HAS NO TAGS" ) {
				if( found > 0 ) {
					eqn.evaluated = DataRuleEvaluator.makeFail( `Heading ${DataRuleEvaluator.makeFriendlyHeading(eqn.fieldName)} has tagged data, which was unexpected.`)
				} else {
					eqn.evaluated = DataRuleEvaluator.makeEvaluation( "T");
				}
				return eqn.evaluated;
			}
			eqn.evaluated = DataRuleEvaluator.makeEvaluation("F");
			if( eqn.operator == "HAS TAG" || eqn.operator == "HAS ALL TAGS" ) {
				eqn.evaluated.result = ( found == count ? "T" : "F");
			}
			if( eqn.operator == "HAS SOME OF THESE TAGS" ) {
				eqn.evaluated.result = ( found > 0 ? "T" : "F");
			}
			if( eqn.evaluated.result == "F" ) {
				const friendlyHeading = DataRuleEvaluator.makeFriendlyHeading( eqn.fieldName );
				let message = `Heading ${friendlyHeading} does not have tag(s) for ` + missingTag.join( ", ")
				if( eqn.operator == "HAS TAG" ) {
					const friendlyTag = eqn.expectedValues[0].replaceAll( "_", " ");
					message = `The "${friendlyTag}" tag is missing. Ensure the tag is applied under the ${friendlyHeading} heading`;
				}
				// The "Excess Amount" tag is missing. Ensure the tag is applied under the Sum (Re)Insured heading within the Risk Details section"
				// The MRCv3 Guidance advises that "Co-Insurance Share" tag should appear if it is applicable. You may wisj to apply this tag under heading Excess if you deem it applicable.
				eqn.evaluated.failMessages.push( message );
			} 
			return eqn.evaluated;
		}
		if( eqn.operator == "HEADING NOT INCLUDED" ) {	// TODO data is mean to be including list of mrcHeadings
			let result = "T";
			const dp = this.findFirst2( eqn.fieldName, "" );
			if( dp ) {
				result = "F";
			}
			eqn.evaluated = DataRuleEvaluator.makeEvaluation(result);
			eqn.evaluated.failMessages.push( `Heading ${eqn.fieldName} included`);
			return eqn.evaluated;
		}
		if( eqn.operator == "PASSES MRCV3 RULES" ) {
			return this.runHardcodedMRCv3Rules( eqn );
		}
		if( eqn.operator == "HAS SOME TAGGED DATA" ) {
			let result = "T";
			const dp = this.findFirst2( eqn.fieldName, "" );
			if( !dp ) {
				result = "F";
			}
			eqn.evaluated = DataRuleEvaluator.makeEvaluation(result);
			eqn.evaluated.failMessages.push( `No tagged data in heading ${eqn.fieldName}`);
			return eqn.evaluated;
		}
		if( this.fieldMapper && this.translator )
		{
			const ddFieldMap = this.fieldMapper.mapField( eqn.fieldName );
			const headings = ddFieldMap.mrcHeading.split( "/").map( x => { return x.trim(); });
			const tags = ddFieldMap.tag.split( "/").map( x => { return x.trim(); });
			let dp = new DDDataPoint( "", "", "", { name: "", value: "", phType: ""});
			headings.forEach( heading => {
				tags.forEach( tag => {
					if( !dp.mrcHeading ) {
						const found = this.findFirst2( heading, tag );
						if( found ) {
							dp = found;
						}
					}
				});
			});
			const dpValue = dp.mrcHeading ? dp.value.replaceAll( "&", "and") : "";
			let extra = "";
			let translated = "";
			if( dp.mrcHeading ) {
				translated = this.translator.translate( dpValue, ddFieldMap.translation );
				extra = `for value ${dpValue}` + ( dpValue == translated ? "" : ` -> ${translated}` );
			} else {
				extra = `no datapoint for heading ${ddFieldMap.mrcHeading}, tag ${ddFieldMap.tag}`;
			}
			if( eqn.operator == "IS NULL" ) {
				eqn.evaluated = DataRuleEvaluator.makeEvaluation( dp.mrcHeading ? "F" : "T" );
			} else if( eqn.operator == "IS NOT NULL" ) {
				eqn.evaluated = DataRuleEvaluator.makeEvaluation( dp.mrcHeading ? "T" : "F" );
			}
			else if( dp && eqn.expectedValues.includes( translated ) ) {
				eqn.evaluated = DataRuleEvaluator.makeEvaluation("T");
			} else {
				eqn.evaluated = DataRuleEvaluator.makeEvaluation("F");
				eqn.evaluated.failMessages.push( `Fails on ${translated || "blank"} ${eqn.operator} ${eqn.expectedValues.join(", ")}`);
			}
			if( eqn.operator == "IS NOT" || eqn.operator == "IS NOT IN" ) {
				eqn.evaluated = DataRuleEvaluator.makeEvaluation( eqn.evaluated.result == "T" ? "F" : "T");
				if( eqn.evaluated.result == "T" ) {
					eqn.evaluated.failMessages.length = 0;
				} else {
					eqn.evaluated.failMessages.push( `Fails on ${translated || "blank"} ${eqn.operator} ${eqn.expectedValues.join(", ")}`);
				}
			}
			this.mayLog( `evaluateEquation ${eqn.evaluated.result} ${eqn.rawValue} op:${eqn.operator}: ${extra}`);
		}
		return eqn.evaluated || DataRuleEvaluator.makeEvaluation("?");
	}

	runHardcodedMRCv3Rules( eqn : DataRuleEquation ) : Evaluation {
		if( eqn.fieldName == "Premium" )	{ return this.runHardcodedMRCv3RulesForPremium( eqn ); }
		eqn.evaluated = DataRuleEvaluator.makeEvaluation("?");
		return eqn.evaluated;
	}
/*
TAGS Premium_Type Premium_Amount Premium_Amount_Less_Discounts_Applicable_At_Inception Premium_Application_Basis Premium_Period_Qualifier Premium_Rate_% 
Adjustment_Basis Adjustment_Basis_Amount Adjustment_Rate_% Adjustment_Date
Instalment_Amount Instalment_% Instalment_Due_Date Instalment_Number Instalment_Frequency
Contractual_Exchange_Rate Settlement_Due_Date Pool_Scheme TRIA_Decision_Date

VALUES FOR Premium_Type : Adjustment Premium,Deposit Premium,Estimated Premium,Minimum Premium,Minimum and Deposit Premium,Original Gross Premium,Premium,Reinstatement Premium

VALUES FOR Premium_Period_Qualifier : annual,annual in full,in full,in full per annum or pro rata,in full per annum and/or pro rata,in full per annum,per annum (and pro rata for the period),for the period
*/
	runHardcodedMRCv3RulesForPremium( eqn : DataRuleEquation ) : Evaluation {
		eqn.evaluated = DataRuleEvaluator.makeEvaluation("?");
		const dp_premium_type = this.findFirst2( "Premium", "Premium_Type" );
		if( !dp_premium_type ) {
			eqn.evaluated = DataRuleEvaluator.makeFail( 'A "Premium Type" tag is required in the "Premium" heading');
			return eqn.evaluated;
		}
		const dp_premium_amount = this.findFirst2( "Premium", "Premium_Amount" );
		if( ["Premium", "Minimum Premium","Deposit Premium","Minimum And Deposit Premium"].includes( dp_premium_type.value ) ) {
			if( !dp_premium_amount ) {
				eqn.evaluated = DataRuleEvaluator.makeFail( '"Premium Amount" should be present in the "Premium" heading when type is ${dp_premium_type.value}');
				return eqn.evaluated;
			} 
			const extraTags = ["Premium_Application_Basis", "Premium_Period_Qualifier" ];
			extraTags.forEach( tag => {
				const dpExtra = this.findFirst2( "Premium", tag );
				if( !dpExtra ) {
					const friendlyTag = DataRuleEvaluator.makeFriendlyTag( tag );
					if( eqn.evaluated ) {
						eqn.evaluated.result = "F";
						eqn.evaluated.failMessages.push( `"${friendlyTag}" should be present in the "Premium" heading when type is ${dp_premium_type.value}`);
					}
				}
			});
			if( eqn.evaluated.result != "?" ) {
				return eqn.evaluated;
			}
		}
		const dp_paldaai = this.findFirst2( "Premium", "Premium_Amount_Less_Discounts_Applicable_At_Inception" );
		if( !dp_premium_amount && !dp_paldaai ) {
			eqn.evaluated = DataRuleEvaluator.makeFail( 'Either "Premium_Amount" or "Premium_Amount_Less_Discounts_Applicable_At_Inception" should be present in the "Premium" heading');
			return eqn.evaluated;
		}
		const dp_Instalment_Amount = this.findAll2( "Premium", "Instalment_Amount");
		const dp_Instalment_Perc = this.findAll2( "Premium", "Instalment_%");
		const dp_Instalment_Due_Date = this.findAll2( "Premium", "Instalment_Due_Date");
		const parents = DefinedData.getDistinctParents( [ ...dp_Instalment_Amount, ...dp_Instalment_Perc, ...dp_Instalment_Due_Date] );
		const instFails : string[] = [];
		if( parents.length ) {
			parents.forEach( p => {
				const dd = dp_Instalment_Due_Date.find( x => { return x.parentID == p; } );
				const amt = dp_Instalment_Amount.find( x => { return x.parentID == p; } );
				const perc = dp_Instalment_Perc.find( x => { return x.parentID == p; } );
				if( !dd ) {
					instFails.push( "Each set of instalments requires a due date");
				}
				if( !amt && !perc ) {
					instFails.push( "Each set of instalments requires either an amount or a percentage");
				}
			});	
		}
		if( instFails.length ) {
			eqn.evaluated = DataRuleEvaluator.makeEvaluation( "F");
			eqn.evaluated.failMessages = [...instFails];
			return eqn.evaluated;
		}
		eqn.evaluated = DataRuleEvaluator.makeEvaluation("T");
		return eqn.evaluated;
	}

	static makeFriendlyHeading( mrcHeading : string ) : string {
		const len = mrcHeading.length;
		let result = "";
		for( let i = 0; i < len; i++ ) {
			const c = mrcHeading.charAt(i);
			if( i > 0 && c >= 'A' && c <= 'Z' && i + 1 < len ) {
				const c2 = mrcHeading.charAt(i+1);
				if( c2 >= 'a' && c2 <= 'z' ) {
					result += " ";
				}
			} 
			result += c;
		}
		return result;
	}
	static makeFriendlyTag( tag : string ) : string {
		return tag.replaceAll( "_", " ");
	}

	findAll2( mrcHeading : string, tag : string ) : DDDataPoint[] {
		const grail = { mrcHeading: mrcHeading, tag: tag, sectionID: this.sectionID };
		return DefinedData.findAll( this.ddExport.definedData, grail );
	}
	findFirst2( mrcHeading : string, tag : string ) : DDDataPoint | null {
		const headings = mrcHeading.split("/").map( h => { return h.trim() });
		let result : DDDataPoint | null = null;
		headings.forEach( heading => {
			if( !result ) {
				const grail = { mrcHeading: heading, tag: tag, sectionID: this.sectionID };
				result = DefinedData.findFirst( this.ddExport.definedData, grail );
				// this.mayLog( `findFirst2 result=${result ? result.value : "null"} ` + JSON.stringify(grail));
			}
		});
		return result;
	}

	evaluateStatement( stmt : DataRuleStatement ) : Evaluation {
		stmt.evaluated = "?";
		const tidied = utils.aggressiveTidy( stmt.rawValue );
		if( tidied.indexOf( "premiumispayablebyinstalments") >= 0 ) {
			stmt.evaluated = "F";
			const grailTags = [ "Instalment_Amount", "Instalment_%", "Instalment_Due_Date", "Instalment_Number", "Instalment_Frequency"];
			for( let i = 0, len = this.ddExport.definedData.length; i < len; i++ ) {
				const dp = this.ddExport.definedData[i];
				if( grailTags.includes( dp.tag ) ) {
					stmt.evaluated = "T";
					break;
				}			
			}
		}
		return DataRuleEvaluator.makeEvaluation( stmt.evaluated );
	}

	evaluateCondition( cond : DataRuleCondition ) : Evaluation {
		const evaluations : Evaluation[] = [];
		evaluations.push( ...cond.equations.map( x => { return this.evaluateEquation(x); } ) );
		evaluations.push( ...cond.statements.map( x => { return this.evaluateStatement(x); } ) );
		evaluations.push( ...cond.subConditions.map( x => { return this.evaluateCondition(x); } ) );

		const results = evaluations.map( evln => { return evln.result } ).join("");
		if( results.includes( "!" ) ) {
			cond.evaluated = DataRuleEvaluator.makeEvaluation("!");
		}
		else if( results.includes( "?" ) ) {
			cond.evaluated = DataRuleEvaluator.makeEvaluation("?");
		}
		else if( cond.conjunction == "AND" ) {
			cond.evaluated = DataRuleEvaluator.makeEvaluation( results.includes( "F" ) ? "F" : "T" );
		}
		else if( cond.conjunction == "OR" ) {
			cond.evaluated = DataRuleEvaluator.makeEvaluation( results.includes( "T" ) ? "T" : "F" );
		}
		if( cond.evaluated && cond.evaluated?.result == "F" ) {
			evaluations.forEach( evln => {
				if( evln.result == "F" ) {
					cond.evaluated?.failMessages.push( ...evln.failMessages );
				}
			});
		}
		return cond.evaluated || DataRuleEvaluator.makeEvaluation("?");
	}

	getFieldValue( ruleSet : DataRuleSet ) : string {
		if( ruleSet.condition.evaluated?.result == "F" ) {
			return "---";
		}
		const fm = this.fieldMapper.mapField( ruleSet.fieldName );
		const grail = { tag: fm.tag, mrcHeading: fm.mrcHeading};
		// hard coded bodge
		// if( ruleSet.fieldName == "Policyholder Identification Code" ) {
		// 	grail.tag = "Policyholder_Identifier";
		// 	grail.mrcHeading = "Policyholder";
		// }
		if( !grail.tag ) {
			throw new Error( `Field ${ruleSet.fieldName} does not map to a tag` );
		}
		const dp = this.findFirst2( grail.mrcHeading, grail.tag );
		if( dp ) {
			const dpValue = dp ? dp.value.replaceAll( "&", "and") : "";
			if( fm.translation ) {
				return this.translator.translate( dpValue, fm.translation );
			}
			return dpValue;
		}
		return "** MISSING **";
	}

	static findEquation( rule : DataRuleSet, expected : string ) : DataRuleEquation | null {
		return this.findEquationInCondition( rule.condition, expected );
	}

	static findEquationInCondition( cond : DataRuleCondition, expected : string ) : DataRuleEquation | null {
		let found : DataRuleEquation | null = cond.equations.filter( eqn => { return eqn.rawValue == expected } )[0];
		if( found ) {
			return found;
		}
		for( let i = 0, len = cond.subConditions.length; i < len; i++ ) {
			found = this.findEquationInCondition( cond.subConditions[i], expected );
			if( found ) {
				return found;
			}
		}
		return null;
	}

	static findStatement( rule : DataRuleSet, expected : string ) : DataRuleStatement | null {
		return this.findStatementInCondition( rule.condition, expected );
	}

	static findStatementInCondition( cond : DataRuleCondition, expected : string ) : DataRuleStatement | null {
		let found : DataRuleStatement | null = cond.statements.filter( stmt => { return stmt.rawValue == expected } )[0];
		if( found ) {
			return found;
		}
		for( let i = 0, len = cond.subConditions.length; i < len; i++ ) {
			found = this.findStatementInCondition( cond.subConditions[i], expected );
			if( found ) {
				return found;
			}
		}
		return null;
	}
}