import React from "react"
import { useState } from "react"
import { Button } from "react-bootstrap"

export const SummaryFilter = ({ onSearch, grailDefault = ""}) => {
    const [grail, setGrail] = useState(grailDefault)
    
    const onChange = (event) => {
        const value = event.target.value;
        setGrail( value );
    }
    const onClear = () => {
        setGrail( "" );
    }
    const doSearch = () => {
        onSearch( grail );
    }
    return (
        <>
        <div className='table-filter-wrapper'>
            <input className="table-filter" type="text" placeholder="UMR or Insured" aria-label="Search Input" value={grail} onChange={onChange} />
            <Button type="button" className="clear-filter btn-primary" onClick={doSearch}>Search</Button>
            &nbsp;
            <Button type="button" className={`clear-filter ${grail.length ? 'btn-primary' : 'btn-grey'}`} onClick={onClear} disabled={!grail.length}>Clear</Button>
        </div>
        </>
    )
}