import { PKFeedback, PKObservation } from "./PaymentFeedback";

export class PKFigure {
	private original = NaN;
	private proposed = NaN;
	private working = NaN;
	private observations: PKObservation[] = [];

	// static clone( input : any ) : PKFigure {
	// 	const result = new PKFigure();
	// 	if( typeof(input.working) == "number" )	result.setW( input.working );
	// 	if( typeof(input.proposed) == "number" )	result.setP( input.proposed );
	// 	if( typeof(input.original) == "number" )	result.setO( input.original );
	// 	if( Array.isArray( input.observations ) ) {
	// 		input.observations.forEach( (ob:PKObservation) => { result.holdObservation( ob ); });
	// 	}
	// 	return result;
	// }

	W() : number { return this.working; }
	P() : number { return this.proposed; }
	O() : number { return this.original; }
	setW( value : number ) : number {
		this.observations.forEach( ob => {
			if( !isNaN( ob.figureProposed ) ) {
				this.proposed = ob.figureProposed;
			}
			if( ob.agreed ) {
				this.agree( value );
			}
		});
		if( isNaN( this.working ) ) {
			this.working = value;
		}
		return this.working;
	}

	agree( value : number ) {
		if( !isNaN( this.proposed ) ) {
			if( isNaN(this.original)) {
				this.original = value;
			}
			this.working = this.proposed;
		}
	}

	holdObservation( ob : PKObservation ) {
		this.observations.push( ob );
	}
}