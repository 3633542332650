import React, { useState, useEffect } from 'react'
import { Modal, Col, Row, Button, Tab, Tabs, Form } from 'react-bootstrap'
import { NoResultsFound, PageLoading } from "../../components/Loading"
import { RiskGrid } from "../../components/RiskGrid"
import { SummaryFilter } from "../../components/SummaryFilter"
import { NotificationManager } from 'react-notifications'
import { utils } from "../../FromElsewhere/utils"
import { Query, whitespace, WhitespaceAPI, localresource } from "../../helpers/whitespace"
import { riskFilter } from "../../helpers/riskFilter"
import { JMRC } from "../../rebrowse/jmrc";
import { MRCViewer } from '../../rebrowse/MRCViewer'
import { interpolatePlaceholders } from '../../helpers/mrc'
import { DDPage } from '../../FromElsewhere/DefinedData'

export const ApplyTemplate = () => {
	const MyStage = {
		PICKTEMPLATE: "PICKTEMPLATE",
		PICKRISK: "PICKRISK",
		CONFIRM: "CONFIRM",
		UNDERWAY: "UNDERWAY",
		COMPLETE: "COMPLETE"		
	}

	const [loaded, setLoaded] = useState(true)
	const [templates, setTemplates] = useState<any[]>([]);
	const [templateID, setTemplateID] = useState("");
    const [risks, setRisks] = useState<any[]>([])
	const [riskID, setRiskID] = useState("")
	const [templateLineItemSummary, setTemplateLineItemSummary] = useState("")
	const [templateMRC, setTemplateMRC] = useState<any>(null);
	const [riskMRC, setRiskMRC] = useState<any>(null);
	const [myPopup, setMyPopup] = useState<boolean>(false)
	const [stage, setStage] = useState(MyStage.PICKTEMPLATE)
	const [stageMessage, setStageMessage] = useState( "");
	const [showPreview, setShowPreview] = useState(false);
	const [previewLines, setPreviewLines] = useState<string[]>([]);

	useEffect(() => {
		setLoaded( false );
		setTemplates([]);
		const payload2: any = { statuses : [ "Template" ] };
		const url = `/api/summary`;
        WhitespaceAPI.post( url, payload2 ).then( response => {
			console.log( `${response.data.length} templates from ${url}`);
			setTemplates( riskFilter.groupRisksSummary( response.data ) );	
			doSummary( "");		
		})}
	, []);

    const doSummary = ( grail ) => {
        grail = grail.trim();
		const statuses = [ "NotFinalised", "QuotationRequest", "QuotedByUnderwriter", "BindableQuote","FirmOrder" ];
        NotificationManager.success( grail ? `Fetching for ${grail}` : "Fetching first 60 risks");
        const payload: any = {statuses : statuses };
        if( grail ) {
            if( utils.validUMR( grail ) ) {
                payload.umrSearch = grail;
            } else {
                payload.nameSearch = grail;
            }
        }
		console.log( payload );
        setRisks( [] );
        setLoaded( false );
		whitespace(Query.POST, '/api/summary', payload, { silent: true } ).then( response => {
			// response = null if BindableQuote not known status so /api/summary gives a 400 error
			if( response ) {
				console.log( "POST /api/summary complete");
				// const sumRisks = riskFilter.groupAllRisks(response.data);
				const sumRisks = riskFilter.groupRisksSummary( response.data ); // , { status : statuses[0]} );
				setRisks( sumRisks );
				setLoaded(true);
			} else {
				payload.statuses = [ "NotFinalised","FirmOrder" ]
				console.log( payload );
				setRisks( [] );
				setLoaded( false );
				whitespace(Query.POST, '/api/summary', payload,  { silent: true } ).then(response => {
					console.log( "POST /api/summary complete");
					// const sumRisks = riskFilter.groupAllRisks(response.data);
					const sumRisks = riskFilter.groupRisksSummary( response.data ); // , { status : statuses[0]} );
					setRisks( sumRisks );
					setLoaded(true);
				});	
			}
		});
    }

	class DDItem {
		mrcHeading: string;
		page: string;
		tag: string;
		value: string;

		constructor( mrcHeading : string, page: string, tag: string, value: string ) {
			this.mrcHeading = mrcHeading;
			this.page = page;
			this.tag = tag;
			this.value = value;
		}

		onSamePage( item: DDItem) : boolean {
			return( this.page == item.page && this.mrcHeading == item.mrcHeading );
		}
	}
		
	const clickPreview = () => {
		copyFromQuoteToTemplate( false );
	};

	const copyFromQuoteToTemplate = ( doUpdate : boolean ) : boolean => {
		if( !riskMRC ) {
			console.log( "copyFromQuoteToTemplate no riskMRC");
			return false;
		}
		if( !templateMRC ) {
			console.log( "copyFromQuoteToTemplate no templateMRC");
			return false;
		}
		const qPages = DDPage.fromJMRC( riskMRC );
		const tPages = DDPage.fromJMRC( templateMRC );
		const lines: string[] = [];
		console.log( `DDPages : ${qPages.length} v. ${tPages.length}`);

		const qSingles: DDPage[] = [];
		const qRepeats: DDPage[] = [];
		qPages.forEach( p => {
			const count = qPages.filter( p2 => { return p2.mrcHeading == p.mrcHeading }).length;
			if( count == 1 ) {
				qSingles.push( p );
			} else {
				qRepeats.push( p );
			}
		});

		qSingles.forEach( q => {
			let copied = false;
			tPages.forEach( t => {
				if( q.matchForCopy( t ) ) {
					// const values = "Values " + q.points.map( qp => { return qp.value }).join( " / ") + " replace " + t.points.map( tp => { return tp.value }).join( " / ");
					// lines.push( `Can copy ${q.mrcHeading} to ${t.mrcHeading} : ${values}`);

					lines.push( `# ${q.mrcHeading}`);
					lines.push( q.points.map( qp => { return qp.value }).join( " / ") );
					lines.push( t.points.map( qp => { return qp.value }).join( " / ") );

					if( doUpdate ) {
						setDDValues( templateMRC, q, t );
					}
					copied = true;
				}
			});
			if( !copied ) {
				const values = "Values " + q.points.map( qp => { return qp.value }).join( " / ");
				lines.push( `# No target for ${q.mrcHeading} / ${q.parentID.substring(0,15)} : ${values}`);
			}
		});
		qRepeats.forEach( q => {
			let copied = false;			
			tPages.forEach( t => {
				if( copied ) {
					return;
				}
				if( q.matchForCopy( t, false ) ) {
					// const values = "Values " + q.points.map( qp => { return qp.value }).join( " / ") + " replace " + t.points.map( tp => { return tp.value }).join( " / ");
					// // lines.push( `Can copy ${q.mrcHeading} / ${q.parentID.substring(0,15)} to ${t.mrcHeading} / ${t.parentID.substring(0,15)} : ${values}`);
					// lines.push( `Can copy ${q.mrcHeading} to ${t.mrcHeading} : ${values}`);

					lines.push( `# ${q.mrcHeading}`);
					lines.push( q.points.map( qp => { return qp.value }).join( " / ") );
					lines.push( t.points.map( qp => { return qp.value }).join( " / ") );

					if( doUpdate ) {
						setDDValues( templateMRC, q, t );
					}
					copied = true;
				}
			});
			if( !copied ) {
				const values = "Values " + q.points.map( qp => { return qp.value }).join( " / ");
				// lines.push( `No target for ${q.mrcHeading} / ${q.parentID.substring(0,15)} : ${values}`);
				lines.push( `# No target for ${q.mrcHeading} : ${values}`);
			}
		});
		if( !doUpdate ) {
			setShowPreview( true );
			setPreviewLines( lines );
		}
		return true;
	}

	const setDDValues = ( mrc : JMRC.Root, source: DDPage, target: DDPage ) : any => {
		source.points.forEach( sp => {
			const tPoint = target.points.filter( tp => { return tp.tag == sp.tag; })[0];
			tPoint.value = sp.value;
			if( tPoint.placeholder) {
				tPoint.value = sp.value;
				tPoint.placeholder.value = sp.value; 
			}
		});
	}

	const chooseTemplate = (event) => {
		const tid = event.target.value;
		WhitespaceAPI.get( `/api/risks/${tid}`).then( response => {
			if( response.data && response.data.control ) {
				const x0 : JMRC.Root = response.data;
				setTemplateID( tid );
				setTemplateMRC( x0 );
			}
		});
	}

	const pickRisk = ( risk ) => {
		if( !templateID ) {
			NotificationManager.error( "Select a template first");
			return;
		}
		setStage( MyStage.CONFIRM );
		const riskID = risk.id;	 // + "::SIGNED";
		WhitespaceAPI.get( `/api/risks/${riskID}`).then( response => {
			if( response.data && response.data.control ) {
				console.log( response.data.control );

				const x : JMRC.Root = response.data;
				setRiskMRC( x );
			}
		});
    }

	const preUpdate = () => {
		after = "";
		setStage( MyStage.UNDERWAY );
	}

	const updateAndSave = () => {
		preUpdate();

		if( !copyFromQuoteToTemplate( true ) ) {
			NotificationManager.error( "Unable to copy the quote values into the template");
			return;
		}
		const undeleteableHeadings = [ "Insured", "Reinsured", "UMR", "PolicyNumber","Information"];
		const renameLineItems = riskMRC.MRCContract.lineItems.filter( li => { return undeleteableHeadings.includes(li.mrcHeading); });
		if( renameLineItems.length > 0 ) {
			const msg = `${renameLineItems.length} to rename ` + renameLineItems.map( li => { return `${li.index}:${li.mrcHeading}`} ).join( ", ");
			console.log( `updateAndSave ${msg}` );
			const indexes = renameLineItems.map( li => { return li.index; } );
			overwriteUndeleteableHeading( riskMRC._rev, indexes );
		} else {
			deleteAllByIndex( riskMRC._rev );
		}
	}

	const overwriteUndeleteableHeading = ( riskRev : string, indexArray : number[] ) => {
		if( indexArray.length <= 0 ) {
			console.log( "overwriteUndeleteableHeading : None left!" );
			deleteAllByIndex(riskRev);
			return;
		}
		const index = indexArray.pop();
		console.log( `overwriteUndeleteableHeading deleting ${index}, ${indexArray.length} remain` );
		const elements = [{"text":"DELETEME",index:0}];
		const payload : any = { op : "edit", _rev: riskRev, index: index, elements:elements, placeholders:[], newHeading: "DELETEME",
			tagPages: [{pageID: "93DD9BE1-6B38-4AD4-B9D0-CE80E78D9269",title: "",sectionIDs: []}],
			attributes: { locked: false }  };
		const url = `/api/risks/${riskMRC._id}/editLineItem`;
		WhitespaceAPI.post( url, payload ).then( response => {
			console.log( response.data );
			if( response.data.msg == "OK" ) {
				const newrev = response.data._rev;
				overwriteUndeleteableHeading( newrev, indexArray );
			} else {
				NotificationManager.error( JSON.stringify( response.data ));
			}
		});
	}

	const deleteAllByIndex = ( riskRev : string ) => {
		const deleteIndexes = riskMRC.MRCContract.lineItems.map( li => { return li.index; }).filter( x => x > 0 );
		const deletePayload = { insert: [], delete: deleteIndexes, modify: [], _rev : riskRev };
		console.log( deletePayload );
		const url = `/api/risks/${riskMRC._id}/changeHeadings`;
		WhitespaceAPI.post( url, deletePayload ).then( response => {
			console.log( response.data );
			const rev = response.data._rev;
			const templateIndexes = templateMRC.MRCContract.lineItems.map( li => { return li.index; }).filter( x => x > 0 );
			const maxIndex = Math.max( ...templateIndexes );
			console.log( `${templateIndexes.length} template indexes, max ${maxIndex}`);
			editLineItem( 1, maxIndex, rev );
		});

	}
	
	const editLineItem = ( index, maxindex, rev ) => {
		if( index > maxindex ) {
			console.log( `editLineItem finished ${index} exceeds ${maxindex}`);
			NotificationManager.success( `Saved ${maxindex} line items`);
			setStage( MyStage.COMPLETE );
			return;
		}
		const li = templateMRC.MRCContract.lineItems.filter( li => li.index == index )[0];
		if( !li ) {
			editLineItem( index + 1, maxindex, rev );
			return;
		}
		const msg = `${index} of ${maxindex} : ${li.originalHeading}`;
		setStageMessage( msg );
		if( li.originalSection == "self" ) {
			addSection( index, maxindex, rev, li );
			return;
		}
		const payload : any = { op : "insert", _rev: rev, index: index - 1 };
		payload.newHeading = li.originalHeading;
		payload.mrcHeading = li.mrcHeading;
		if( li.locked === true || li.locked === false ) {
			payload.attributes = { locked : li.locked };
		}
		payload.elements = li.elements;
		payload.placeholders = li.placeholders;
		payload.tagPages = li.tagPages;
		const url = `/api/risks/${riskMRC._id}/editLineItem`;
		WhitespaceAPI.post( url, payload ).then( response => {
			console.log( response.data );
			if( response.data.msg == "OK" ) {
				const newrev = response.data._rev;
				editLineItem( index + 1, maxindex, newrev );
			} else {
				NotificationManager.error( JSON.stringify( response.data ));
			}
		});
	}

	let after = "";

	const addSection = ( index, maxindex, rev, li ) => {
		if( !after ) {
			after = "RiskDetails";
		}
		const payload = { new_section: li.mrcSection, new_section_title: li.originalHeading, rev: rev, after: after };
		after = li.mrcSection;
		const url = `/api/risks/${riskMRC._id}/insertSection`;
		WhitespaceAPI.post( url, payload ).then( response => {
			console.log( response.data );
			if( response.data._rev ) {
				const newrev = response.data._rev;
				editLineItem( index + 1, maxindex, newrev );
			} else {
				NotificationManager.error( JSON.stringify( response.data ));
			}
		});
	}

	const clickDone = () => {
		setRiskMRC( null );
		setStage( MyStage.PICKTEMPLATE );
	}
	const clickBack = () => {
		setRiskMRC(null);
		setStage( MyStage.PICKRISK );
	}
	const clickNext = () => {
		if( !templateID ) {
			NotificationManager.error( "Choose a template first");
			return;
		}
		setStage( MyStage.PICKRISK );
	}

	// const  = ( str, idx ) => {
	// 	if( str.startsWith( "#" ) ) {
	// 		return (  )
	// 	} else {
	// 		return ( <div className='XSmallText' key={idx}>{str}</div> )
	// 	}
	// }

	const SummaryLine = ( { str, idx }) => {
		if( str.startsWith( "#")) {
			return (
				<div className='Above12' key={idx}>{str.substring(2)}</div>
			)	
		} else {
			return (
				<div className='XSmallText' key={idx}>{str}</div>
			)
		}
    }


	return (
        <>
        {!loaded && <PageLoading/>}
        {loaded && !risks.length && <div className="page-notice"><NoResultsFound />No Bindable Quotes</div>}
		{stage == MyStage.PICKTEMPLATE && loaded && 
		<>
		<h2>Step 1 : Pick a template to apply</h2>
		<Form.Control
			as="select"
			className="my-1"
			onChange={chooseTemplate}
			name="templatePair"
			value={templateID}
			custom>
			<option value=''>Select ...</option>
			{templates.map((fac, idx) => <option value={`${fac.rootID}`} key={idx}>{fac.InsuredName}</option>)}
		</Form.Control>
		<Button onClick={clickNext}>Next</Button>
		</>
		}
		{
			!riskMRC && stage == MyStage.PICKRISK && loaded &&
			<>
			<h2>Step 2 : Choose the risk to apply the template to</h2>
			<div className='Above12'>
				<Button onClick={() => { setStage( MyStage.PICKTEMPLATE ); } }>Back</Button>
				&nbsp;
				Template: {templateMRC?.control?.insuredName || "-"}
			</div>
			<SummaryFilter onSearch={doSummary}></SummaryFilter>
			<RiskGrid input={risks} pickFn={pickRisk} singleLine={false} /> 
			</>
		}
		{riskMRC && stage == MyStage.CONFIRM &&
			<>
			<h2>Step 3 : Apply the template</h2>
			<div>
				<Button onClick={clickBack}>Back</Button>
				&nbsp;<Button onClick={clickPreview}>Preview</Button>
			</div>
			<div className='Above12'>Template: {templateMRC?.control?.insuredName || "-"}</div>
			<div className='Above12'><MRCViewer lineItems={riskMRC.MRCContract.lineItems}/></div>
			<div className='Above12'><Button onClick={updateAndSave}>Apply the template</Button></div>
			</>
		}
		{stage == MyStage.UNDERWAY && 
			<>
			<h2>Processing</h2> 
			<div>{stageMessage}</div>
			</>
		}
		{stage == MyStage.COMPLETE && 
			<>
			<h2 className='SuccessMessage'>Complete</h2>
			<Button onClick={clickDone}>Done</Button>
			</>
		}
		{
			<Modal size="lg" show={showPreview} onHide={() => setShowPreview(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Preview</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{previewLines.map( (str, idx ) => { return <SummaryLine str={str} idx={idx}></SummaryLine> })}
				</Modal.Body>
			</Modal>
		}
        </>
    )
}
