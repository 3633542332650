import React, { useState, useEffect } from 'react';
import { Modal, Col, Row, Container, Button, Tab, Tabs, Form } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import { WhitespaceAPI } from '../../helpers/whitespace';
import { BNFFacilityID, BNFPayload, SDDataModel, SDTFPayload, SIHardCoded } from './SDDataModel';
import { ShowInstruction } from './ShowInstruction';
import { StampPicker } from './StampPicker';
import { payloads } from '../../helpers/payloads';
// const [percentageTotal,setPercentageTotal] = useState( 0.0 );

interface SIUpdate2 {
	stamperChannel: string;
	stampID: string;
	selected: boolean;
	contractLeader: string;
	role : string;	
	percentage: number;
}

export const SectInst = ( { sddm, decSectID, facSectID, goBackAfterShowing } ) => {
	const [myDataModel, setMyDataModel] = useState<SDDataModel>(sddm);
	const [declarationSectID, setDeclarationSectID] = useState( decSectID );
	const [facilitySectID, setFacilitySectionId] = useState( facSectID );
	const [facGuidanceState,setFacGuidanceState] = useState( myDataModel.getGuidanceState(declarationSectID,facilitySectID) );
	const [dataGrid,setDataGrid] = useState<SIUpdate2[]>( [] );
	const [totalPercentage,setPercentageTotal] = useState( 0.0 );
	const [showDialog,setShowDialog] = useState(false);
	const [showPostSave,setShowPostSave] = useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [stepConfirmed, setStepConfirmed] = useState(false);
	const [postURL, setPostURL] = useState( "");
	const [postPayload, setPostPayload] = useState<any>(null);
	const [myPayload,setMyPayload] = useState<any>( {} );
	const [hasBeenShownToLeader, setHasBeenShownToLeader] = useState( sddm.hasBeenShownToLeader( decSectID, facSectID ))
	const [verticalVisibility,setVerticalVisibility] = useState( "DoNotSet");

	const afterShowing = () => {
		goBackAfterShowing();
	};

	useEffect(() => {
		const grid : SIUpdate2[] = [];
		myDataModel.prepareInstructions(declarationSectID, facilitySectID).map( (instruction,idx) => {
			const perc = instruction.content.impressions.reduce( (x,y) => { return x + parseFloat(y.signedLinePercentageString)}, 0.0 );
			const update : SIUpdate2 = { stamperChannel: instruction.content.stamperChannel, stampID: SIHardCoded.UNCHOSEN, selected: false, contractLeader: "No", role: instruction.content.role, percentage: perc };
			grid.push( update );
		});
		setDataGrid( grid );
		calcTotPerc( grid );
	}, []);

	const calcTotPerc = ( grid ) : number => {
		let totPerc = 0.0;
		grid.forEach(element => {
			const perc = parseFloat( element.percentage );
			totPerc += isNaN(perc) ? 0.0 : perc;
		});
		// const totPerc = grid.reduce( (x,y) => { return x + y.percentage; }, 0.0 );
		setPercentageTotal( totPerc );
		return totPerc;
	}

	const updateMe = ( ob : SIUpdate2 ) => {
		const current = dataGrid.filter( item => { return item.stamperChannel != ob.stamperChannel });
		current.push( ob );
		setDataGrid( current );
		calcTotPerc( current );
	}


	const setLeader = ( doSave ) : any => {
		const payload = makeSetLeaderPayload();
		if( !doSave ) {
			setMyPayload( payload );
			setShowDialog( true );
			return;
		}
		if( payload.error ) {
			NotificationManager.error( payload.error );
			return;
		}
		askForConfirmation( "/api/showDeclarationToFacility", payload );
	}

	const setAgreementParty = ( doSave ) : any => {
		const payload = makeAgreementPartyPayload();
		if( !doSave ) {
			setMyPayload( payload );
			setShowDialog( true );
			return;
		}
		if( payload.error ) {
			NotificationManager.error( payload.error );
			return;
		}
		askForConfirmation( "/api/showDeclarationToFacility", payload );
	}

	const makeSetLeaderPayload = () : any => {
		const selected = dataGrid.filter( x => { return x.selected });
		if( selected.length != 1 ) {
			return { error : `Please select one company as the Leader` };
		}
		const totPerc = calcTotPerc( dataGrid );
		if( totPerc <= 0 || totPerc > 100.0 ) {
			return { error : `Total Percentage must be greater than 0 and no more than 100, not ${totPerc}` };
		}
		if( selected[0].stampID == SIHardCoded.UNCHOSEN ) {
			return { error : "Select the stamp to use, or let the underwriter choose"};
		}
		// myDataModel.buildSDTF();
		// const payload : SDTFPayload = myDataModel.sdtf;
		const payload = myDataModel.buildSDTF();
		// AllStamps / LeaderStamps / OwnStampOnly ... would be nice to have a choice
		// payload.verticalVisibility = "LeaderStamps"; // to be introduced with 2.15 Q4 2023 JHP/AJC
		if( verticalVisibility != "DoNotSet") {
			payload.verticalVisibility = verticalVisibility;
		}
		payload.sections.forEach( s => {
			if( s.facilitySectionID == facilitySectID && ( s.sectionID == declarationSectID || s.sectionID == SIHardCoded.SECTION0 ) ) {
				s.leader = (selected[0].contractLeader == "Yes");
				Object.keys( s.carriers ).forEach( key => {
					const carrier = s.carriers[ key ];
					const item = dataGrid.filter( x => { return x.stamperChannel == key })[0];
					if( item ) {
						if( item.stamperChannel == selected[0].stamperChannel ) {
							console.log( `Updating all of ${key} to ${item.percentage}% ${item.role} etc`);
							carrier.value = item.percentage.toString();
							carrier.role = item.role;
							carrier.valueIsLeaderLineGuidance = ( item.role == "leader");
							carrier.show = true;
							carrier.suggestedStampIDs = [];
							if( item.stampID.startsWith( "ST") ) {
								carrier.suggestedStampIDs.push( item.stampID );
							}						
						} else {
							carrier.role = item.role;
							carrier.value = item.percentage.toString();
						}
					}
				});
			}
		})
		payload.sections.forEach( s => {
			if( s.sectionID == SIHardCoded.SECTION0 ) {
				delete s.sectionID;
			}
			if( s.facilitySectionID == SIHardCoded.SECTION0 ) {
				delete s.facilitySectionID;
			}
		});
		return payload;
	}

	const makeAgreementPartyPayload = () : any => {
		const selected = dataGrid.filter( x => { return x.selected });
		if( selected.length != 1 ) {
			return { error : `Please select one agreement party` };
		}
		const item = selected[0];
		if( item.role != "agreementParty" ) {
			return { error : `The selected carrier should have a role of "agreementParty" not ${item.role}` };
		}
		if( item.stampID == SIHardCoded.UNCHOSEN ) {
			return { error : "Select the stamp to use, or let the underwriter choose"};
		}
		// myDataModel.buildSDTF();
		// const payload : SDTFPayload = myDataModel.sdtf;
		const payload = myDataModel.buildSDTF();
		// payload.debugMessage = "HELLO WORLD";
		// payload.dataGrid = [];
		// dataGrid.forEach( x => {
		// 	payload.dataGrid.push( x );
		// });
		const instructions = myDataModel.prepareInstructions(declarationSectID, facilitySectID);
		// payload.instructions = instructions;

		payload.sections.forEach( s => {
			if( s.facilitySectionID == facilitySectID && s.sectionID == declarationSectID ) {
				const suggestedStampIDs: string[] = [];
				if( item.stampID.startsWith( "ST") ) {
					suggestedStampIDs.push( item.stampID );
				}
				s.carriers[ item.stamperChannel ] = { value: item.percentage.toString(), role: item.role, 
					valueIsLeaderLineGuidance : ( item.role == "leader"),
					show: true, suggestedStampIDs: suggestedStampIDs };

				// dataGrid.forEach( x => {
				// 	if( x.role == "notifyParty") {
				// 		s.carriers[ x.stamperChannel ].value = x.percentage.toString();
				// 	}
				// });
				instructions.forEach( x => {
					if( x.content.role == "notifyParty" || x.content.role == "nonNotifyParty" ) {
						s.carriers[ x.content.stamperChannel ].value = x.percentage.toString();
					}
				});

				// const carrier = s.carriers[ item.stamperChannel ];
				// carrier.value = item.percentage.toString();
				// carrier.role = item.role;
				// carrier.valueIsLeaderLineGuidance = ( item.role == "leader");
				// carrier.show = true;
				// carrier.suggestedStampIDs = [];
				// if( item.stampID.startsWith( "ST") ) {
				// 	carrier.suggestedStampIDs.push( item.stampID );
				// }
			}
		});
		payload.sections.forEach( s => {
			if( s.sectionID == SIHardCoded.SECTION0 ) {
				delete s.sectionID;
			}
			if( s.facilitySectionID == SIHardCoded.SECTION0 ) {
				delete s.facilitySectionID;
			}
		});
		return payload;
	}

	////////////////////////////////////

	const bindNotifyParties = ( doSave ) => {
		const parties = dataGrid.filter( x => { return x.role == "notifyParty" || x.role == "nonNotifyParty" || x.role == "doesNotApply"} );
		if( parties.length == 0 ) {
			NotificationManager.error( "There are no notify, non-notify or does not apply parties to bind");
			return;
		}
		const unchosenCount = parties.filter( x => { return x.stampID == SIHardCoded.UNCHOSEN }).length;
		if( unchosenCount > 0 ) {
			NotificationManager.error( "For each party, select a stamp or leave to underwriter to choose");
			return;
		}
		
		const sTable: any = {};
		parties.forEach( p => { 
			if( p.stampID.startsWith( "ST" ) ) {
				sTable[ p.stamperChannel ] = p.stampID;
			}
		});
		const fid : BNFFacilityID = { facilityID: myDataModel.facID, sectionID: facilitySectID };
		if( fid.sectionID == SIHardCoded.SECTION0 ) {
			delete fid.sectionID;
		}
		const payload : BNFPayload = { declarationID: myDataModel.declarationMRC?._id || "", sectionID: declarationSectID, facilityID: fid, suggestedStampID: sTable };
		if( payload.sectionID == SIHardCoded.SECTION0 ) {
			delete payload.sectionID;
		}

		if( !doSave ) {
			setMyPayload( payload );
			setShowDialog( true );
			return;
		}
		const url = "/api/bindNotifyParties";
		askForConfirmation(url, payload);	
	}

	////////////////////////////////////

	// const preview = () => {
	// 	const x = makePayload();
	// 	if( x.error ) {
	// 		return;
	// 	} else {
	// 		setMyPayload( x );
	// 		setShowDialog( true );
	// 	}
	// }

	const copyToClipboard = () => {
		const json = JSON.stringify(myPayload,null,4);
		navigator.clipboard.writeText(json);
		NotificationManager.success( `Copied ${json.length} bytes to clipboard`);
	}

	const askForConfirmation = ( url, payload ) => {
		setPostURL( url );
		setPostPayload( payload );
		setStepConfirmed(false);
		setShowConfirmDialog(true);
	}

	const confirmButtonClicked = () => {
		setShowConfirmDialog(false);
		makeChangesAndReturn( postURL, postPayload );
	}

	const makeChangesAndReturn = ( url, payload ) => {	// previously known as callAPI !
		WhitespaceAPI.post( url, payload ).then( response => {
			if( response.reason ) {
				NotificationManager.error( `Error ${response.reason} calling ${url}`);
			} else {
				setShowPostSave( true );
				setTimeout( afterShowing, 5000 );
			}
		});
	}

	const ConfirmDialog = () => {
		return (
			<Modal size="sm" show={showConfirmDialog} onHide={() => setShowConfirmDialog(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Confirm</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>Are you sure you would like to show this document to the chosen carriers?</div>
					<div className='FloatRight Above12'>
						<Button onClick={confirmButtonClicked}>OK</Button>
						&nbsp;<Button onClick={() => setShowConfirmDialog(false) }>Cancel</Button>
					</div>
				</Modal.Body>
			</Modal>
		)
	}

	const PostSaveDialog = () => {
        return (
          <Modal size="lg" show={showPostSave} onHide={() => setShowPostSave(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Data Saved</Modal.Title>
            </Modal.Header>
            <Modal.Body>
				You will now be returned to the section selection stage, to refresh the data.
				<div className='FloatRight Above12'>
					<Button onClick={() => setShowPostSave(false) }>OK</Button>
				</div>
            </Modal.Body>
          </Modal>
        )
    }

	const onChangeVerticalVisibility = ( event ) => {
		setVerticalVisibility( event.target.value );
	}

	const APIDialog = () => {
        return (
          <Modal size="lg" show={showDialog} onHide={() => setShowDialog(false)}>
            <Modal.Header closeButton>
              <Modal.Title>API Payload
				&nbsp;|&nbsp;<span className='MyLink' onClick={copyToClipboard}>Copy</span>
				</Modal.Title>
            </Modal.Header>
            <Modal.Body>
				<pre className='XSmallText'>{JSON.stringify(myPayload,null,4)}</pre>
            </Modal.Body>
          </Modal>
        )
    }

	return (
		<>
		<Container>
			{!hasBeenShownToLeader &&
				<Row className='Above12'>
					<Col xs={4} className='RightAlign'>Leader</Col>
					<Col xs={4}><Button onClick={() => setLeader(false)}>Preview</Button> <Button onClick={() => setLeader(true)}>Show to Leader</Button></Col>
					<Col xs={4}>Vertical Visibility&nbsp; 
					<select value={verticalVisibility} onChange={onChangeVerticalVisibility}>
						<option value="DoNotSet">Do Not Set</option>
						<option value="AllStamps">All Stamps</option>
						<option value="LeaderStamps">Leader Stamps</option>
						<option value="OwnStampOnly">Own Stamp Only</option>
					</select>
					</Col>
				</Row>
			}
			{hasBeenShownToLeader &&
				<>
				<Row className='Above12'>
					<Col xs={4} className='RightAlign'>Agreement Parties</Col>
					<Col xs={8}><Button onClick={() => setAgreementParty(false)}>Preview</Button> <Button onClick={() => setAgreementParty(true)}>Show to Agreement Party</Button></Col>
				</Row>
				<Row className='Above12'>
					<Col xs={4} className='RightAlign'>Notify and Non-Notify Parties</Col>
					<Col xs={8}><Button onClick={() => bindNotifyParties(false)}>Preview</Button> <Button onClick={() => bindNotifyParties(true)}>Bind Notify Parties</Button></Col>
				</Row>
				</>
			}
		</Container>
		{/* <div className='Above12'>
			
			{facGuidanceState == SIHardCoded.noFacGuidanceYet && 
				<>&nbsp;</>
			}
			{(facGuidanceState == "leaderWritten" || decSectID == SIHardCoded.SECTION0 || facSectID == SIHardCoded.SECTION0 ) && 
				<>
				&nbsp;
				&nbsp;Bind Notify Parties : 
				&nbsp;
				</>
			}
			&nbsp; {facGuidanceState} : {myDataModel.describeState(declarationSectID,facilitySectID)}
		</div> */}
		{facGuidanceState == "shownToLeader" && <div className='WarningBanner'>Waiting for the leader to write their line</div>}
		{facGuidanceState == "leaderWritten" && <div className='WarningBanner'>Set agreement parties, then bind notify parties</div>}
		{facGuidanceState == "notifyPartiesBound" && <div className='WarningBanner'>Completed - notify parties have been bound</div>}
		<Container className='Above12'>
			<Row className='SmallText'>
				<Col xs={1}>Select</Col>
				<Col xs={2}>Percentage {!hasBeenShownToLeader && <span>{totalPercentage}%</span> }</Col>
				<Col xs={1}>Contract Leader</Col>
				<Col xs={2}>Role</Col>
				<Col xs={6}>Stamp</Col>
			</Row>
		</Container>
		{myDataModel.prepareInstructions(declarationSectID, facilitySectID).map( (inst,idx) => {
			return ( 
				<div key={idx}><InstEditor inst={inst} updateParent={updateMe} state={facGuidanceState}/></div>
			)
		})}
		<div className='Above12 HideMe'>
			{
				Object.keys( dataGrid ).map( (key,idx) => {
					return( <div key={idx}>{key} : {dataGrid[key].percentage} : S:{dataGrid[key].selected} : {dataGrid[key].contractLeader} : {dataGrid[key].role} : {dataGrid[key].stampID}</div> )
				})
			}
		</div>
		<APIDialog/>
		<ConfirmDialog />
		<PostSaveDialog/>
		</>
	)
}

export const InstEditor = ( { inst, updateParent, state } ) => {
	const [instruction, setInstruction] = useState<ShowInstruction>( inst );
	const [facGuidanceState, setFacGuidanceState] = useState( state );
	const [percentage, setPercentage] = useState( inst.percentage );
	const [selected,setSelected] = useState(false);
	const [contractLeader,setContractLeader] = useState("No");
	const [role,setRole] = useState(inst.content.role);
	const [stampUniqueID,setStampUniqueID] = useState( SIHardCoded.UNCHOSEN );

	const onChangePercentage = (event) => {
		const perc : number = parseFloat( event.target.value );
		instruction.percentage = isNaN(perc) ? 0.0 : perc;
		setPercentage( isNaN(perc) ? "0" : event.target.value ); // needed to preserve decimal point as it it typed // instruction.percentage );
		sendUpdate( "percentage", instruction.percentage );
	}

	const onChangeStamp = ( event ) => {
		setStampUniqueID( event.target.value );
		sendUpdate( "stampID", event.target.value );
	}

	const onChangeSelected = ( event ) => {
		const checked = event.target["checked"];
		setSelected( checked ? true : false );
		sendUpdate( "selected", checked ? true : false );
	}
	
	const onChangeContractLeader = ( event ) => {
		setContractLeader( event.target.value );
		sendUpdate( "contractLeader", event.target.value );
	}

	const onChangeRole = ( event ) => {
		setRole( event.target.value );
		sendUpdate( "role", event.target.value );
	}

	const sendUpdate = ( key, newValue ) => {
		const update : SIUpdate2 = { stamperChannel: instruction.content.stamperChannel, stampID: stampUniqueID, selected: selected, contractLeader: contractLeader, role: role, percentage: percentage };
		update[ key ] = newValue;
		updateParent( update );
	}
	const editableTriple = () => {
		return facGuidanceState == SIHardCoded.noFacGuidanceYet;
	}

	return (
		<>
		<Container className='Above12'>
			<Row>
				<Col xs={12}>{inst.heading}</Col>
			</Row>
			<Row>
			<Col xs={1}>
				<input type="checkbox" onChange={onChangeSelected} />
			</Col>
			{ editableTriple() && 
				<>
				<Col xs={2}>
					<input className='FullWidth' type="text" value={percentage} onChange={onChangePercentage}/>
				</Col>
				<Col xs={1}>
					<select className='FullWidth' value={contractLeader} onChange={onChangeContractLeader}>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</Col>

				<Col xs={2}>
					<select className='FullWidth' value={role} onChange={onChangeRole}>
						<option value="leader">leader</option>
						<option value="agreementParty">agreementParty</option>
						<option value="notifyParty">notifyParty</option>
						<option value="nonNotifyParty">nonNotifyParty</option>
						<option value="doesNotApply">doesNotApply</option>
					</select>
				</Col>
				</>
			}
			{ !editableTriple() && 
				<>
				<Col xs={2}>{percentage}%</Col>
				<Col xs={1}>{contractLeader}</Col>
				<Col xs={2}>{role}</Col>
				</>
			}
			<Col xs={6}>
				<StampPicker imps={instruction.content.impressions} onChange={onChangeStamp}/>
			</Col>

			</Row>
		</Container>

		</>
	)	
}
