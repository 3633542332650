import { CombinedSets } from "../../helpers/CombinedSets";
import { SDDataModel } from "./SDDataModel";

export class ShowInstruction {
	content : CombinedSets.Content;
	heading : string;
	state : string;
	percentage : number;
	fixedPerc : boolean;
	
	constructor( content : CombinedSets.Content, heading : string, state : string, proposedLineGuidance : number ) {
		this.content = content;
		this.heading = heading;
		this.state = state;
		this.percentage = proposedLineGuidance > 0 ? proposedLineGuidance : content.impressions.reduce( (x,y) => { return x + parseFloat(y.signedLinePercentageString)}, 0.0 );
		this.fixedPerc = ( proposedLineGuidance > 0 );
	}
}