import { utils } from "../FromElsewhere/utils";
import { DataRuleTranslator } from "./DataRuleTranslator";
import { DDFieldMap } from "./DataRules";

const TAX_THREE_HEADINGS = "TaxPayableByInsurers / TaxesPayableByInsurersAndAdministeredByInsuredOrTheirAgent / TaxesPayableByInsuredAndAdministeredByInsurers"
export class DataRuleFieldMapper {
	throwError = false;
	fieldLookup = [
		["policyreference","UMR","Broker_Reference"],
		["grouppolicyreference","PlacingData","Contract_Group_Name"],
		["facilityreference","AttachingToLineslipContractOrBindingAuthorityAgreementNumber","Facility_Reference"],
		["uniquemarketreference","UMR","Broker_Reference"],
		["placingbrokercontractsectionreference","UniqueMarketReference","Broker_reference+Section_identifier / Broker_Contract_Section_Reference"],
		["insuranceorreinsurance","Information","Insurance_Type"],
		["reinsurancetype","ContractType","Reinsurance_Type"],
		["lloydsrenewalindicator","PlacingData","Lloyds_Renewal_Indicator"],
		["treatyorfacultative","ContractType","Treaty_Or_Facultative"],
		["proportionalornonproportional","ContractType","Proportional_Or_Non-Proportional"],
		["retrocession","ContractType","Retrocession"],
		["policyholderclassification","RegulatoryClientClassification","Client_Classification"],
		["policyholdername","Policyholder","Policyholder_Name"],
		["policyholderlocationstreetnoandstreet","Policyholder","Policyholder_Number_And_Street"],
		["policyholderlocationcity","Policyholder","Policyholder_City_Name"],
		["policyholderlocationziporpostcode","Policyholder","Policyholder_Postal_Code"],
		["policyholderlocationcountrysubdivision","Policyholder","Policyholder_Country_Subentity",DataRuleTranslator.Subentity],
		["policyholderlocationcountry","Policyholder","Policyholder_Country",DataRuleTranslator.Country],
		["policyholderidentificationcode","Insured","Insured_Identifier"],
		["policyholderidentificationcodeset","Insured","Insured_Identification_Agency"],
		["policyholderindustrialsectorcode","IndustrialSectorCode","Industrial_Sector_Code"],
		["policyholderindustrialsectorcodeset","IndustrialSectorCode","Industrial_Sector_Code_Agency"],
		["additionalinsuredaffiliationtype","Insured /  Reinsured","Additional_Insured_Affiliation_Type / Additional_Reinsured_Affiliation_Type"],
		["additionalinsuredname","Insured","Additional_Insured_Name"],
		["additionalinsuredlocationstreetnoandstreet","Insured","Additional_Insured_Number_And_Street"],
		["additionalinsuredlocationcity","Insured","Additional_Insured_City_Name"],
		["additionalinsuredlocationziporpostcode","Insured","Additional_Insured_Postal_Code"],
		["additionalinsuredlocationcountrysubdivision","Insured","Additional_Insured_Country_Subentity",DataRuleTranslator.Subentity],
		["additionalinsuredlocationcountry","Insured","Additional_Insured_Country",DataRuleTranslator.Country],
		["placingbrokeruniqueidentifier","PlacingData","Placing_Broker_Unique_Identifier"],
		["placingbrokername","","Derived"],
		["placingbrokerlocationstreetnoandstreet","","Derived"],
		["placingbrokerlocationcity","","Derived"],
		["placingbrokerlocationziporpostcode","","Derived"],
		["placingbrokerlocationcountrysubdivision","","Derived"],
		["placingbrokerlocationcountry","","Derived"],
		["placingbrokeruniqueidentifieragency","PlacingData","Placing_Broker_Unique_Identifier_Agency"],
		["otherintermediaryrole","OverseasBroker","Additional_Intermediary_Role"],
		["otherintermediaryname","OverseasBroker /  OverseasBroker /  OverseasBroker","Other_Broker_Name / Correspondent_Broker_Name / Producing_Broker_Name"],
		["otherintermediarylocationstreetnoandstreet","OverseasBroker /  OverseasBroker /  OverseasBroker","Other_Broker_Address_Number_And_Street / Correspondent_Broker_Address_Number_And_Street / Producing_Broker_Address_Number_And_Street"],
		["otherintermediarylocationcity","OverseasBroker /  OverseasBroker /  OverseasBroker","Other_Broker_Address_City_Name / Producing_Broker_Address_City_Name / Correspondent_Broker_Address_City_Name"],
		["otherintermediarylocationziporpostcode","OverseasBroker /  OverseasBroker /  OverseasBroker","Other_Broker_Address_Postal_Code / Producing_Broker_Address_Postal_Code / Correspondent_Broker_Address_Postal_Code"],
		["otherintermediarylocationcountrysubdivision","OverseasBroker /  OverseasBroker /  OverseasBroker","Correspondent_Broker_Address_Country_Subentity / Other_Broker_Address_Country_Subentity / Producing_Broker_Address_Country_Subentity",DataRuleTranslator.Subentity],
		["otherintermediarylocationcountry","OverseasBroker /  OverseasBroker /  OverseasBroker","Other_Broker_Address_Country / Producing_Broker_Address_Country / Other_Broker_Address_Country / Correspondent_Broker_Address_Country",DataRuleTranslator.Country],
		["surpluslinestransactionnumber","SurplusLinesBroker","Surplus_Lines_Broker_Transaction_Number"],
		["surpluslinesbrokerlicencenumber","SurplusLinesBroker","Surplus_Lines_Broker_Licence_Number"],
		["otherintermediaryuniqueidentifier","","Do not capture. We have tags for Coverholder Pin and Unique Reference Number"],
		["otherintermediaryuniqueidentifiertype","","Do not capture. We have tags for Coverholder Pin and Unique Reference Number"],
		["premiumgross","Premium","Premium_Amount",DataRuleTranslator.AmountFigure],
		["premiumcontractualcurrency","Premium","Premium_Amount",DataRuleTranslator.AmountCurrency],
		["instalmentamount","Premium / PremiumPaymentTerms","Instalment_Amount"],
		["discountorfeeamount","FeePayableByClient /  FeesPayableToReInsurers /  PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder /  FeePayableByClient","Fee_Payable_By_Client_Amount / Fee_Amount / Premium_Discount_Amount / Fee_Payable_By_Client"],
		["premiumbasispercentage","Premium","Premium_Application_Basis"],
		["premiumtype","Premium","Premium_Type"],
		["instalmentamountcurrency","Premium","Captured with Instalment_Amount"],
		["discountorfeetype","OtherDeductionsFromPremium /  FeesPayableToReInsurers /  OtherDeductionsFromPremium","Fee_Type / Discount_Type"],
		["discountorfeecalculatedat","OtherDeductionsFromPremium /  FeesPayableToReInsurers /  FeePayableByClient","Discount_Class / Fee_Payable_At / Fee_Payable_By_Client_Payable_At"],
		["discountorfeepercentage","OtherDeductionsFromPremium /  FeesPayableToReInsurers /  OtherDeductionsFromPremium","Fee_% / Discount_%"],
		["discountorfeebasis","OtherDeductionsFromPremium /  OtherDeductionsFromPremium","Fee_Basis / Discount_Basis"],
		["discountorfeecurrency","Premium /  FeePayableByClient","Captured with Fee_Amount / Captured with Discount_Amount / Fee_Payable_By_Client_Amount"],
		["discountorfeeindicator","OtherDeductionsFromPremium","Discount_Or_Fee_Indicator"],
		["premiumperiodindicator","Premium","Premium_Period_Qualifier"],
		["premiumrate","Premium","Premium_Rate_%"],
		["premiumratebasis","Premium","Premium_Rate_Basis"],	// RWDefinedData misssing
		["instalmentpercentage","Premium /  PremiumPaymentTerms","Instalment_%"],
		["premiumsigningindicator","PremiumSigning","Premium_Signing_Indicator"],
		["premiumdiscountindicator","Generated in the endpoint","Premium_Discount_Indicator"],
		["discountorfeepayableto","OtherDeductionsFromPremium /  FeesPayableToReInsurers /  OtherDeductionsFromPremium","Discount_Payable_To / Fee_Payable_To"],
		["suminsuredorlimitamount","LimitOfLiability","Coverage_Amount"],
		["suminsuredorlimitcurrency","LimitOfLiability","Captured with Coverage_Amount"],
		["suminsuredorlimitbasis","LimitOfLiability","Coverage_Basis"],
		["deductibleorexcessindicator","Deductibles / Excess","Deductible_Basis / Excess_Basis",">>>ReturnExcessOrDeductible"],
		["deductibleorexcessamount","Deductibles / Excess","Deductible_Amount / Excess_Amount"],
		["deductibleorexcesscurrency","Deductibles / Excess","Deductible_Amount / Excess_Amount"],
		["deductibleorexcessbasis","Deductibles / Excess","Deductible_Basis / Excess_Basis"],
		["deductibleorexcesspercentage","Excess / Deductibles","Excess_% / Deductible_%"],
		["reinsurercontractreference","","Generated in the endpoint"],
		["reinsureruniqueidentifier","","Generated in the endpoint"],
		["reinsurername","","Derived"],
		["basisofclaimagreement","BasisOfClaimsAgreement","Basis_of_Claims_Agreement"],
		["originalinsured","OriginalInsured","Original_Insured_Name"],
		["reinsurerplacementrole","","Bureau_Leader_Placement_Role"],
		["reinsurerendorsementrole","","Insurer_Endorsement_Role"],
		["reinsurerclaimsrole","ClaimsAgreementParties","Claims_Agreement_Role"],
		["reinsureruniqueidentifiertype","","Generated in the end point"],
		["reinsureruniquelinereference","Generated in the endpoint","Insurer_Contract_Reference /  Reinsurer_Contract_Reference"],
		["reinsurerclassofbusiness","","Need to add"],
		["brokersharepercentage","OrderHereon","Broker_Share_%"],
		["orderpercentage","OrderHereon","Order_%"],
		["reinsurersignedshare","Generated in the endpoint","Signed_Line_Percentage"],
		["signedsharebasis","BasisOfSignedLines","Basis_Of_Signed_Lines"],
		["linetostandindicator","Generated in the endpoint","Line_To_Stand_Indicator"],
		["datecontractenteredinto","Generated in the endpoint","Written_Date_Time"],
		["brokershareamount","OrderHereon","Broker_Share_Amount"],
		["brokershareamountcurrency","OrderHereon","Captured with Broker_Share_Amount"],
		["orderamount","OrderHereon","Order_Amount"],
		["orderamountcurrency","OrderHereon","Captured with Order_Amount"],
		["reinsurerwrittenshare","Generated in the endpoint","Written_Share"],
		["writtensharebasis","BasisOfWrittenLines","Basis_Of_Written_Lines"],
		["participationbasis","Generated in the endpoint","Participation_Basis"],
		["reinsurerwrittensharecurrency","Generated in the endpoint","Captured with Written_Share"],
		["insuranceperiodstart","Period","Inception_Date /  Inception_Time /  Time_Qualifier /  Time_Zone"],
		["insuranceperiodend","Period","Expiry_Date /  Expiry_Time /  Time_Qualifier /  Time_Zone"],
		["pureyearofaccount","","Derived"],
		["lloydsrenewaloriginalinceptiondate","PlacingData / OriginalPolicyPeriod","Lloyds_Renewal_Original_Inception_Date / Original_Inception_Date"],
		["retroactivedate","ClaimsBasis","Retroactive_Date"],
		["priorandpendinglitigationdate","ClaimsBasis","Prior_And_Pending_Litigation_Date"],
		["continuitydate","ClaimsBasis","Continuity_Date"],
		["dateinclusiveexclusiveindicator","Period /  Period","Inception_Date_Inclusive/Exclusive_Indicator / Expiry_Date_Inclusive/Exclusive_Indicator"],
		["perioddatestatus","Period","Need to add"],
		["yearofaccount","AllocationOfPremiumToYearsOf Account","Year_Of_Account"],
		["contractualexchangerate","Premium","Contractual_Exchange_Rate"],
		["premiumsettlementduedate","Premium /  PremiumPaymentTerms /  SettlementDueDate","Settlement_Due_Date"],
		["premiumsettlementcurrency","","Need to Add"],
		["adjustmentpremiumperiodofcredit","AdjustmentPremiumPeriodOfCredit","Adjustment_Premium_Period_Of_Credit"],
		["trustfundcode","","Derived"],
		["premiumadjustableindicator","Endpoint","Subjectivity_Indicator"],
		["instalmentpremiumperiodofcredit","InstalmentPremiumPeriodOfCredit","Instalment_Premium_Period_Of_Credit"],
		["instalmentduedate","Premium /  PremiumPaymentTerms /  SettlementDueDate /  SettlementDetails","Instalment_Due_Date"],
		["commissiontype","PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder","Commission_Type"],
		["commissionamount","Commission /  PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder /  OtherDeductionsFromPremium","Commission_Amount"],
		["commissionpercentage","Commission /  PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder /  OtherDeductionsFromPremium","Commission_%"],
		["commissionbasis","Commission /  PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder /  OtherDeductionsFromPremium","Commission_Basis"],
		["commissionclass","OtherDeductionsFromPremium /  PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder","Commission_Class"],
		["commissionamountcurrency","Commission /  PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder /  OtherDeductionsFromPremium","Captured with Commission_Amount"],
		["commissionpayableto","OtherDeductionsFromPremium /  PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder","Commission_Payable_To"],
		["brokerageamount","RetailBrokerage /  WholesaleBrokerage /  TotalBrokerage","Brokerage_Amount"],
		["brokerageamountcurrency","RetailBrokerage /  WholesaleBrokerage /  TotalBrokerage","Captured with Brokerage_Amount"],
		["brokeragepercentage","RetailBrokerage /  WholesaleBrokerage /  TotalBrokerage","Brokerage_%"],
		["brokeragebasis","RetailBrokerage /  WholesaleBrokerage /  TotalBrokerage","Brokerage_Basis"],
		["brokerageclass","RetailBrokerage /  WholesaleBrokerage /  TotalBrokerage","Brokerage_Class"],
		["otherdeductionsamount","OtherDeductionsFromPremium /  PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder","Deduction_Amount"],
		["otherdeductionsamountcurrency","OtherDeductionsFromPremium /  PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder","Captured with Deduction_Amount"],
		["otherdeductionspercentage","OtherDeductionsFromPremium /  PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder","Deduction_%"],
		["otherdeductionsbasis","OtherDeductionsFromPremium /  PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder","Deduction_Basis"],
		["otherdeductionsclass","OtherDeductionsFromPremium /  PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder","Deduction_Class"],
		["otherdeductionspayableto","OtherDeductionsFromPremium /  PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder","Deduction_Payable_To"],
		["otherdeductionsserviceprovidedto","OtherDeductionsFromPremium /  PremiumDiscountsCommissionsAndOrDeductionsPayableToThePolicyholder","Service_Provided_To"],
		["premiumsplitpercentage","AllocationOfPremiumToCoding /  AllocationOfPremiumToCoding","Risk_Code_% / FIL_Code_%"],
		["premiumsplittype","AllocationOfPremiumToCoding /  AllocationOfPremiumToYearsOfAccount /  RegulatoryRiskLocation","Premium_Split_Type"],
		["naturalcatastrophepremiumamount","","Derived"],
		["riskcode","AllocationOfPremiumToCoding","Risk_Code"],
		["filcode","AllocationOfPremiumToCoding","FIL_Code"],
		["insurableinteresttype","Interest","Insured_Item_Type"],
		["insurableinteresttypesubgroup","Interest","Insurable_Interest_Type_Sub_Group"],
		["insurableinteresttypegroup","Interest","Insurable_Interest_Type_Group"],
		["insurableinterestlocationstreetnoandstreet","Interest","Insured_Item_Number_And_Street"],
		["insurableinterestlocationcity","Interest","Insured_Item_City_Name"],
		["insurableinterestlocationziporpostcode","Interest","Insured_Item_Postal_Code"],
		["insurableinterestlocationcountrysubdivision","Interest","Insured_Item_Country_Subentity",DataRuleTranslator.Subentity],
		["insurableinterestlocationcountry","Interest","Insured_Item_Country",DataRuleTranslator.Country],
		["insurableinterestregistrationlocationcountry","Interest","Insured_Item_Registration_Country",DataRuleTranslator.Country],
		["insurableinterestuniqueidentifier","Interest","Insured_Item_Unique_Identifier"],
		["insurableinterestuniqueidentifiertype","Interest","Insured_Item_Unique_Identifier_Type"],
		["transitorstoragecondition","Interest","Transit_Storage_Condition"],
		["insurableinterestusage","Interest","Insured_Item_Usage"],
		["vesselengineindicator","Interest","Vessel_Engine_Indicator"],
		["vesselmaritimeregiontype","Interest","Vessel_Maritime_Region_Type"],
		["vesselgrosstonnage","Interest","Vessel_Gross_Tonnage"],
		["insurableinterestweight","Interest","Insured_Item_Weight"],
		["insurableinteresttypeadditionalgrain","Interest","Insurable_Interest_Type_Additional_Tax_Grain"],
		["insurableinterestuniqueidentifieragency","Interest","Insurable_Interest _Unique_Identifier_Agency"],
		["insurableinterestweightunit","Interest","Insured_Item_Weight_Unit"],
		["insurableinterestlocationlatitude","Interest","Insurable _Interest_Location_Latitude"],
		["insurableinterestlocationlongitude","Interest","Insurable_Interest_Location_Longitude"],
		["insuredriskfactortype","Interest","Insured_Risk_Factor_Type"],
		["insuredriskfactorvalue","Interest","Insured_Risk_Factor_Value"],
		["insuredriskfactordescription","Interest","Insured_Risk_Factor_Description"],
		["propertyinsuredvalue","Interest","Insured_Item_Coverage_Limit_Amount"],
		["policiesnumberof","Interest","Number_Of_Policies"],
		["insurableinterestnumberof","Interest","No_Of_Insured_Items"],
		["insuredriskfactorcurrency","Interest","Captured with Insured_Risk_Factory_Value"],
		["propertyinsuredvaluecurrency","Interest","Captured with Property_Insured_Value"],
		["otherinterestedpartyrole","ClaimsExperts","Claims_Expert_Role"],
		["otherinterestedpartyname","ClaimsExperts","Claims_Expert_Name"],
		["otherinterestedpartylocationstreetnumberorstreet","ClaimsExperts","Claims_Expert_Number_and_Street"],
		["otherinterestedpartylocationcity","ClaimsExperts","Claims_Expert_City_Name"],
		["otherinterestedpartylocationcountrysubdivision","ClaimsExperts","Claims_Expert_Country_Subentity",DataRuleTranslator.Subentity],
		["otherinterestedpartylocationcountry","ClaimsExperts","Claims_Expert_Country",DataRuleTranslator.Country],
		["otherinterestedpartylocationziporpostcode","ClaimsExperts","Claims_Expert_Postal_Code"],
		["taxrisklocationcountrysubdivision","Situation","Risk_Location_Country_Subentity",DataRuleTranslator.Subentity],
		["taxrisklocationcountry","Situation","Risk_Location_Country",DataRuleTranslator.Country],
		["taxname",TAX_THREE_HEADINGS,"Tax_Type"],
		["taxadministeredby", TAX_THREE_HEADINGS,"Tax_Administered_By"],
		["taxpayableby",TAX_THREE_HEADINGS,"Tax_Payer_Party_Role"],
		["taxpayertaxidentificationnumber",TAX_THREE_HEADINGS,"Taxpayer_Tax_Identification_Number"],
		["taxpayername",TAX_THREE_HEADINGS,"Taxpayer_Name"],
		["taxrate",TAX_THREE_HEADINGS,"Tax_%"],
		["taxbasis",TAX_THREE_HEADINGS,"Tax_Rate_Basis"],
		["taxratebasisamount",TAX_THREE_HEADINGS,"Tax_Basis_Amount"],
		["taxcalculationtype",TAX_THREE_HEADINGS,"Tax_Calculation_Type"],
		["taxamount",TAX_THREE_HEADINGS,"Tax_Amount"],
		["taxsettlementcurrency","","Derived"],
		["withholdingtaxreduction","TaxPayableByInsurers","Withholding_Tax_Reduction"],
		["taxapplicationtype","","Derived"],
		["taxfixedamount",TAX_THREE_HEADINGS,"Tax_Fixed_Amount"],
		["taxmultiplier",TAX_THREE_HEADINGS,"Tax_Multiplier"],
		["taxpayertaxidentificationnumbercodeset","ClaimsExperts","Taxpayer_Tax_Identification_Number_Code_Set"],
		["taxratebasisamountcurrency",TAX_THREE_HEADINGS,"Captured with Tax_Rate_Basis"],
		["taxamountcurrency",TAX_THREE_HEADINGS,"Captured with Tax_Amount"],
		["endorsementtype","ContractChanges /  ContractChanges","Insurer_Endorsement _Type / Reinsurer_Endorsement _Type"],
		["endorsementreason","ContractChanges","Endorsement_Reason"],
		["endorsementreference","ContractChanges","Endorsement_Reference"],
		["endorsementeffectivedate","EndorsementEffectiveDate","Endorsement_Effective_Date"],
		["endorsementexpirydate","EndorsementEffectiveDate","Endorsement_Expiry_Date"],
		["beneficiaryname","LossPayee","Loss_Payee_Name"],
		["beneficiarylocationcountry","LossPayee","Loss_Payee_Country",DataRuleTranslator.Country],
		["beneficiarylocationstreetnoandstreet","LossPayee","Loss_Payee_Street_Number_And_Street"],
		["beneficiarylocationcity","LossPayee","Loss_Payee_City_Name"],
		["beneficiarylocationcountrysubdivision","LossPayee","Loss_Payee_Country_Subentity",DataRuleTranslator.Subentity],
		["beneficiarylocationziporpostcode","LossPayee","Loss_Payee_Postal_Code"],
		["choiceoflaw","ChoiceOfLawAndJurisdiction","Choice_Of_Law"],
		["choiceofjurisdiction","ChoiceOfLawAndJurisdiction","Jursidiction_Location_Country",DataRuleTranslator.Country],
		["regulatoryrisklocationterritory","Situation / RegulatoryRiskLocation","Risk_Location_Country / Regulatory_Risk_Location_Country",DataRuleTranslator.Country],
		["regulatoryrisklocationterritorysubdivision","Situation","Risk_Location_Country_Subentity",DataRuleTranslator.Subentity],
		["taxandregulatorycoverages","LimitOfLiability","Coverage"],
		["taxandregulatoryperilsincluded","ContractType /  Interest /  Conditions /  OriginalConditions /  LimitOfLiability","Perils_Included"],
		["taxandregulatoryperilsexcluded","ContractType /  Interest /  Conditions /  OriginalConditions /  LimitOfLiability","Perils_Excluded"],
		["triaacceptordeclinedate","Premium","TRIA_Decision_Date"],
		["distributionchannel","IsTheBusinessSubjectToDistanceMarketingDirective","Distribution_Channel"],
		["usclassification","USClassification","US_Classification"],
		["homestate","StateOfFiling","Insured_Filing_Country_Subentity",DataRuleTranslator.Subentity],
		["regionalregulatoryclasscodeset","RegulatoryClientClassification","Regional_Regulatory_Class_Code_Set"],
		["regionalregulatoryclass","RegulatoryClientClassification","Regional_Regulatory_Class"],
		["poolscheme","Premium /  PremiumPaymentTerms","Pool_Scheme"],
		["claimsbasis","Period / ClaimsBasis","Claims_Basis"],
		["solvencyiilineofbusiness","","Derived"],
		["highproductriskproductindicator","PlacingData","High_Product_Risk_Indicator"],
		["solvencyiilineofbusinessshortcode","","Derived"],
		["lloydsplatform","PlacingData","Lloyd's_Platform"],
		["subjectivityindicator","Endpoint","Subjectivity_Indicator"],
		["subjectivitydate","Subjectivities","Subjectivity_Due_Date"]
	];

	mapField( fieldName : string ) : DDFieldMap {
		const tidiedName = utils.aggressiveTidy( fieldName );
		const lup = this.fieldLookup.filter( x => { return x[0] == tidiedName })[0];
		if( !lup ) {
			if( this.throwError ) {
				throw new Error( `DataRuleFieldMapper does not recognise ${fieldName} = ${tidiedName}`);
			} else {
				let translation = "none";
				if( fieldName.toLowerCase().endsWith( "country") ) { translation = DataRuleTranslator.Country; }
				if( fieldName.toLowerCase().endsWith( "subentity") ) { translation = DataRuleTranslator.Country; }
				return { mrcHeading: "", tag: fieldName, translation: translation };
			}
		}
		return { mrcHeading: lup[1], tag: lup[2], translation: lup[3] || "none"};
	}
}