import React, { useState, useEffect, ReactElement } from 'react'
import { whitespace, Query } from '../helpers/whitespace'
import { Modal } from 'react-bootstrap'
import { Session } from '../Session'
import { RefreshSubscribers } from '../App'

export const MyDetails = ({ companyId }) : ReactElement => {
    const [ myDetails, setMyDetails ] = useState<any>( null )
    const [ showDialog, setShowDialog ] = useState(false)

    useEffect(() => {
        console.log( `MyDetails useEffect ${companyId}`);
        refreshData();
        RefreshSubscribers.push( { id: "MyDetails", fn: refreshMe } );
    }, [companyId])

    const refreshMe = () => {
        console.log( `MyDetails refreshMe`);
        refreshData();
    }

    const MyDialog = () => {
        return (
          <Modal show={showDialog} onHide={() => setShowDialog(false)}>
            <Modal.Header closeButton>
              <Modal.Title>My Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='DlgSubTitle'>Company Name</div>{myDetails.companyName}
                <div className='DlgSubTitle'>Email</div>{myDetails.username}
              <div className='DlgSubTitle'>{myDetails.teams.length} {myDetails.teams.length === 1 ? 'team' : 'teams'}</div>
              {myDetails.teams.map( (team, idx ) => <div key={idx}>{team.name} ({team.teamId})</div> )}
            </Modal.Body>
          </Modal>
        )
    }

    const refreshData = () => {
        setMyDetails( Session.userDetails );
    }
    
    if( myDetails ) return (
        <>
        <div className='NavTiny'><span className='MyLink' onClick={() => setShowDialog(true)}>{myDetails.companyName}</span><br/>
        {myDetails.teams.length} {myDetails.teams.length === 1 ? 'team' : 'teams'}</div>
        <MyDialog />
        </>
    )
    else return (
        <div></div>
    )
}