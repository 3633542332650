import { PageLoading, NoResultsFound } from '../../components/Loading'
import React, { useState, useEffect } from 'react'
import { RiskGrid } from '../../components/RiskGrid';
import { whitespace, Query, WhitespaceAPI } from '../../helpers/whitespace';
import { riskFilter } from '../../helpers/riskFilter';
import { DefinedData, DDExport, DDDataPoint } from '../../FromElsewhere/DefinedData';
import DataTable from 'react-data-table-component'
import { Modal, Col, Row, Button, Tab, Tabs, Form, Container } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications'
import { Session } from "../../Session";
import { MemberPopup } from '../../components/MemberPopup';
import './MRCChecker.css'
import { MRCV3ByHeading, MRCV3Test, MRCV3TestOutcome } from './MRCV3Test';
import { MRCV3Rules } from './MRCV3Rules';
import { CDRConcept, ConceptFactory } from '../../CaveatsReusable/Concepts';
import { SummaryForChecker, SummaryHardCoded } from '../../CaveatsReusable/Summary';
import { DataRuleHardcoded, DataRuleSet } from '../../CaveatsReusable/DataRules';
import { DataRuleTranslator } from '../../CaveatsReusable/DataRuleTranslator';
import { DataRuleEvaluator } from '../../CaveatsReusable/DataRuleEvaluator';

interface CDRConceptPair {
	concept: CDRConcept;
	summary: SummaryForChecker;
}

export const MRCChecker = ({ refresh }) => {
	const [loaded, setLoaded] = useState(false)
	const [riskID,setRiskID] = useState("")
    const [risks, setRisks] = useState<any[]>([])
    const [selectedRisk, setSelectedRisk] = useState<any>( null );
	const [definedData, setDefinedData] = useState<any>( null );
	const [activeTabKey, setActiveTabKey] = useState("chooseRisk");
	const [tests,setTests] = useState<MRCV3Test[]>( [] );
	const [headingSummaries,setHeadingSummaries] = useState<MRCV3ByHeading[]>( [] );
	const [failsOnly,setFailsOnly] = useState(false);
	const [selectedHeading,setSelectedHeading] = useState("");

	const [myTranslator,setMyTranslator] = useState( new DataRuleTranslator() );
	const [mrcRules,setMRCRules] = useState<DataRuleSet[]>( [] );
	const [cdrRules,setCDRRules] = useState<DataRuleSet[]>( [] );
	const [CDRConcepts, setCDRConcepts] = useState<CDRConceptPair[]>( [] );
	const [mrcSummary,setMRCSummary] = useState<any>({});
	
	useEffect(() => {
		let url = "/api/documents/RWISO3166";
		WhitespaceAPI.get( url ).then( response => {
			myTranslator.setISO3166( response.data );
			console.log( `Loaded ${url}` );

			url = "/api/documents/RWCDRRules";
			WhitespaceAPI.get( url ).then( response => {
				setCDRRules( response.data.caveats );
				console.log( `Loaded ${response.data.caveats.length} rules from ${url}`);
	
				url = "/api/documents/RWMRCRules";
				WhitespaceAPI.get( url ).then( response => {
					setMRCRules( response.data.caveats );
					console.log( `Loaded ${response.data.caveats.length} rules from ${url}`);

					url = "/api/summary";
					WhitespaceAPI.get( url ).then( response => {
						setRisks( riskFilter.groupRisksSummary( response.data ))
						setLoaded(true);
					});
				});
			});
		});
    }, [refresh])

	const pickRisk = ( risk ) => {
		setRiskID( risk.id );
        whitespace(Query.GET, `/api/risks/${risk.id}/getExtendedMRC`).then( response => {
            setSelectedRisk( response.data );
			const mrcHeadings = response.data.MRCContract.lineItems.map( li => { return li.mrcHeading });
			console.log( `mrcHeadings: ${mrcHeadings.length} from ${mrcHeadings[0]} to ${mrcHeadings[mrcHeadings.length-1]}`);
			whitespace(Query.GET, `/api/v22.04/data/${risk.id}`).then( response => {
				setDefinedData( response.data );

				const which : string = "CDR";	// ""MRC" "Headings"
				if( which == "MRC" ) {
					runMRCTests( response.data );
					setActiveTabKey( "reviewForMRC");
				}
				if( which == "CDR" ) {
					runCDRTests( response.data );
					setActiveTabKey( "reviewForCDR");
				}
				if( which == "Headings" ) {
					runTests( response.data, mrcHeadings );
					setActiveTabKey( "reviewByHeading");
				}				
			})
        })
    }

	const toggleFailsOnly = () => {
		setFailsOnly( !failsOnly );
	}

	const doRefresh = () => {
		console.log( `doRefresh ${riskID}`);
		pickRisk( {id:riskID} );		
	}

	// MRC Tests //////////////////////////////////////////////////////////////////////////

	const runMRCTests = ( ddExport : DDExport ) => {
		ConceptFactory.verbose = true;
		console.log( JSON.stringify( ddExport ));
		const checker = ConceptFactory.makeChecker( ConceptFactory.MRCv3, myTranslator );
		const summary = checker.check( ddExport, mrcRules );
		
		console.log( `runMRCTests on ${ddExport.definedData.length} data points gets ${summary.failCount()} fails(s). ${ddExport.metadata?.documentID} ${ddExport.metadata?.fetchedAt}`);
		setMRCSummary( summary );
	}

	// CDR Tests //////////////////////////////////////////////////////////////////////////

	const runCDRTests = ( ddExport : DDExport ) => {
		
		const allConcepts = ConceptFactory.makeAllCDRConcepts();
		const allPairs : CDRConceptPair[] = [];
		allConcepts.forEach( c => {
			const checker = ConceptFactory.makeChecker( c.id.toString(), myTranslator );
			const summary = checker.check( ddExport, cdrRules );
			if( summary.narrative != SummaryHardCoded.NYI ) {
				console.log( "0409 summary:")
				console.log( JSON.stringify( summary ) );	
			}
			const pair : CDRConceptPair = { concept : c, summary : summary };
			allPairs.push( pair );
		});
		setCDRConcepts( allPairs );	
	}
	// MRC Tests //////////////////////////////////////////////////////////////////////////


	const runTests = ( ddExport : DDExport, mrcHeadings : string[] ) => {
		const ruleset = new MRCV3Rules();
		const insurance_type = DefinedData.findFirst( ddExport.definedData, { tag: "Insurance_Type"});
		const isReinsurance = (insurance_type?.value || "-" ) == "Reinsurance";
		const myTests : MRCV3Test[] = ruleset.build( isReinsurance );
		const summaries : MRCV3ByHeading[] = [];

		myTests.forEach( t => {
			t.check( ddExport, mrcHeadings );
			let h = summaries.filter( h => { return h.mrcHeading == t.mrcHeading } )[0];
			if( !h ) {
				h = new MRCV3ByHeading( t.mrcHeading );
				summaries.push( h );
			}
			h.add( t );
		});
		setTests( myTests );
		setHeadingSummaries( summaries );
	}

	const ddValue = ( tag ) => {
		if( definedData && definedData.definedData && definedData.definedData.length > 0 ) {
			const dp : DDDataPoint | null = DefinedData.findFirst( definedData.definedData, { tag: tag } );
			return dp?.value || "-";
		}
		return "-";
	}

	const decideTestRowClassName = ( test : MRCV3Test ) : string => {
		return `TestGridRow ${test.outcome}Colour`;
	}

	const decideSpanClassName = ( test : MRCV3Test ) : string => {
		return `SummaryTestSpan ${test.outcome}Colour`;
	}

	const decideSummaryTitleClassName = ( summary : MRCV3ByHeading ) : string => {
		let myColour = "";
		const outcomesInOrder = [
			MRCV3TestOutcome.Fail, MRCV3TestOutcome.OptionalFail, MRCV3TestOutcome.NotApplicable, MRCV3TestOutcome.Pass
		];
		outcomesInOrder.forEach( o => {
			if( !myColour ) {
				if( summary.tests.filter( t => { return t.outcome == o } ).length > 0 ) {
					myColour = `${o}Colour`;
				}
			}
		});
		return `col-md-6 ${myColour}`;
	}

	const TestRow = ( { test } ) => {
		const t : MRCV3Test = test;
		return ( 
			<Row className={decideTestRowClassName(t)}> 
				<Col className='col-md-2'>#{t.rowNo} {t.getReq()} {t.mrcHeading}</Col>
				<Col className='col-md-3'>{t.tag.replaceAll(",",", ")}</Col>
				<Col className='col-md-5'>{t.value}</Col>
				<Col className='col-md-2'>{t.comment}</Col>
			</Row>
		)
	}

	const pickHeading = ( heading ) => {
		setSelectedHeading( heading );
		setActiveTabKey( "reviewByRule");
	}

	const SummaryRow = ( { summary } ) => {
		const s : MRCV3ByHeading = summary;
		return (
			<Row className='SummaryRow'>
				<Col className={decideSummaryTitleClassName(s)}><span className='MyLink' onClick={() => pickHeading(s.mrcHeading)}>{s.mrcHeading}</span></Col>
				<Col className='col-md-1'>{s.tests.length} test{s.tests.length == 1 ? "" : "s"}</Col>
				<Col className='col-md-5'>
					{s.tests.map( (t, idx ) => {
						return( <span key={idx} className={decideSpanClassName(t)}>#{t.rowNo}</span> )
					})}
				</Col>
			</Row>
		)
	}

	const onSelectHeading = (event) => {
		const value = event.target.value;
		setSelectedHeading( value );
    }

	const CDRBlob = ( { id } ) => {
		const pair = CDRConcepts.find( c => { return c.concept.id == id } );
		if( pair ) {
			const concept = pair.concept;
			const summary = pair.summary;
			const classNames = `CDRBlob CDRBlob${concept.status.replaceAll(' ','')}`;
			return (
				<div className={classNames}>
					<h3>{concept.id} {concept.name}</h3>
					<div>{concept.status}</div>
					<div>{summary.narrative}</div>
					{summary.getFails().map( (r,idx) => {
						return (
							<div key={idx}>Failed {r.ruleID}</div>
						)
					})}
				</div>
			)	
		} else {
			return <></>
		}
	}

	const MRCRuleRow = ( { r, key } ) => {
		let rowClass = "PassColour";
		if( r.result == "FAIL" ) {
			if( r.severity == DataRuleHardcoded.OPTIONAL ) {
				rowClass = "OptionalFailColour";
			} else {
				rowClass = "FailColour";
			}
		}
		
		`${r.result}Colour`;

		return (
			<>
			<Row className={rowClass} key={key}>
				<Col className='col-md-2'>{r.ruleID}</Col>
				<Col className='col-md-2'>{r.result}</Col>
				<Col className='col-md-8'>{r.fieldName}</Col>
			</Row>
			{r.failMessages && r.failMessages.map( ( m, i) => {
				return <Row className={rowClass} key={i}><Col className='col-md-12'>{m}</Col></Row>
			})}
			</>
		)
	}

	return (
        <Tabs activeKey={activeTabKey} onSelect={(event) => setActiveTabKey(event || "chooseRisk")}>
            <Tab eventKey="chooseRisk" title="Choose Risk" className='BorderedTab'>
				{!loaded && <PageLoading />}
				{loaded && !risks.length && <NoResultsFound />}
				<div className={`Fadein-wrapper ${risks.length && 'load'}`}>
					<RiskGrid input={risks} pickFn={pickRisk} />
				</div>
			</Tab>
            <Tab eventKey="reviewByHeading" title="Review by Heading" className='BorderedTab'>
				<div>
					{tests.length} Tests across {headingSummaries.length} headings, <span className='FailHighlight'>{tests.filter((t) => { return t.outcome == MRCV3TestOutcome.Fail }).length} failures</span> for {selectedRisk?.control?.umr || ""} / {selectedRisk?.control?.insuredName || "-" } / {selectedRisk?.platformReferences?.RiskID || "-" }
					<span> : </span><span className='MyLink' onClick={doRefresh}>Refresh</span>
				</div>
				<Container className='Above12'>
				<Row className='HeaderRow'> 
				<Col className='col-md-6'>MRC Heading</Col>
				<Col className='col-md-1'>Tests</Col>
				<Col className='col-md-5'>Results</Col>
				</Row>
				{headingSummaries.map( (s, idx) => { 
					return( <SummaryRow key={idx} summary={s}></SummaryRow> )
				})}
				</Container>
			</Tab>
            <Tab eventKey="reviewByRule" title="Review by Rule" className='BorderedTab'>
				<div>
					{tests.length} Tests, <span className='FailHighlight'>{tests.filter((t) => { return t.outcome == MRCV3TestOutcome.Fail }).length} failures</span> for {selectedRisk?.control?.umr || ""} / {selectedRisk?.control?.insuredName || "-" } / {selectedRisk?.platformReferences?.RiskID || "-" }
					<span> : </span><span className='MyLink' onClick={toggleFailsOnly}>{failsOnly ? "Only Failures" : "All Tests"}</span> : <span className='MyLink' onClick={doRefresh}>Refresh</span>
				</div>
				<div>
					<Form.Control
						as="select"
						className="my-1"
						onChange={onSelectHeading}
						name="selectedHeading"
						value={selectedHeading}
						custom>
						<option value='' key="select">Select ...</option>
						{headingSummaries.map((s, idx) => <option value={s.mrcHeading} key={idx}>{s.mrcHeading}</option>)}
					</Form.Control>	
				</div>
				<Container className='Above12'>
				<Row className='HeaderRow'> 
				<Col className='col-md-2'>MRC Heading</Col>
				<Col className='col-md-3'>Tag</Col>
				<Col className='col-md-5'>Value</Col>
				<Col className='col-md-2'>Comment</Col>
				</Row>
				{tests.filter( t => { return selectedHeading == "" || selectedHeading == t.mrcHeading} ).filter( t => { return( !failsOnly || t.outcome == MRCV3TestOutcome.Fail ) }).map( (t, idx) => { 
					return( <TestRow key={idx} test={t}></TestRow> )
				})}
				</Container>
            </Tab>
			<Tab eventKey='reviewForMRC' title="MRC" className='BorderedTab'>
				
				{mrcSummary && mrcSummary.rules && 
					<>
					<div>Result <b>{mrcSummary.narrative}</b> checked against {mrcRules.length} MRC Rules : <span className='MyLink' onClick={doRefresh}>Refresh</span>
					&nbsp;: <span className='MyLink' onClick={toggleFailsOnly}>{failsOnly ? "Only Failures" : "All Tests"}</span></div>
					<div>{selectedRisk?.control?.umr || ""} / {selectedRisk?.control?.insuredName || "-" } / {selectedRisk?.platformReferences?.RiskID || "-" }</div>
						<Container className='Above12'>
						<Row className='HeaderRow'> 
						<Col className='col-md-2'>Rule ID</Col>
						<Col className='col-md-2'>Result</Col>
						<Col className='col-md-8'>Details</Col>
						</Row>
						{mrcSummary.rules.filter( r => { return !failsOnly || r.result == "FAIL" } ).map( (r,idx) => 
							<MRCRuleRow r={r} key={idx}></MRCRuleRow>
						)}
					</Container>
					<div className='Above12'><pre className='JSONResult'>{JSON.stringify(mrcSummary,null,4)}</pre></div>
					</>
				}
			</Tab>
			<Tab eventKey='reviewForCDR' title="CDR" className='BorderedTab'>
				<div>{selectedRisk?.control?.umr || ""} / {selectedRisk?.control?.insuredName || "-" } / {selectedRisk?.platformReferences?.RiskID || "-" }</div>
				<Container className='Above12'>
					<Row>
						<Col className='col-md-3'>
							<CDRBlob id={1}/>
							<CDRBlob id={2}/>
							<CDRBlob id={3}/>
							<CDRBlob id={4}/>
							<CDRBlob id={5}/>
							<CDRBlob id={6}/>
							<CDRBlob id={7}/>
						</Col>
						<Col className='col-md-3'>
							<CDRBlob id={8}/>
							<CDRBlob id={9}/>
							<CDRBlob id={10}/>
							<CDRBlob id={11}/>
							<CDRBlob id={12}/>
							<CDRBlob id={13}/>
							<CDRBlob id={14}/>
						</Col>
						<Col className='col-md-3'>
							<CDRBlob id={15}/>
							<CDRBlob id={16}/>
							<CDRBlob id={17}/>
							<CDRBlob id={18}/>
							<CDRBlob id={19}/>
							<CDRBlob id={20}/>
							<CDRBlob id={21}/>
						</Col>
						<Col className='col-md-3'>
							<CDRBlob id={22}/>
							<CDRBlob id={23}/>
							<CDRBlob id={24}/>
							<CDRBlob id={25}/>
							<CDRBlob id={26}/>
						</Col>
					</Row>
				</Container>
			</Tab>
        </Tabs>
    )
}



