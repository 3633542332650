import React, { useState, useEffect } from 'react'
import { whitespace, Query } from '../../helpers/whitespace'
import { Modal, Table, Row, Col, Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { PageLoading, NoResultsFound } from '../../components/Loading'
import { getMRCItemText, interpolatePlaceholders } from '../../helpers/mrc'
import { riskFilter } from '../../helpers/riskFilter'
import { RiskGrid } from '../../components/RiskGrid'
import { CLIENT } from '../../constants/client'
import { buildEOC } from './eocBuilding'
import { utils } from '../../FromElsewhere/utils'
import { NotificationManager } from 'react-notifications'
import dayjs from 'dayjs'
import axios from 'axios'
import relativeTime from 'dayjs/plugin/relativeTime'
import './EOC.css'
import { SummaryFilter } from '../../components/SummaryFilter'
import { Session } from '../../Session'

dayjs.extend(relativeTime)
const wsDatetimeFormat = 'YYYY-MM-DD HH:mm:ss'

const DownloadPDF = ({ title = 'EOC Document', variables, risk }) => {
    const generatePDF = () => {
        buildEOC(risk, variables).then(eocPdf => {
            console.log('eocpdf', eocPdf)
            axios
            .post('https://dev.whitespace.co.uk/pdfmake/', eocPdf, { 
                responseType: 'arraybuffer',
                headers: { 
                    'Accept': '*/*',
                    'Content-type': 'application/json',
                }
            })
            .then(response => {
                const blob = new Blob([response.data], {type: response.headers['content-type']})
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = title
                link.click()
            })
            .catch(function (error) {
                console.error(error)
                return (error.message === 'Network Error') ? 'Network Error' : false
            })
        })
    }

    return (
        <button className="btn btn-outline-danger" onClick={() => generatePDF()}>Download PDF ↓</button>
    )
}

export const EOC = ({ refresh }) => {
    const [loaded, setLoaded] = useState(false)
    const [risks, setRisks] = useState<any[]>([])
    const [eocPopup, setEocPopup] = useState<boolean>(false)
    const [selectedRisk, setSelectedRisk] = useState<any>(false)
    const [selectedAddress, setSelectedAddress] = useState<string>('')
    const [PPT, setPPT] = useState<string>('')
    const [LCWS, setLCWS] = useState<string>('')
    const [UMR, setUMR] = useState<string>('')
    const [documentDate, setDocumentDate] = useState<string>('')
    const [contractType, setContractType] = useState<string>('')
    const [principalAddress, setPrincipalAddress] = useState<string>('')
    const [overseasBroker, setOverseasBroker] = useState<string>('')
    const [period, setPeriod] = useState<string>('')
    const [insured, setInsured] = useState<string>('')
    const [originalInsured, setOriginalInsured] = useState<string>('')
    const [orderHereon, setOrderHereon] = useState<string>('')
    const LEAD_UWR_NOT_SELECTED = 'Lead underwriter not selected';
    const [leadUnderwriter, setLeadUnderwriter] = useState<string>(LEAD_UWR_NOT_SELECTED)
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
    
    const doSummary = ( grail ) => {
        grail = grail.trim();
        NotificationManager.success( grail ? `Fetching for ${grail}` : "Fetching first 60 risks");
        const payload: any = { statuses : [ "Signed","Endorsement","EndorsementPending" ] };
        if( grail ) {
            if( utils.validUMR( grail ) ) {
                payload.umrSearch = grail;
            } else {
                payload.nameSearch = grail;
            }
        }
        setRisks( [] );
        setLoaded( false );
        whitespace(Query.POST, '/api/summary', payload ).then(response => {
            console.log( "POST /api/summary complete");
            setRisks( riskFilter.groupRisksSummary( response.data ));
            setLoaded(true);
        })
    }

    useEffect(() => {
        setRisks([])
        setLoaded(false)
        const payload: any = { statuses : [ "Signed","Endorsement","EndorsementPending" ] };
        whitespace(Query.POST, '/api/summary', payload ).then(response => {
            setRisks( riskFilter.groupRisksSummary( response.data ));
            setLoaded(true);
        })
    }, [refresh])

    const pickRisk = ( risk ) => {
        const signedID = utils.rootIC( risk.id ) + "::SIGNED";
        console.log( `pickRisk /api/risks/${signedID}/getExtendedMRC`)
        whitespace(Query.GET, `/api/risks/${signedID}/getExtendedMRC`).then( response => {
            popupSelectedRisk( response.data )
        })
    }

    const popupSelectedRisk = xmrc => {
      setPrincipalAddress(extractAddress(xmrc))
      setOverseasBroker(extractOverseasBroker(xmrc))
      setSelectedRisk(xmrc)
      setUMR(getMRCItemText(xmrc, 'UMR'))

      const insured1 = getMRCItemText(xmrc, 'Insured')
      const insured2 = getMRCItemText(xmrc, 'Policyholder')
      let insured = insured1.length > insured2.length ? insured1 : insured2;
      if( !insured ) {
        insured = getMRCItemText(xmrc, 'Reinsured')
      }
      setInsured(insured)
      setOriginalInsured(getMRCItemText(xmrc, 'OriginalInsured'))
      setPeriod(getMRCItemText(xmrc, 'Period'))
      setOrderHereon(getMRCItemText(xmrc, 'OrderHereon'))
      setDocumentDate(dayjs().format('D MMMM YYYY'))
      setContractType(getMRCItemText(xmrc, 'ContractType'))
      if(!eocPopup) setEocPopup(true)
    }

    const extractAddress = risk => {
      
      let li = risk.MRCContract.lineItems.filter(lineItem => lineItem.mrcHeading === 'PrincipalAddress')[0];
      if( !li ) {
        li = risk.MRCContract.lineItems.filter(lineItem => lineItem.mrcHeading.toLowerCase().includes('address'))[0];
      }
      if( !li ) {
        return '';
      }
      return interpolatePlaceholders( li, "\n");
      /*
      let address = '';
      try {
        address = risk.MRCContract.lineItems.filter(lineItem => lineItem.mrcHeading === 'PrincipalAddress')[0].elements.map(element => element.text).join(' \n')
        console.log( `extractAddress returning ${address}`);
      } catch(e) {
        try {
          address
        } catch(e) {
          address = risk.MRCContract.lineItems.filter(lineItem => lineItem.mrcHeading.toLowerCase().includes('address'))[0].elements.map(element => element.text).join(' \n')
        }
      }
      return address
      */
    }

    const extractOverseasBroker = risk => {
      const li = risk.MRCContract.lineItems.filter(lineItem => lineItem.mrcHeading === 'OverseasBroker')[0];
      if( !li ) {
        return '';
      }
      return interpolatePlaceholders( li, "\n");
      // let overseasBroker = ''
      // try {
      //   overseasBroker = risk.MRCContract.lineItems.filter(lineItem => lineItem.mrcHeading === 'OverseasBroker')[0].elements.map(element => element.text).join(' \n')
      // } catch {
      //   overseasBroker
      // }
      // return overseasBroker
    }

    const handleSelect = (e) => {
        setLeadUnderwriter(e);
    }

    const renderEOCOptions = () => {
      interface EOCOption{ 
        order: number
        ref: string
        options?: string[]
      }

      return CLIENT.eocOptions.map(option => {
        const switchOption = option as EOCOption | any
        switch (switchOption.ref) {
            case 'leadUnderwriter':
                return (
                    <div className='Option' key={`option-${option.ref}`}>
                        <div>Signed lines will be shown largest first. Use this option to have the lead underwriter first.</div>
                        <DropdownButton id="dropdown-basic" title={leadUnderwriter} onSelect={handleSelect}>
                            <Dropdown.Item key="9999" eventKey="{LEAD_UWR_NOT_SELECTED}">{LEAD_UWR_NOT_SELECTED}</Dropdown.Item>
                        {selectedRisk.signedLineSets.map( (sl,i) => {
                            // console.log( sl );
                            if( sl.contents[0] ) {
                                return (
                                    <Dropdown.Item key={i} eventKey={sl.contents[0].businessUnit}>{sl.contents[0].businessUnit}</Dropdown.Item>
                                )
                            }
                        })}
                        </DropdownButton>
                    </div>
                )
                break
          case 'freeAddress':
            return (
              <div className='Option' key={`option-${option.ref}`}>
                <div className='Option-title'>Evidence of Cover document will display the following address:</div>
                <textarea 
                  rows={5} 
                  onChange={(event) => setSelectedAddress(event.currentTarget.value)} 
                  className="Modify-textarea"
                  value={selectedAddress}>
                </textarea>
              </div>
            )
            break
          case 'addressChoice':
            return (
              <div className='Option' key={`option-${option.ref}`}>
                <div className='Option-title'>Use the following address</div>
                <Row>
                  <Col 
                    className={`Col-select ${(principalAddress === selectedAddress) && 'selected'}`} 
                    onClick={() => setSelectedAddress(principalAddress)}
                  >
                    <div className='header'>Principal Address</div>
                    <div className='body'>{principalAddress}</div>
                  </Col>
                  <Col 
                    className={`Col-select ${(overseasBroker === selectedAddress) && 'selected'}`} 
                    onClick={() => setSelectedAddress(overseasBroker)}
                  >
                  <div className='header'>Overseas Broker</div>
                  <div className='body'>{overseasBroker}</div>
                  </Col>
                </Row>
              </div>
            )
            break
          case 'premiumPaymentType':
            return (
              <div className='Option' key={`option-${option.ref}`}>
                <div className='Option-title'>Use the following Premium Payment type</div>
                <Row>
                  {switchOption.options.map(option => {
                    return (
                      <Col 
                        key={`col-ppt-${option.label}`}
                        className={`Col-select ${(PPT === option.value) && 'selected'}`} 
                        onClick={() => setPPT(option.value)}
                      >
                        <div className='header'>{option.label}</div>
                        <div className='body'>{option.value.substr(0,100) + '...'}</div>
                      </Col>
                    )
                  })}
                </Row>
              </div>
            )
            break
          case 'lineConditionsWarrantiesSubjectivities':
            return (
              <div className='Option' key={`option-${option.ref}`}>
                <div className='Option-title'>Line Conditions, Warranties and Subjectivities</div>
                <textarea 
                  rows={5} 
                  onChange={(event) => setLCWS(event.currentTarget.value)} 
                  className="Modify-textarea"
                >
                </textarea>
              </div>
            )
            break
            case 'editableUmr':
              return (
                <div className='Option' key={`option-${option.ref}`}>
                  <div className='Option-title'>UMR displayed in EOC</div>
                  <input value={UMR} className='Modify-text' onChange={(e) => setUMR(e.target.value)} />
                </div>
              )
              break
            case 'editableDocumentDate':
              return (
                <div className='Option' key={`option-${option.ref}`}>
                  <div className='Option-title'>Date of Document</div>
                  <input value={documentDate} className='Modify-text' onChange={(e) => setDocumentDate(e.target.value)} />
                </div>
              )
              break
            case 'editableContractType':
              return (
                <div className='Option' key={`option-${option.ref}`}>
                  <div className='Option-title'>Contract Type</div>
                  <input value={contractType} className='Modify-text' onChange={(e) => setContractType(e.target.value)} />
                </div>
              )
              break
            case 'editableInsured':
              return (
                <div className='Option' key={`option-${option.ref}`}>
                  <div className='Option-title'>Insured / Reinsured</div>
                  <textarea 
                    rows={5} 
                    onChange={(e) => setInsured(e.target.value)} 
                    className="Modify-textarea"
                    value={insured}
                  ></textarea>
                </div>
              )
              break

              case 'editableOriginalInsured':
                return (
                  <div className='Option' key={`option-${option.ref}`}>
                    <div className='Option-title'>Original Insured</div>
                    <textarea 
                      rows={5} 
                      onChange={(e) => setOriginalInsured(e.target.value)} 
                      className="Modify-textarea"
                      value={originalInsured}
                    ></textarea>
                  </div>
                )
                break

            case 'editablePeriod':
              return (
                <div className='Option' key={`option-${option.ref}`}>
                  <div className='Option-title'>Period</div>
                  <textarea 
                    rows={5} 
                    onChange={(e) => setPeriod(e.target.value)} 
                    className="Modify-textarea"
                    value={period}
                  ></textarea>
                </div>
              )
              break
            case 'editableOrderHereon':
              return (
                <div className='Option' key={`option-${option.ref}`}>
                  <div className='Option-title'>Order Hereon</div>
                  <textarea 
                    rows={5} 
                    onChange={(e) => setOrderHereon(e.target.value)} 
                    className="Modify-textarea"
                    value={orderHereon}
                  ></textarea>
                </div>
              )
              break
        }
      })
    }

    const EOCpopup = () => {
        let downloadLink
        const variables = {
          address: selectedAddress,
          LCWS: LCWS,
          PPT: PPT,
          UMR: UMR,
          documentDate: documentDate,
          contractType: contractType,
          insured: insured,
          originalInsured: originalInsured,
          period: period,
          orderHereon: orderHereon,
          leadUnderwriter: leadUnderwriter,
          officeAddress: "",
          officePhoneWeb: "",
          officeFooter: ""
        };

        if( CLIENT["variations"] ) { // 8 June 2023 - see mcgill.ts version of client.ts for an example
          CLIENT["variations"].forEach( v => {
            if( v.companyIds.includes( Session.corporate.companyId ) ) {
              variables.officeAddress = v.officeAddress || "";
              variables.officePhoneWeb = v.officePhoneWeb || "";
              variables.officeFooter = v.officeFooter || "";
            }
          });
        }

        try {
          downloadLink = <DownloadPDF title={selectedRisk.control.umr + ' EOC'} variables={variables} risk={selectedRisk} />
        } catch (error) {
          downloadLink = 'Missing information'
        }
        return (
          <Modal size="lg" show={eocPopup} onHide={() => setEocPopup(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Download Evidence of Cover</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {renderEOCOptions()}
            </Modal.Body>
            <Modal.Footer>
              {downloadLink}
            </Modal.Footer>
          </Modal>
        )
    }

    return (
        <>
        <SummaryFilter onSearch={doSummary}></SummaryFilter>
        {!loaded && <PageLoading/>}
        {loaded && !risks.length && <div className="page-notice"><NoResultsFound />No Signed Risks</div>}
        <div className={`Fadein-wrapper ${risks.length && 'load'}`}>
            {selectedRisk && EOCpopup()}
            <RiskGrid input={risks} pickFn={pickRisk} singleLine={true} />
        </div>
        </>
    )
}