import React, { useState, useEffect } from 'react'
import { Modal, Button, Tab, Tabs, Form, Container, Row, Col } from 'react-bootstrap'
import { RiskGrid } from "../../components/RiskGrid"
import { SummaryFilter } from "../../components/SummaryFilter"
import { NotificationManager } from 'react-notifications'
import { utils } from "../../FromElsewhere/utils"
import { WhitespaceAPI } from "../../helpers/whitespace"
import { riskFilter } from "../../helpers/riskFilter"
import { JMRC } from "../../rebrowse/jmrc";
import { MSStorer } from '../../rebrowse/multi.section.storer'
import { CombinedSets } from '../../helpers/CombinedSets'
import { SIHardCoded, SDDataModel } from './SDDataModel'

import { Market } from '../../helpers/Market'
import { SectInst } from './SectInst'
import './ShowDecs.css'
import { LineSetViewer } from './LineSetViewer'
import { isJSDocNullableType } from 'typescript'

let market : Market;	// = Market.makeUsingAPI( WhitespaceAPI );
const dataModel = new SDDataModel( console.log );

export const ShowDecs = () => {
	const MyStage = {
		PICKDECLARATION: "PICKDECLARATION",
		PICKFACILITY: "PICKFACILITY",
		PICKSECTIONS: "PICKSECTIONS",
		SHOWTOUWR: "SHOWTOUWR"		
	}
	const mockSectionDoc = () : MSStorer.Root => {
		const provenance = mockProvenance();
		const section = mockSection()
		const result: MSStorer.Root = { _id: "", _rev: "", associatedRootID: "", channels: [], createdAt: "", updatedAt: "", type: "", sections: [ section ], provenance:provenance};
		return result;
	}
	const mockProvenance = () => {
		const result : MSStorer.Provenance = {dataHash: "",provHash: "",system: "",userID: "",version: "",writtenAt: ""};
		return result;
	}
	const mockSection = () : MSStorer.Section => {
		return { index: 0, leadUnderwriter: "", leadUnderwriterChannel: "", multiSectionId: SIHardCoded.SECTION0,  multiSectionName: "Single Section" }
	}
	const [dmMessage, setDMessage] = useState("Data Model is empty");
	const [defaultDecGrail, setDefaultDecGrai] = useState( localStorage['ShowDecsDefaultDecGrail'] || "" );	// 1687E
	const [defaultFacGrail, setDefaultFacGrai] = useState( localStorage['ShowDecsDefaultFacGrail'] || "");	// CLYDE
	const [stage, setStage] = useState(MyStage.PICKDECLARATION)
	const [declarations, setDeclarations] = useState<any[]>([]);
	const [declarationMRC, setDeclarationMRC] = useState<JMRC.Root>();
	const [declarationSections, setDeclarationSections] = useState<MSStorer.Root>(mockSectionDoc());
	const [declarationSectID,setDeclarationSectID] = useState( "-1");
	const [facilitySectID,setFacilitySectID] = useState( "-1");

	const [facilities, setFacilities] = useState<any[]>([]);
	const [facilityMRC, setFacilityMRC] = useState<JMRC.Root>();
	const [facilitySections, setFacilitySections] = useState<MSStorer.Root>(mockSectionDoc());
	const [facilityLineSets, setFacilityLineSets] = useState<CombinedSets.Root[]>([]);
	const [showFacilityLineSetsDialog,setShowFacilityLineSetsDialog] = useState(false);

	const [myDataModel, setMyDataModel] = useState<SDDataModel|null>(null);
	
	const feedbackFromDataModel = ( msg ) => {
		setDMessage( msg );
	}

	const backToSafeStage = () => {
		setStage( MyStage.PICKSECTIONS );
	};

	useEffect(() => {
		WhitespaceAPI.get( "/api/shared/corporate").then( response => {
			market = Market.makeUsingCorporates( response.data );
			dataModel.callback = feedbackFromDataModel;
			dataModel.market = market;
			console.log( `market has ${dataModel.market.corporates.length} corporates`);
		})
	}, []);

	const doDeclarationSummary = ( grail ) => {
		localStorage[ "ShowDecsDefaultDecGrail" ] = grail;
        grail = grail.trim();
		const statuses = [ "FirmOrder" ];
        NotificationManager.success( grail ? `Fetching for ${grail}` : "Fetching first 60 risks");
        const payload: any = {statuses : statuses };
        if( grail ) {
            if( utils.validUMR( grail ) ) {
                payload.umrSearch = grail;
            } else {
                payload.nameSearch = grail;
            }
        }
		console.log( payload );
        setDeclarations( [] );
		WhitespaceAPI.post( '/api/summary', payload, { silent: true } ).then( async response => {
			console.log( "POST /api/summary complete");
			const sumRisks = riskFilter.groupRisksSummary( response.data ); // , { status : statuses[0]} );
			setDeclarations( sumRisks );
		});
    }

	const pickDeclaration = ( risk ) => {
		const riskID = risk.id + "::FO";
		const msDocID = risk.id + "::MS";
		dataModel.decID = risk.id;
		WhitespaceAPI.get( `/api/risks/${riskID}`).then( response => {
			if( response.data && response.data.control ) {
				const x : JMRC.Root = response.data;
				dataModel.declarationMRC = x;
				setDeclarationMRC( x );
								
				WhitespaceAPI.get( `/api/documents/${msDocID}`, { silent: true } ).then( response => {
					let sectDoc : MSStorer.Root = mockSectionDoc();
					if( response.data && response.data.sections && response.data.sections.length > 0 ) {
						sectDoc = response.data;
					}
					setDeclarationSections( sectDoc );	
					dataModel.decSections = sectDoc;	
					setStage( MyStage.PICKFACILITY );
				});
			}
		});
    }

	const summariseLineSets = ( s : CombinedSets.Root[] ) : string => {
		return s.length > 0 ? s.length.toString() + utils.makePlural( s.length, ` Line Set`) : "No Line Sets";
	}

	const doFacilitySummary = ( grail ) => {
		localStorage[ "ShowDecsDefaultFacGrail" ] = grail;
        grail = grail.trim();
        NotificationManager.success( grail ? `Fetching for ${grail}` : "Fetching first 60 risks");
        const payload: any = {types : ["Facilities"] };
        if( grail ) {
            if( utils.validUMR( grail ) ) {
                payload.umrSearch = grail;
            } else {
                payload.nameSearch = grail;
            }
        }
		console.log( payload );
        setDeclarations( [] );
		WhitespaceAPI.post('/api/summary', payload, { silent: true } ).then( response => {
			console.log( "POST /api/summary complete");
			const sumRisks = riskFilter.groupRisksSummary( response.data ); // , { status : statuses[0]} );
			setFacilities( sumRisks );
		});
    }

	const pickFacility = ( risk ) => {
		const riskID = risk.id + "::SIGNED";
		const msDocID = risk.id + "::MS";
		dataModel.facID = risk.id;

		WhitespaceAPI.get( `/api/risks/${riskID}`).then( response => {
			if( response.data && response.data.control ) {
				console.log( response.data.control );
				const x : JMRC.Root = response.data;
				setFacilityMRC( x );

				WhitespaceAPI.get( `/api/documents/${msDocID}`, { silent: true }).then( response => {
					let sectDoc : MSStorer.Root = mockSectionDoc();
					if( response.data && response.data.sections && response.data.sections.length > 0 ) {
						sectDoc = response.data;
					}
					setFacilitySections( sectDoc );	
					dataModel.facSections = sectDoc;	
					setFacilitySectID( "-1");

					WhitespaceAPI.get( `/api/lines/${risk.id}/combinedSets`).then( response => {
						const allSets : CombinedSets.Root[] = response.data;
						const signedSets = allSets.filter( ls => { return ls.type == "RWSignedLineSet"} )
						dataModel.facilityLineSets = signedSets;
												
						setFacilityLineSets( signedSets );
						console.log( `setFacilityLineSets with ${signedSets.length} items`);
						gotoStage( MyStage.PICKSECTIONS );
					});
				});
			}
		});
    }

	const FacilityLineSetsDialog = ( { sddm } ) => {
        return (
          <Modal size="lg" show={showFacilityLineSetsDialog} onHide={() => setShowFacilityLineSetsDialog(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Facility Line Sets</Modal.Title>
            </Modal.Header>
            <Modal.Body>
			<div className='SmallText LightGray'>{sddm.describe().join(", ")}</div>
			<div className='SmallText LightGray'>requiresTabs: {sddm.requiresTabs() ? "True" : "False"} {sddm.getTabLabels().map( (lbl,idx) => { return(<span key={idx}>{lbl}</span>) })}</div>
			<LineSetViewer sddm={sddm}/>
            </Modal.Body>
          </Modal>
        )
    }

	const pickedTheSections = () => {	
		console.log( `Picked the sections ${declarationSectID} ${facilitySectID}`);
		if( declarationSectID == "-1" || facilitySectID == "-1" ) { return; }
		dataModel.decSectionID = declarationSectID;
		dataModel.facSectionID = facilitySectID;

		// we might need to re-read the declaration to get an updated revision
		WhitespaceAPI.get( `/api/risks/${dataModel.decID}::FO`).then( response => {
			if( response.data && response.data.control ) {
				const x : JMRC.Root = response.data;
				dataModel.declarationMRC = x;

				WhitespaceAPI.get( `/api/risks/${dataModel.decID}/related/RWFacilityGuidance`).then( response => {
					dataModel.facilityGuidance = response.data;
					console.log( `RWFacilityGuidance returned ${dataModel.facilityGuidance.length} items`);
					setMyDataModel( dataModel );
					setStage( MyStage.SHOWTOUWR );
				});		
			}
		});				
	}

	const gotoStage = ( nextStage ) => {
		if( nextStage == MyStage.PICKSECTIONS ) {
			setDeclarationSectID( (declarationSections?.sections.length || 0 ) > 1 ? "-1" : SIHardCoded.SECTION0 );
			setFacilitySectID( (facilitySections?.sections.length || 0 ) > 1 ? "-1" : SIHardCoded.SECTION0 );
		}
		setStage( nextStage );
	}

	// enabled for AJG on PRODUCTION, and anyone on oyther clusters
	const isEnabledPickDeclaration = () => {
		let result = false;
		if( !localStorage['cluster'].startsWith("PROD") ) {
			result = true;
		}
		if( process.env.REACT_APP_CLIENT_NAME == "ajg" ) {
			result = true;
		}
		console.log( `isEnabledPickDeclaration ${result} for ${localStorage['cluster']} / ${process.env.REACT_APP_CLIENT_NAME}`);
		return result;
	}
	
	return (
		<>
		{stage == MyStage.PICKDECLARATION && !isEnabledPickDeclaration() && 
			<>
			<h2>Disabled</h2>
			<div>
			This screen is disabled for the {localStorage['cluster']} cluster.
			</div>
			</>
		}
		{stage == MyStage.PICKDECLARATION && isEnabledPickDeclaration() && 
			<>
			<h2>Step 1 : Choose the declaration</h2>
			<div>
			<SummaryFilter onSearch={doDeclarationSummary} grailDefault={defaultDecGrail} ></SummaryFilter>
			<RiskGrid input={declarations} pickFn={pickDeclaration} singleLine={true} />
			</div>
			</>
		}
		{stage == MyStage.PICKFACILITY &&
			<>
			<h2>Step 2 : Choose the facility</h2>
			<div><b>Declaration</b> {declarationMRC?.control.insuredName} : {declarationMRC?.control.umr} : 
				&nbsp;{declarationSections?.sections.length || "No"} Section(s) :
				&nbsp;<span className='LightGray'>{declarationMRC?._id}</span></div>
			<div><Button onClick={() => gotoStage(MyStage.PICKDECLARATION)}>Back</Button></div>
			<SummaryFilter onSearch={doFacilitySummary} grailDefault={defaultFacGrail} ></SummaryFilter>
			<RiskGrid input={facilities} pickFn={pickFacility} singleLine={true} /> 
			</>
		}
		{stage == MyStage.PICKSECTIONS &&
			<>
			<h2>Step 3 : Choose the sections</h2>
			<div><b>Declaration</b> {declarationMRC?.control.insuredName} : {declarationMRC?.control.umr} : 
				&nbsp;{declarationSections?.sections.length || "No"} Section(s) :
				&nbsp;<span className='LightGray'>{declarationMRC?._id}</span></div>
			<div className='Above12'><b>Facility</b> {facilityMRC?.control.insuredName} : {facilityMRC?.control.umr} : 
				&nbsp;{facilitySections?.sections.length || "No"} Section(s) :
				&nbsp;{summariseLineSets(facilityLineSets)} :
				&nbsp;<span className="MyLink" onClick={x => setShowFacilityLineSetsDialog(true)}>Line Sets</span> :
				&nbsp;<span className='LightGray'>{facilityMRC?._id}</span></div>

			<div className='Above12'>
				<Button onClick={() => setStage(MyStage.PICKFACILITY)}>Back</Button>
				<span className={declarationSectID == '-1' || facilitySectID == '-1' ? 'HideMe' : ''}>
				&nbsp;<Button onClick={pickedTheSections}>Next</Button></span>
			</div>
			<Container>
				<Row className='Above12'>
					<Col xs={6}><h3>Declaration</h3></Col>
					<Col xs={6}><h3>Facility</h3></Col>
				</Row>
				<Row>
					<Col xs={6}>
						{declarationSections.sections.map( (s, idx) => { 
							return ( <div className='SectPicker' onClick={() => { setDeclarationSectID(s.multiSectionId) }} key={idx}>
								{s.multiSectionId} : {s.multiSectionName}
								&nbsp;<span className={s.multiSectionId == declarationSectID ? "ShowMe" : "HideMe"}>SELECTED</span>
							</div>)} )
						}
					</Col>
					<Col xs={6}>
						{facilitySections.sections.map( (s, idx) => { 
							return ( <div className='SectPicker' onClick={() => { setFacilitySectID(s.multiSectionId) }} key={idx}>
								{s.multiSectionId} : {s.multiSectionName} 
								&nbsp;<span className={s.multiSectionId == facilitySectID ? "ShowMe" : "HideMe"}>SELECTED</span>
							</div>)} )
						}
					</Col>
				</Row>
			</Container>
			</>
		}
		{stage == MyStage.SHOWTOUWR && myDataModel != null &&
			<>
			<h2>Step 4 : Set values and show to carrier</h2>
			<div><b>Declaration</b> {declarationMRC?.control.insuredName} : {declarationMRC?.control.umr} : 
				&nbsp;{declarationSections?.sections.length || "No"} Section(s) : 
				&nbsp;<span className='LightGray'>{declarationMRC?._id}</span></div>
			<div className='Above12'><b>Facility</b> {facilityMRC?.control.insuredName} : {facilityMRC?.control.umr} : 
				&nbsp;{facilitySections?.sections.length || "No"} Section(s) : 
				&nbsp;{summariseLineSets(facilityLineSets)} :
				&nbsp;<span className="MyLink" onClick={x => setShowFacilityLineSetsDialog(true)}>Line Sets</span> :
				&nbsp;<span className='LightGray'>{facilityMRC?._id}</span></div>
			<div className='Above12'>
				<Button onClick={() => gotoStage(MyStage.PICKSECTIONS)}>Back</Button>
				&nbsp;<span className='LightGray'>{dmMessage}</span>
			</div>
			<SectInst sddm={myDataModel} decSectID={declarationSectID} facSectID={facilitySectID} goBackAfterShowing={backToSafeStage} />
			</>
		}
		{ myDataModel != null &&
			<FacilityLineSetsDialog sddm={myDataModel}/>	
		}
		</>
	)
}