import React, { useEffect, useState } from "react"
import { NotificationManager } from 'react-notifications'
import { WhitespaceAPI } from "../../helpers/whitespace"
import { Session } from "../../Session"
import { Button } from "react-bootstrap"
import { utils} from "../../FromElsewhere/utils";

/*
Team and User Summary
TeamId	Team Name	Name	Email	Current State	Second Approval Reviewer	Second Approval Self-Approver	Template Manager
/NodeScripts/Miscellaneous/CorpInfo.js

Approved Carrier List
CompanyId Name
*/


export const VariousLists = ({ refresh }) => {
	const [marketData, setMarketData] = useState<any[]>([]);

	useEffect(() => {
		WhitespaceAPI.get( "/api/shared/corporate").then( response => {
			setMarketData(response.data);
		});
	}, [refresh]);

	const getCompanyName = ( companyId ) => {
		const corp = marketData.find( c => { return c.companyId == companyId });
		return corp?.name || `No company ${companyId}`;
	}


	const doApprovedCarrierList = () => {
		const url = `/api/documents/ACL::${Session.corporate.companyId}`;
		WhitespaceAPI.get( url ).then( response => {
			if( !response.data || !response.data.carriers ) {
				NotificationManager.error( `Bad response from ${url}`); 
				return;
			} else {
				const carriers = response.data.carriers;
				console.log( `${carriers.length} carriers`);
				if( carriers.length ) {
					console.log( JSON.stringify( carriers[0]));
				}
				const corps = response.data.carriers.filter( x => { return x.active && !x.companyID.startsWith("OFFLINE")} );
				const offline = response.data.carriers.filter( x => { return x.active && x.companyID.startsWith("OFFLINE")} );

				if( corps.length == 0 && offline.length == 0 ) {
					return;
				}
				const lines : string[] = [];
				
				lines.push( `${corps.length} Carriers`);
				lines.push( "" );
				corps.forEach( c => {
					const name = getCompanyName( c.companyID );
					lines.push( `${c.companyID},"${name}"`);
	
				});
				lines.push( "" );
				lines.push( `${offline.length} Offline Carriers`);
				lines.push( "" );
				offline.forEach( c => {
					const name = getCompanyName( c.companyID );
					lines.push( `${c.companyID},"${name}"`);
				});
				const filename = "ApprovedCarrierList.csv";
				downloadTextFile( filename, lines );
				NotificationManager.success( `${corps.length} approved, plus ${offline.length} offline, downloaded to ${filename}`); 
			}
		});
	}

	const doTeamSummary = () => {
		const url = `/api/documents/Corporate-${Session.corporate.companyId}`;
		WhitespaceAPI.get( url ).then( response => { 
			if( !response.data || !response.data.teams ) {
				NotificationManager.error( `Bad response from ${url}`); 
				return;
			}
			const corp = response.data;

			const secondApprovalReviewers = corp.permissions?.secondApprovalReviewers?.teams;
			const secondApprovalSelfApprovers = corp.permissions?.secondApprovalSelfApprovers?.teams;
			const templateManagers = corp.permissions?.templateManagers?.teams;

			corp.members.sort( (x,y) => { return x.name.localeCompare( y.name ); });

			const filename = "TeamAndUserSummary.csv";
			const msg = `${corp.teams.length} teams, ${corp.members.length} members, downloaded to ${filename}`;

			const lines : string[] = [];
			lines.push( makeCSV( [ corp.companyId, corp.name, `${corp.teams.length} ${utils.makePlural(corp.teams.length, "Team")}` ]));
			lines.push( "" );
			lines.push( makeCSV( [ "TeamId","Team Name","Name","Email","Current State","Second Approval Reviewer","Second Approval Self-Approver","Template Manager" ] ) );
			corp.teams.forEach( t => {
				lines.push( "" );
				const members = corp.members.filter( m => { return m.teams.includes( t.teamId )});
				lines.push( makeCSV( [ t.teamId, t.name, `${members.length} ${utils.makePlural(members.length,"Member")}` ]));
				members.forEach( m => {
					const parts = [ t.teamId, t.name, m.name, m.email, m.currentState ];
					parts.push( lookupPermission( secondApprovalReviewers, t.teamId, m.uniqueID, "Second Approval Reviewer"));
					parts.push( lookupPermission( secondApprovalSelfApprovers, t.teamId, m.uniqueID, "Second Approval Self-Approver"));
					parts.push( lookupPermission( templateManagers, t.teamId, m.uniqueID, "Template Manager"));
					lines.push( makeCSV( parts ) );
				});				
			});
			downloadTextFile( filename, lines );
			NotificationManager.success( msg );
		});
	}

	const lookupPermission = ( permissions, teamId, muid, ifFound ) => {
		if( !permissions || !permissions[ teamId ] ) 
		{ 
			return "-"; 
		}
		if( permissions[ teamId ].includes( muid ) ) {
			return ifFound;
		}
		return "-"; 
	}

	//////////////////////////////////////////////////////////////////////////////////////////////

	const doStampsList = () => {
		const lines : string[] = [];
		lines.push( makeCSV( [ 
			"Company ID","Company Name","Stamp Type","Bureau Market","Bureau Market Code","Business Unit","uniqueID" 
		]));
		lines.push( "" );
		const corps = marketData.filter( x => { return x.role == "underwriter" && !x.isOffline && !x.isTestOnly } )
			.sort( ( a, b ) => { return a.companyId.localeCompare( b.companyId ); } );
		corps.forEach( c => {
			c.stamps.forEach( s => {
				lines.push( makeCSV( [ c.companyId, c.name, s.stampType, s.bureauMarket, s.bureauMarketCode, s.businessUnit, s.uniqueID ] ) );
			});
		});
		const filename = "StampsList.csv";
		downloadTextFile( filename, lines );
		const msg = `${lines.length} lines written to ${filename}`;
		NotificationManager.success( msg );
	}

	//////////////////////////////////////////////////////////////////////////////////////////////

	const makeCSV = ( parts ) => {
		return parts.map( p => { return utils.prepareForCSV( p ) } ).join( "," );
	}

	const downloadTextFile = ( filename, lines : string[] ) => {
		const content = lines.join( "\n");
		// console.log( content );
		const link = document.createElement('a');
		const file = new Blob( [content], {
			type: "text/plain"
		});
		link.href = URL.createObjectURL( file );
		link.download = filename;
		link.click()
	}

    return (
        <>
			<div className="Above12">
			<h1><Button onClick={doApprovedCarrierList}>Download</Button> Approved Carrier List</h1>
			<div>For a broker, a list of all the currently approved carriers.</div>
			</div>

			<div className="Above12">
			<h1><Button onClick={doTeamSummary}>Download</Button> Team and User Summary</h1>
			<div>Lists each team with its members and their details</div>
			</div>

			<div className="Above12">
			<h1><Button onClick={doStampsList}>Download</Button> Stamps List</h1>
			<div>Lists stamp details for all carriers on the platform</div>
			</div>

        </>
    )
}