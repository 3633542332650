import { SIHardCoded } from "./SDDataModel"

export const StampPicker = ( { imps, onChange } ) => {
	return (
		<select className='FullWidth' onChange={onChange}>
			<option value={SIHardCoded.UNCHOSEN} key={-1}>Which stamp to apply</option>
			<option value={SIHardCoded.LETUWRCHOOSE} key={-2}>Let underwriter choose</option>
			{imps.map( ( x, idx) => { return( <option key={idx} value={x.stamp.uniqueID}>{x.signedLinePercentageString}% {x.stamp.bureauMarket} {x.stamp.bureauMarketCode} {x.stamp.businessUnit}</option> )})} 
		</select>
	)
}