import React, { useState, useEffect } from 'react'
import { PageLoading, NoResultsFound } from '../../components/Loading'
import { PlatformLink } from '../../components/PlatformLink'
import { whitespace, Query } from '../../helpers/whitespace'
import DataTable from 'react-data-table-component'
import { Button } from 'react-bootstrap'
import { Risk } from '../../components/Risk'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { sortUpdatedAt } from '../../helpers/processes'
import { riskFilter } from '../../helpers/riskFilter'
import { SummaryFilter } from '../../components/SummaryFilter'
import { NotificationManager } from 'react-notifications'
import { utils } from '../../FromElsewhere/utils'

dayjs.extend(relativeTime)
const wsDatetimeFormat = 'YYYY-MM-DD HH:mm:ss'

const columns = [
    {
        name: 'UMR',
        selector: 'umr',
        sortable: true,
    },
    // {
    //     name: 'ID',
    //     selector: 'id',
    //     sortable: true,
    // },
    {
        name: 'Insured',
        selector: 'InsuredName',
        sortable: true,
    },
    {
        name: 'Contract Type',
        selector: 'ContractType',
        sortable: true,
        right: true,
    },
    {
        name: 'Last Updated',
        selector: 'updatedAt',
        sortable: true,
        sortFunction: sortUpdatedAt,
        right: true
    },
    {
        name: '',
        selector: 'WSLink',
        right: true
    },
]

/*
const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className='table-filter-wrapper'>
        <input className="table-filter" type="text" placeholder="Filter by Insured" aria-label="Search Input" value={filterText} onChange={onFilter} />
        <Button type="button" className={`clear-filter ${filterText.length ? 'btn-primary' : 'btn-grey'}`} onClick={onClear} disabled={!filterText.length}>Clear</Button>
    </div>
)
*/

export const UnderscoreRisks = () => {
    const [loaded, setLoaded] = useState(false)
    const [risks, setRisks] = useState<any[]>([])
    //const [filterText, setFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
    
    const doSummary = ( grail ) => {
        grail = grail.trim();
        NotificationManager.success( grail ? `Fetching for ${grail}` : "Fetching first 60 risks");
        const payload: any = { statuses : [ "Signed","Endorsement","EndorsementPending" ] };
        if( grail ) {
            if( utils.validUMR( grail ) ) {
                payload.umrSearch = grail;
            } else {
                payload.nameSearch = grail;
            }
        }
        setRisks( [] );
        setLoaded( false );
        whitespace(Query.POST, '/api/summary', payload ).then(response => {
            console.log( "POST /api/summary complete");
            processRisks( riskFilter.groupRisksSummary( response.data ))
        })
    }

    // useEffect(() => { 
    //     doSummary( '' );
    // })

    useEffect(() => {
        const payload: any = { statuses : [ "Signed","Endorsement","EndorsementPending" ] };
        whitespace(Query.POST, '/api/summary', payload ).then(response => {
            console.log( "POST /api/summary complete");
            processRisks( riskFilter.groupRisksSummary( response.data ))
        })
    }, [] )



    // useEffect(() => {
    //     whitespace(Query.GET, '/api/risks?status=Signed').then(risks => {
    //         typeof(risks) !== 'undefined' && processRisks(risks)
    //     })
    // }, [])



    const isUnderscoreRisk = umr => {
        if( umr.startsWith('B0999') ) { return true; }
        if( umr.startsWith('B1740US') ) { return true; }
        return false;
    }

    const processRisks = risks => {
        //const umrs: string[] = []
        const processedRisks = risks.filter(risk => {
            if( isUnderscoreRisk(risk.umr) ) {
                return true
            } else {
                return false
            }
        })
        processedRisks.map(risk => {
            risk.createdAt = dayjs().to(dayjs(risk.createdAt).format(wsDatetimeFormat))
            risk.updatedAtUnix = dayjs(risk.updatedAt).unix()
            risk.updatedAt = dayjs().to(dayjs(risk.updatedAt).format(wsDatetimeFormat))
            risk.id = risk.rootID + "::SIGNED";
            risk.WSLink = <PlatformLink id={risk.id} />
            return risk
        })
        setRisks(processedRisks)
        setLoaded(true)
    }

    // The row data is composed into your custom expandable component via the data prop
    const ExpandedRisk = ({ data }) => {
        const lineItems = [
            { name: 'Insured', mrcHeading: 'Insured' },
            { name: 'Overseas Broker', mrcHeading: 'OverseasBroker' },
            { name: 'Period', mrcHeading: 'Period' },
            { name: 'Premium', mrcHeading: 'Premium' },
        ]
        return (
            <>
            <div className='XSmallText LightGray'>{data.id}</div>
            <Risk lineItems={lineItems} riskId={data.id} />
            </>
        )
    } 

    //const filteredRisks = risks.filter(risk => risk.InsuredName && risk.InsuredName.toLowerCase().includes(filterText.toLowerCase()))

    // const subHeaderComponentMemo = React.useMemo(() => {
    //     const handleClear = () => {
    //         if (filterText) {
    //             setResetPaginationToggle(!resetPaginationToggle)
    //             setFilterText('')
    //         }
    //     }
    //     return <SummaryFilter onSearch={doSummary} />
    // }, [filterText, resetPaginationToggle])

    return (
        <>
        <SummaryFilter onSearch={doSummary}></SummaryFilter>
        {!loaded && <PageLoading />}
        {loaded && !risks.length && <NoResultsFound />}
        <div className={`Fadein-wrapper ${risks.length && 'load'}`}>
            
            <DataTable
                columns={columns}
                data={risks}
                defaultSortField="updatedAtUnix"
                defaultSortAsc={false}
                expandableRows
                expandableRowsComponent={<ExpandedRisk data={risks} />}
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 50, 100, 500]}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                persistTableHead
            />
        </div>
        </>
    )
}