import React, { useState, useEffect } from 'react'
import { Modal, Col, Row, Button, Tab, Tabs, Form, Container } from 'react-bootstrap'
import { utils } from '../../FromElsewhere/utils';
import dayjs, { Dayjs } from "dayjs";
import { PKExtended, PKFeedback, PKObservation } from './PaymentFeedback';
import { Session } from '../../Session';

export const FeedbackDialog = ( { item, startFigure, feedbacks, closeFn, saveFn }) => {
	const [dlgFeedbacks,setDlgFeedbacks] = useState<PKExtended[]>( feedbacks );
	const [dlgItem,setDlgItem] = useState(item);
	const [dlgStartFigure, setDlgStartFigure] = useState( startFigure );

	const [dlgAgreed, setDlgAgreed] = useState(false);
	const [dlgComment,setDlgComment] = useState("");
	const [dlgFigure,setDlgFigure] = useState("");
	const [dlgValidation,setDlgValidation] = useState("");

	const onChangeAgreed = ( event ) => {
		const checked = event.target["checked"];
		setDlgAgreed( checked ? true : false );
	}

	const cancel = () => {
		closeFn();
	}
	const save = () => {
		let figure = NaN;
		setDlgValidation( "Checking...");
		if( dlgAgreed ) {
			if( dlgFigure.trim() ) {
				setDlgValidation( "Proposed figure should be blank if you are agreeing");
				return;
			}
		} else {
			if( dlgFigure.trim() ) {
				figure = parseFloat( dlgFigure.trim().replaceAll( ",", "" ));
				if( isNaN(figure)) {
					setDlgValidation( "Proposed figure should be blank or numeric");
					return;
				}
			}
			if( !dlgFigure.trim() && !dlgComment.trim() ) {
				setDlgValidation( "You should enter either a comment or a proposed figure");
				return;
			}
		}
		saveFn( dlgAgreed, figure, dlgComment.trim() );
	}

	return (
		<Modal size="lg" show={true} onHide={cancel}>
		<Modal.Header closeButton>
			<Modal.Title>Feedback on {dlgItem} {utils.fmt2ps(dlgStartFigure)}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Container>
			{
				dlgFeedbacks.map( (fb,idx) => {
					return (
						<Row key={idx}>
							<Col className='col-md-2'>{dayjs(fb.createdAt).format( "D MMM YYYY")}<br/>{dayjs(fb.createdAt).format( "HH:mm")}</Col>
							<Col className='col-md-6'>{fb.userName}<br/>{fb.comment}</Col>
							<Col className='col-md-2'>{isNaN(fb.figureProposed) ? "-" : utils.fmt2ps(fb.figureProposed)}</Col>
							<Col className='col-md-2'>{fb.agreed ? "Agreed" : ""}</Col>
						</Row>
					)
				})
			}
			</Container>
			{dlgFeedbacks.length > 0 &&
			<div className='DlgSpacer'></div>
			}
			<Container>
				<Row>
					{Session.corporate.role == "underwriter" &&
					<>
					<Col className='col-md-2 RightAlign'>{Session.corporate.role == "underwriter" ? "Agreed" : ""}</Col>
					<Col className='col-md-4'><input type="checkbox" onChange={onChangeAgreed} /></Col>
					</> 
					} 
					{Session.corporate.role != "underwriter" && 
					<Col className='col-md-6'>&nbsp;</Col>
					}
				<Col className='col-md-3 RightAlign'>Proposed Figure</Col>
				<Col className='col-md-3'><input type='text' className='FullWidth' placeholder='Figure' value={dlgFigure} onChange={(e) => setDlgFigure(e.target.value)}/></Col>
				</Row>
				<Row className='Above12'>
				<Col className='col-md-2  RightAlign'>Comment</Col>
				<Col className='col-md-10'>
					<textarea className='DlgTextArea' value={dlgComment} onChange={(e) => setDlgComment(e.target.value)}/>
				</Col>
				</Row>				
				<Row>
					<Col className='col-md-8 WarningText'>{dlgValidation}</Col>
					<Col className='col-md-4 RightAlign'>
						<Button onClick={cancel}>Cancel</Button>&nbsp;<Button onClick={save}>Save</Button>
					</Col>
				</Row>
			</Container>
		</Modal.Body>
		</Modal>
	)
}