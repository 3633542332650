import React, { useState } from 'react'
import { Modal, Table, Row, Col, Button } from 'react-bootstrap'
import { whitespace, Query } from '../../helpers/whitespace'
import { riskFilter } from '../../helpers/riskFilter'
import { getMRCItemText, getDefinedData, JSONSR } from '../../helpers/mrc'
import { NotificationManager } from 'react-notifications'
import { SummaryFilter } from '../../components/SummaryFilter'
import { utils } from '../../FromElsewhere/utils'
import { payloads } from '../../helpers/payloads'
import { RiskGrid } from '../../components/RiskGrid'
import { pdfmaker } from '../../helpers/pdfmaker'
import { CorporateLogoBase64 } from '../../components/CorporateLogo'

class Invoice {
    umr = "";
    insured = "";
    address = "";
    overseasBroker = "";
    Inception_Date = "";
    Expiry_Date = "";
    Premium_Amount = "";
    Settlement_Due_Date = "";
    Tax_Percentage = "";
    taxAmount = "";
    grossAmount = "";
}

export const Invoicing = () => {
    const [risks, setRisks] = useState<any[]>([])
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [invoice, setInvoice] = useState<Invoice>( new Invoice() )

    const doSummary = ( grail ) => {
        grail = grail.trim();
        let payload = payloads.summary_Signed( grail );
        if( grail.startsWith( '=') ) {
            payload = payloads.summary_Draft( grail.substr( 1 ) );
        }

        setRisks( [] );
        whitespace(Query.POST, '/api/summary', payload ).then(response => {
            console.log( "POST /api/summary complete");
            setRisks( riskFilter.groupRisksSummary( response.data ));
        })
    }

    const pickRisk = ( risk ) => {
        const signedID = utils.rootIC( risk.id ) + "::SIGNED";
        console.log( `pickRisk /api/risks/${signedID}/getExtendedMRC`)
        whitespace(Query.GET, `/api/risks/${signedID}/getExtendedMRC`).then( response => {
            popupSelectedRisk( response.data )
        })
    }

    const popupSelectedRisk = ( xmrc ) => {
        const inv = new Invoice();
        inv.umr = getMRCItemText(xmrc, 'UMR');
        const insured1 = getMRCItemText(xmrc, 'Insured');
        const address1 = getMRCItemText(xmrc, 'PrincipalAddress', '\n');
        const insured2 = getMRCItemText(xmrc, 'Policyholder');
        const address2 = getMRCItemText(xmrc, 'PolicyholderAddress', '\n');

        inv.insured = insured1.length > insured2.length ? insured1 : insured2;
        inv.address = address1.length > address2.length ? address1 : address2;

        inv.overseasBroker = getMRCItemText(xmrc, 'OverseasBroker', '\n');
        inv.Inception_Date = getDefinedData( xmrc, "Period", "Inception_Date");
        inv.Expiry_Date = getDefinedData( xmrc, "Period", "Expiry_Date");
        inv.Premium_Amount = getDefinedData( xmrc, "Premium", "Premium_Amount");
        inv.Tax_Percentage = getDefinedData( xmrc, "TaxesPayableByInsuredAndAdministeredByInsurers", "Tax_%");
        inv.Settlement_Due_Date = getDefinedData( xmrc, "SettlementDueDate", "Settlement_Due_Date");
        const premium = utils.parseAmount( inv.Premium_Amount );
        if( premium ) {
            const percentage = parseFloat( inv.Tax_Percentage.replaceAll( "%", "" ) );
            const tax = ( premium.figure * percentage / 100.0 );
            inv.taxAmount = utils.formatAmount( premium.currency, tax );
            inv.grossAmount = utils.formatAmount( premium.currency, premium.figure + tax )
        }
        setInvoice( inv ); 
        setShowPopup( true );
    }

    const downloadInvoice = ( ) => {
        console.log( "downloadInvoice");
        let input = InvoiceTemplate;
        input = JSONSR(input, '{{umr}}', invoice.umr);
        input = JSONSR(input, '{{insured}}', invoice.insured);
        input = JSONSR(input, '{{address}}', invoice.address);
        input = JSONSR(input, '{{overseasBroker}}', invoice.overseasBroker);

        input = JSONSR(input, '{{Inception_Date}}', invoice.Inception_Date);
        input = JSONSR(input, '{{Expiry_Date}}', invoice.Expiry_Date);
        input = JSONSR(input, '{{Premium_Amount}}', invoice.Premium_Amount);
        input = JSONSR(input, '{{Settlement_Due_Date}}', invoice.Settlement_Due_Date);

        input = JSONSR(input, '{{Tax_Percentage}}', invoice.Tax_Percentage);
        input = JSONSR(input, '{{taxAmount}}', invoice.taxAmount);
        input = JSONSR(input, '{{grossAmount}}', invoice.grossAmount);


        input.images.logo = 'data:image/png;base64,' + CorporateLogoBase64
        pdfmaker.download( invoice.umr + " Invoice", input );
        // setShowPopup( false );
    }

    const RiskPopup = () => {
        return (
          <Modal size="lg" show={showPopup} onHide={() => setShowPopup(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Details for the Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='MySubTitle'>UMR</div><div>{invoice.umr}</div>
                <div className='MySubTitle'>Insured / Address</div><div>{invoice.insured} / {invoice.address}</div>
                <div className='MySubTitle'>Overseas Broker</div><div>{invoice.overseasBroker}</div>
                <div className='MySubTitle'>Period</div><div>{invoice.Inception_Date} to {invoice.Expiry_Date}</div>
                <div className='MySubTitle'>Premium</div><div>{invoice.Premium_Amount}</div>
                <div className='MySubTitle'>Tax</div><div>{invoice.taxAmount} ({invoice.Tax_Percentage} of Premium)</div>
                <div className='MySubTitle'>Gross Premium</div><div>{invoice.grossAmount}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={downloadInvoice}>Download Invoice</Button>
            </Modal.Footer>
          </Modal>
        )
    }

    return (
        <>
        <SummaryFilter onSearch={doSummary}></SummaryFilter>
        <RiskPopup></RiskPopup>
        <RiskGrid input={risks} pickFn={pickRisk} singleLine={true} />
        </>
    )
}

// 03/05/2024 Jody asked to reduce the logo size - went from 150 to 100
const InvoiceTemplate = {
    'pageMargins': [40, 90, 40, 40],
    'header': [
      {
          'columns': [
            {
              'width': '*',
              'text': ''
            },
            {
              'image': 'logo',
              'width' : 100,
              'alignment': 'right',
              'margin': [0,10,40,0]
            }
          ]
      }
    ],
    'content': [
        {
            'text': null,
            'style': 'sub_header'
        },
        {
            'style': 'texts',
            'margin': [0, 20, 0, 20],
            'text': '{{overseasBroker}}'
        },
        {
            'text': 'Invoice',
            'style': 'title',
            'margin': [0, 20, 20, 20]
        },
        {
            'style': [ 'left_margin_17', 'texts' ],
            'layout': {
              'defaultBorder': false
            },
            'table': {
                'widths': [
                '30%',
                '*'
                ],
                'body': [
                    [
                        { 'text' : 'UMR'},
                        { 'bold' : true, 'text' : '{{umr}}'}
                    ],
                    [
                        { 'text' : 'Insured'},
                        { 'bold' : true, 'text' : '{{insured}}'}
                    ],
                    [
                        { 'text' : 'Address'},
                        { 'bold' : true, 'text' : '{{address}}'}
                    ]
                ]
            }
        },
        {
            'margin': [0, 20, 0, 20],
            'text':'______________________________________________________________________________________________',
        },
        {

        },
        {
            'style': [ 'left_margin_17', 'texts' ],
            'layout': {
              'defaultBorder': false
            },
            'table': {
                'widths': [
                '30%',
                '*'
                ],
                'body': [
                    [
                        { 'text' : 'Period'},
                        { 'bold' : true, 'text' : '{{Inception_Date}} to {{Expiry_Date}}'}
                    ],
                    [
                        { 'text' : 'Premium'},
                        { 'bold' : true, 'text' : '{{Premium_Amount}}'}
                    ],
                    [
                        { 'text' : 'Tax'},
                        { 'bold' : true, 'text' : '{{taxAmount}} ({{Tax_Percentage}} of Premium)'}
                    ],
                    [
                        { 'text' : 'Gross Premium'},
                        {'bold' : true, 'text' : '{{grossAmount}}'}
                    ]                    
                ]
            }
        },
        {
            'style': [ 'left_margin_17', 'texts' ],
            'text': [ '\n\nPlease remit funds on or before ', 
                { 'text' : '{{Settlement_Due_Date}}', 'bold' : true }, 
                ' to:\n\n' ]
        },
        {
            'style': [ 'left_margin_17', 'texts' ],
            'layout': {
              'defaultBorder': false
            },
            'table': {
                'widths': [
                '30%',
                '*'
                ],
                'body': [
                    [
                        { 'text' : 'Bank Name:'},
                        { 'text' : 'Corporate Bank Inc.'}
                    ],
                    [
                        { 'text' : 'Currency:'},
                        { 'text' : 'USD'}
                    ],
                    [
                        { 'text' : 'Bank Account'},
                        { 'text' : 'ACCOUNT A'}
                    ],
                    [
                        { 'text' : 'Account Number'},
                        { 'text' : '0000000010'}
                    ],
                    [
                        { 'text' : 'Sort Code'},
                        { 'text' : '00-12-00'}
                    ]                    
                ]
            }
        },
        {
            'style': [ 'left_margin_17', 'texts' ],
            'text': '\n\nPlease quote our Risk Reference and Transaction Reference on all correspondence.'
        },
    ],
    'footer':
        {
        'text': 'Whitespace Demonstration Broker\n22 Bishopsgate, London, EC2N 4AJ\nRegistered in England & Wales at the above address. Company number 123123123. VAT Registration Number GB 123 3210 12\nWhitespace Demonstration Broker is a test only and neither authorised nor regulated by the Financial Conduct Authority',
        'style': 'footer'
        },
    'styles': {
      'title': {
        'fontSize': 12,
        'alignment': 'center',
        'bold': true,
        'margin': [0, 0, 0, 30]
      },
      'sub_header': {
          'fontSize': 10,
          'margin': [40, 10, 0, 0]
      },
      'bold' : {
          'bold' : true
      },
      'left_margin_17' : {
        'margin': [17, 0, 0, 0]
      },
      'red_text' : {
        'color' : 'red'
      },
      'right_aligned' : {
          'alignment' : 'right'
      },
      'bold_right_aligned' : {
        'bold': true,
        'alignment' : 'right'
      },
      'table_down': {
        'fontSize': 10,
        'margin': [35, 0, 0, 0]
      },
      'endings': {
        'fontSize': 10,
        'margin': [40, 40, 0, 0]
      },
      'sub_title': {
        'fontSize': 11,
        'bold': true,
        'margin': [40, 0, 0, 0]
    },
      'texts': {
        'fontSize': 10
      },
      'footer': {
        'fontSize': 9,
        'opacity': 0.5,
        'alignment': 'center',
        'margin': [45, -30, 45, 10]
      }
    },
    'images': {
      'logo': ''
    }
}