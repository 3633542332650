import React, { useState, useEffect } from 'react'
import { Modal, Col, Row, Button, Tab, Tabs, Form, Container } from 'react-bootstrap'
import { whitespace, Query, WhitespaceAPI } from '../../helpers/whitespace'
import './DefDataViewer.css'
import { utils } from '../../FromElsewhere/utils';
import { mkdir } from 'fs';

class MatchedHeading {
	constructor( key : string, tags : string[] ) {
		this.key = key;
		this.tags = tags;
	}
	key : string;
	tags : string[];
}

class Rule {
	example: string | null = null;
	regex: string | null = null;
	template: string | null = null;
	type: string | null = null;
	values: string[] | null = null;
}

class MatchedRule {
	constructor( index : number, rule : Rule ) {
		this.index = index;
		this.rule = rule;
	}
	index : number;
	rule : Rule;
	tags : string[] = [];
	values : string[] = [];
	allTags : string[] = [];
	allValues : string[] = [];
}

export const DefDataViewer = () => {
	const [docID, setDocID] = useState("Not Loaded");
	const [rev, setRev] = useState("Not Loaded");
	const [rwdd,setRWDD] = useState<any>( null );
	const [tphCount, setTPHCount] = useState(0);
	const [validationRules, setValidationRules] = useState<any[]>([]);
	const [rawJSON, setRawJSON] = useState("");
	const [grail,setGrail] = useState("premium");
	const [precise, setPrecise] = useState(false);
	const [activeTabKey, setActiveTabKey] = useState("perHeading");
	
	const [matchedHeadings, setMatchedHeadings] = useState<MatchedHeading[]>([]);
	const [matchedRules, setMatchedRules] = useState<MatchedRule[]>([]);
	const [selectedHeading, setSelectedHeading] = useState<MatchedHeading | null>(null);
	const [selectedRule, setSelectedRule] = useState<MatchedRule | null>(null);

	useEffect(() => {
        WhitespaceAPI.get( "/api/documents/RWDefinedData").then( response => {
			const data = response.data;
			setRawJSON( JSON.stringify( data, null, 4 ));
			setDocID( data._id );
			setRev( data._rev );
			setRWDD( data );
			setTPHCount( Object.keys( data.tagsPerHeading).length );
			setValidationRules( data.validationRules );
		});
    }, []);

	const match = ( str : string ) : boolean => {
		if( precise ) {
			return str.includes( grail );
		} else {
			return utils.aggressiveTidy(str).includes( utils.aggressiveTidy(grail));
		}
	}

	const doSearch = () => {
		console.log( `searching for ${grail} precise ${precise}`);
		const mhArray : MatchedHeading[] = [];
		Object.keys( rwdd.tagsPerHeading ).forEach( key => {
			const item = rwdd.tagsPerHeading[ key ];
			const tags = item.filter( i => { return match( i ) } );
			if( match(key) || tags.length) {
				mhArray.push( new MatchedHeading( key, tags ) );
			}
		});
		setMatchedHeadings( mhArray );

		const mrArray : MatchedRule[] = [];
		validationRules.forEach( (r,idx) => {
			let tags : string[] = [];
			let values : string[] = [];
			if( r.tags ) {
				tags = r.tags.filter( i => { return match( i ) } );
			}
			if( r.rule && r.rule.values ) {
				values = r.rule.values.filter( i => { return match( i ) } );
			}
			if( tags.length || values.length ) {
				const mr = new MatchedRule( idx, r.rule );
				mr.tags = tags;
				mr.values = values;
				mr.allTags = r.tags;
				mrArray.push( mr );
				console.log( `added #${mr.index} type:${mr.rule.type} example:${mr.rule.example} ${mr.tags.length} tags, ${mr.values.length} values`);
			}
		});
		setMatchedRules( mrArray );
	}

	const showOneHeading = ( key ) => {
		const item = rwdd.tagsPerHeading[ key ];
		const mhArray : MatchedHeading[] = [];
		setSelectedHeading( new MatchedHeading( key, item ) );
		setActiveTabKey( "oneHeading");
	}

	const ShowHeading = ( { itm, idx } ) => {
		const mh : MatchedHeading = itm;
		return ( 
			<>
			<div key={idx}><span className='MyLink' onClick={() => showOneHeading(mh.key)}>{mh.key}</span></div>
			<div>
				<ul>
					{mh.tags.map( (itm,idx) => <li key={idx}>{itm}</li>)}
				</ul>
			</div>
			</>
		)
	}

	const showOneRule = ( mr : MatchedRule ) => {
		console.log( `Showing just ${mr.index}`);
		console.log( mr );
		const mr2 = new MatchedRule( mr.index, mr.rule );
		mr2.tags = mr.allTags;
		mr2.values = ( mr.rule?.values ? mr.rule.values : [] );
		mr2.allTags = mr.allTags;
		mr2.allValues = mr.allValues;
		setSelectedRule( mr2 );
		setActiveTabKey( "oneRule");
	}

	const ShowRule = ( { itm, idx } ) => {
		const mr : MatchedRule = itm;
		return (
			<>
			<div key={idx} className='Below12 OneRule'>
				{ mr.rule.type && <div className='SmallText LightGray'>Type: {mr.rule.type}</div> }
				{ mr.rule.template && <div className='SmallText LightGray'>Template: {mr.rule.template}</div> }
				{ mr.rule.example && <div className='SmallText LightGray'>Example: {mr.rule.example}</div> }
				{ mr.rule.regex && <div className='SmallText LightGray'>Regex: {mr.rule.regex}</div> }
				{ mr.tags.length > 0 && 
					<>
					<div><span className='MyLink' onClick={() => showOneRule(mr)}>#{mr.index} Tags</span></div>
					<ul>
						{mr.tags.map( (itm,idx) => <li key={idx}>{itm}</li>)}
					</ul>
					</>
				}
				{ mr.values.length > 0 && 
					<>
					<div><span className='MyLink' onClick={() => showOneRule(mr)}>#{mr.index} Values</span></div>
					<ul>
						{mr.values.map( (itm,idx) => <li key={idx}>{itm}</li>)}
					</ul>
					</>
				}
			</div>
			</>
		)
	}

    return (
        <>
		<div>Document {docID} has {tphCount} tagsPerHeading items, {validationRules.length} validation rules</div>
		<div className='SmallText LightGray'>Revision {rev}</div>
		<div>
			<Container>
				<Row className='Above12'>
					<Col className='col-md-6'><input type='text' className='FullWidth' value={grail} onChange={(e) => setGrail(e.target.value)}/></Col>
					<Col className='col-md-4'><input type="checkbox" onChange={(e) => setPrecise(e.target["checked"] ? true : false)} /> Precise (case, text, punctuation sensitive) </Col>
					<Col className='col-md-2'><Button onClick={doSearch}>Search</Button></Col>
				</Row>
			</Container>
		</div>
		<div className='Above12'>
			<Tabs activeKey={activeTabKey} onSelect={(event) => setActiveTabKey(event || "perHeading")}>
				<Tab eventKey="perHeading" title={`Per Heading ${matchedHeadings.length}`} className='BorderedTab'>
					<div>
					{matchedHeadings.map( ( itm, idx ) =>  <ShowHeading key={idx} itm={itm} idx={idx}/> ) }
					</div>
				</Tab>
				<Tab eventKey="oneHeading" title="One Heading" className='BorderedTab'>
					{ !selectedHeading && 
						<div>Click on an item in the Per Heading tab</div>
					}
					{selectedHeading && 
						<ShowHeading itm={selectedHeading} idx={0}/>
					}

				</Tab>
				<Tab eventKey="perRule" title={`Per Rule ${matchedRules.length}`} className='BorderedTab'>
					{matchedRules.map( ( itm, idx ) =>  <ShowRule key={idx} itm={itm} idx={idx}/> ) }
				</Tab>
				<Tab eventKey="oneRule" title="One Rule" className='BorderedTab'>
				{ !selectedRule && 
						<div>Click on an item in the Per Rule tab</div>
					}
				{selectedRule && 
						<ShowRule itm={selectedRule} idx={0}/>
					}
				</Tab>
				<Tab eventKey="asJSON" title="As JSON" className='BorderedTab'>
					<textarea className='JSONViewer' value={rawJSON} ></textarea>
				</Tab>
			</Tabs>
		</div>
        </>
    )
}