import { utils } from "../FromElsewhere/utils";

interface ISO3166 {
	countries : string[][];
	subentities: string[][];
}

export class DataRuleTranslator {
	static None = "None";
	static Country = "Country";
	static Subentity = "Subentity";
	static AmountFigure = "AmountFigure";
	static AmountCurrency = "AmountCurrency";

	iso3166 : ISO3166 = { countries: [], subentities: [] };
	setISO3166( x : any ) : void {
		this.iso3166 = x;
		this.iso3166.countries.forEach( itm => {
			itm.forEach( (str,i) => {
				if( i > 0 ) {
					itm[i] = utils.diacriticTidy(str);
				}
			});
		});
		this.iso3166.subentities.forEach( itm => {
			itm.forEach( (str,i) => {
				if( i > 0 ) {
					itm[i] = utils.diacriticTidy(str);
				}
			});
		});
	}

	translate( value : string, translation : string ) : string {
		let lookup : any[] = [];
		const tidied = utils.diacriticTidy( value );
		if( translation == DataRuleTranslator.None ) {
			return value;
		}
		if( translation == DataRuleTranslator.AmountCurrency || translation == DataRuleTranslator.AmountFigure ) {
			return this.doAmountTranslate( value, translation );
		}
		if( translation == DataRuleTranslator.Country ) {
			lookup = this.iso3166[ "countries" ];
		}
		if( translation == DataRuleTranslator.Subentity ) {
			lookup = this.iso3166[ "subentities" ];
			// lookup = [
			// 	[ "US-CA", "California" ],
			// 	[ "US-TX", "Texas" ],

			// 	[ "ES-AN","Andalucía"],
			// 	[ "ES-AR","Aragón"],
			// 	[ "ES-AS","Asturias"],
			// 	[ "ES-CN","Canarias"],
			// 	[ "ES-CB","Cantabria"],
			// 	[ "ES-CL","Castilla y León"],
			// 	[ "ES-CM","Castilla-La Mancha"],
			// 	[ "ES-CT","Catalunya|Cataluña"],
			// 	[ "ES-CE","Ceuta"],
			// 	[ "ES-EX","Extremadura"],
			// 	[ "ES-GA","Galicia"],
			// 	[ "ES-IB","Illes Balears|Islas Baleares"],
			// 	[ "ES-RI","La Rioja"],
			// 	[ "ES-MD","Madrid"],
			// 	[ "ES-ML","Melilla"],
			// 	[ "ES-MC","Murcia"],
			// 	[ "ES-NC","Navarra"],
			// 	[ "ES-PV","País Vasco|Euskal Herria"],
			// 	[ "ES-VC","Valenciana"]
			// ]
		}
		const llen = lookup.length;
		if( llen == 0 ) {
			return value;
		}
		for( let i = 0; i < llen; i++ ) {
			const lup = lookup[i];
			const possibles = lup;	// [1].split( "|");
			if( possibles.includes( tidied ) || possibles.includes( value )) {
				return lup[0];
			}
		}
		return value;
	}

	doAmountTranslate( value : string, translation : string ) : string {
		const rgx = /^[A-Z]{3}\s['\d.,']*$/;
		if( !value.match( rgx ) ) {
			return value;
		}
		if( translation == DataRuleTranslator.AmountCurrency ) {
			return value.substring(0,3);
		} else {
			return value.substring(4).replaceAll(",","");
		}

	}
}