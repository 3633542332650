import {JMRC} from '../rebrowse/jmrc'
import { utils } from './utils';

export class DDExportMetadata {
    version = "";
    documentID = "";
    env = "";
    fetchedAt = "";
    documentRevision = "";
    tagSet = "";
    userID = "";
}

export class DDSectionDefinition {
    sectionID = "";
    sectionName = "";
}

export class DDExport {
    definedData: DDDataPoint[] = [];
    metadata: DDExportMetadata | null = null;
    multiSectionDefinition: DDSectionDefinition[] = [];

    constructor( x : any ) {
        this.definedData = x.definedData || [];
        this.metadata = x.metadata || null;
        this.multiSectionDefinition = x.multiSectionDefinition || [];
    }

    getFirstMatchingValue = ( tag : string ) : string => {
        const matches = this.definedData.filter( item => { return item.tag == tag });
        if( matches.length ) {
            return matches[0].value;
        } 
        return "";
    }
    getByTagAndSection = ( tag : string, sCombo : string ) : DDDataPoint[] => {
        return this.definedData.filter( item => { return item.tag == tag && DDExport.combineSectionIDs(item.sectionIDs) == sCombo });
    }
    getFirstSectionedValue = ( tag : string, sCombo : string ) : string => {
        const matches = this.getByTagAndSection( tag, sCombo );
        if( matches.length ) {
            return matches[0].value;
        } 
        return "";
    }
    getAnalysisByCarrierAndSection = ( tags : string[] ) : any[] => {
        const retval: any[] = [];
        const sectionCombinations: string[] = [];
        this.definedData.forEach( dd => {
            const sCombo = DDExport.combineSectionIDs( dd.sectionIDs );
            if( !sectionCombinations.includes(sCombo) ) {
                sectionCombinations.push( sCombo );
            }
        });
        interface Pair {
            tag: string
            value: string;
        }
        interface Answer {
            sCombo: string;
            parentID: string;
            pairs: Pair[];
        }
        const answers: Answer[] = [];
        sectionCombinations.sort().forEach( sCombo => {
            const signedPoints = this.getByTagAndSection( "Signed_Line_Percentage", sCombo );
            const pages = signedPoints.map( dp => { return dp.parentID })
                .reduce( ( result: string[], val: string ) => { if( !result.includes(val) ) result.push(val); return result }, [] );
            pages.forEach( parentID => {
                const ob: Answer = { sCombo: sCombo, parentID: parentID, pairs: [] };
                tags.forEach( t => {
                    let value = "";
                    let point = this.getByTagAndSection( t, sCombo ).filter( p => { return p.parentID == parentID })[0];
                    if( point ) {
                        value = point.value;
                    } else {
                        point = this.getByTagAndSection( t, sCombo )[0];
                        if( point ) {
                            value = point.value;
                        } else {
                            point = this.getByTagAndSection( t, "" )[0];
                            if( point ) {
                                value = point.value;
                            }
                        }
                    }
                    ob.pairs.push( { tag: t, value: value });
                });
                answers.push( ob );
                retval.push( ob );
            });
        });
        return retval;
    }
    static combineSectionIDs( sectionIDs : string[] ) : string {
        return sectionIDs ? sectionIDs.sort().join(",") : "";
    }
}

export class DDPage {
    mrcHeading = "";
    parentID = "";
    points: DDDataPoint[] = [];
    constructor( p: DDDataPoint ) {
        this.mrcHeading = p.mrcHeading;
        this.parentID = p.parentID;
        this.points.push( p );
    }

    matchesOb = ( ob : any ) : boolean => {
        return this.mrcHeading === ob.mrcHeading && this.parentID === ob.parentID
    }

    matchForCopy = ( page: DDPage, debug = false ) : boolean => {
        if( page.mrcHeading != this.mrcHeading )   return false;
        if( page.points.length != this.points.length ) return false;

        if( debug ) {
            console.log( `matchForCopy cf ${this.parentID} and ${page.parentID}, both ${page.mrcHeading} ${page.points.length}`);
        }
        let retval = true;
        page.points.forEach( p => {
            const tparray = this.points.filter( tp => { return tp.tag == p.tag });
            if( tparray.length != 1 ) {
                retval = false;
                if( debug ) {
                    console.log( `matchForCopy cf ${tparray.length} tags match ${p.tag}`);
                }
            } else {
                const tp = tparray[0];
                if( !this.valuesMatch( p.value, tp.value ) ) { //  p.value != tp.value && p.value != "???" ) {
                    retval = false;
                    if( debug ) {
                        console.log( `matchForCopy may not because target:${p.value}: source:${tp.value}:`);
                    }    
                }
            }
        });
        if( retval ) {
            console.log( `matchForCopy ok - match found`);
        }
        return retval;
    }

    valuesMatch = ( pValue : string, tpValue : string ) => {
        if( pValue == "???" ) {
            return true;
        }
        if( utils.aggressiveTidy( pValue ) == utils.aggressiveTidy( tpValue ) ) {
            return true;
        }
        return false;
    }

    static fromJMRC( jmrc : JMRC.Root ) : DDPage[] {
        const result: DDPage[] = [];
        const points = DDDataPoint.fromJMRC( jmrc );
        console.log( `${points.length} points`);
        points.forEach( p => {
            const page : DDPage = result.filter( page => { return page.matchesOb( p ); } )[0];
            if( page ) {
                page.points.push( p );
            } else {
                result.push( new DDPage( p ));
            }
        });
        return result;
    }
}

export class DDDataPoint {
    mrcHeading = "";
    name = "";
    parentID = "";
    tag = "";
    value = "";
    placeholder: JMRC.Placeholder;
    sectionIDs: string[] = [];  // TODO properly - added quite late on

    constructor( mrcHeading: string, parentID: string, tag: string, ph: JMRC.Placeholder ) {
        this.mrcHeading = mrcHeading;
        this.name = ph.name || "";
        this.parentID = parentID || "";
        this.tag = tag || "";
        this.value = ph.value || "";
        this.placeholder = ph;
        this.sectionIDs = [];   
    }

    static fromJMRC( jmrc : JMRC.Root | JMRC.XMRC ) : DDDataPoint[] {
        const result: DDDataPoint[] = [];
		jmrc.MRCContract.lineItems.forEach( li => {
			li.placeholders.forEach( ph => {
				if( ph.name.substring(0,11) == "DefinedData") {
					DDDataPoint.fromPlaceholder( li.mrcHeading, ph ).forEach( dp => {
						result.push( dp );	
					});
				}
			});
		});
		return result;
    }

    static fromPlaceholder( mrcHeading: string, ph : JMRC.Placeholder ) : DDDataPoint[] {
        const array: DDDataPoint[] = [];
        const parentID = ph.tags?.filter( tag => { return tag.startsWith( "Page:" ); })[0] || "";
        ph.tags?.forEach( tag => {
            if( !tag.startsWith( "Page:" ) )
            {
                const newPoint = new DDDataPoint( mrcHeading, parentID, tag, ph );
                array.push( newPoint );
            }
        });
        return array;
    }

    static getFirstMatchingValue( ddPoints : DDDataPoint[], tag : string ) : string {
        const matches = ddPoints.filter( item => { return item.tag == tag });
        if( matches.length ) {
            return matches[0].value;
        } 
        return "";
    }
}

export class DefinedData {
    /*
    static readMRC( mrc : JMRC.MRCContract ) : DDDataPoint[] {
        const result : DDDataPoint[] = [];

        mrc.lineItems.map( ( li ) => {
            li.placeholders.map( ( ph ) => {
                if( ph.name.startsWith( "DefinedData") ) {
                    const pageID = ph.tags?.filter( ( t ) => { return t.startsWith( "Page:")})[0].substring(5);
                    const pageTitle = li.tagPages?.filter( ( p ) => { return p.pageID == pageID })[0].title;
                    ph.tags?.map( ( t ) => {
                        if( t != "Page:" + pageID ) {
                            const dd = new DDDataPoint();
                            dd.mrcHeading = li.mrcHeading;
                            dd.name = ph.name;
                            dd.parentID = pageID || "";
                            dd.tag = t;
                            dd.value = ph.value;
                            result.push( dd );
                        }    
                    });
                }
            })
        });
        return result;
    }
    */
    static findFirst( ddpoints: DDDataPoint[], grail : any ) : DDDataPoint | null {
        const all = DefinedData.findAll( ddpoints, grail );
        if( all[0] ) {
            return all[0];
        }
        return null;
    }

    static findAll( ddpoints: DDDataPoint[], grail : any ) : DDDataPoint[] {
        let subset = [...ddpoints];
        if( grail.mrcHeading ) {
            if( grail.tidy ) {
                subset = subset.filter( ( p ) => { return utils.aggressiveTidy(p.mrcHeading) == utils.aggressiveTidy(grail.mrcHeading) } );
            } else {
                subset = subset.filter( ( p ) => { return p.mrcHeading == grail.mrcHeading } );
            }
        }
        if( grail.tag ) {
            subset = subset.filter( ( p ) => { return p.tag == grail.tag } );
        }
        if( grail.parentID ) {
            subset = subset.filter( ( p ) => { return p.parentID == grail.parentID } );
        }
        if( grail.parentIDs ) {
            subset = subset.filter( ( p ) => { return grail.parentIDs.includes( p.parentID ) } );
        }
        if( grail.sectionID ) {
            subset = subset.filter( ( p) => { return !p.sectionIDs || p.sectionIDs.length == 0 || p.sectionIDs.includes( grail.sectionID ) } );
        }
        return subset;
    }
    static getDistinctParents( ddpoints: DDDataPoint[] ) : string[] {
        return [...new Set( [ ...ddpoints.map( x => { return x.parentID }) ] )];
    }
}