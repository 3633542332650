import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export const OPEditor = ( { index, lineSet, updateParent } ) => {
	const [ls, setLS] = useState<any>( lineSet );
	const [itmIdx, setItmIdx] = useState( index );
	// const [percentage, setPercentage] = useState( lineSet.lines[0].impressions[0].writtenPercentage );

	const onChangePercentage = ( event, lidx, iidx ) => {
		const perc : number = parseFloat( event.target.value );
		ls.lines[lidx].impressions[iidx].writtenPercentage = isNaN(perc) ? 0.0 : perc;
		// setPercentage( isNaN(perc) ? "0" : event.target.value ); // needed to preserve decimal point as it it typed // instruction.percentage );
		updateParent( { itmidx: itmIdx, lidx: lidx, iidx: iidx, percentage: ls.lines[lidx].impressions[iidx].writtenPercentage } );
	}

	const displayImpression = ( { imp, lidx, iidx } ) => {
		const key = ( itmIdx * 100 * 100 + lidx * 100 + iidx ) * 10;
		return (
			<Row key={key} className='Above2' >
				<Col xs={2} ><input className='FullWidth RightAlign' type="text" defaultValue={imp.writtenPercentage} onChange={(event) => onChangePercentage( event, lidx, iidx )}/></Col>
				<Col xs={10}>
					<div>{imp.businessUnit} 
						&nbsp;/&nbsp;
						{imp.bureauMarket}
						&nbsp;{imp.bureauMarketCode}
						&nbsp;{imp.bureauSubMarket}
					</div>
				</Col>
			</Row>
		)
	}
	const displayLine = ( { line, lidx } ) => {
		return (
			<div key={lidx}>
			{line.impressions.map( (imp,iidx) => { return displayImpression( { imp, lidx, iidx } ) } ) }
			</div>
		)
	}

	return (
		<>
		{lineSet.lines.map( (line,lidx) => { return displayLine( { line, lidx } ) } ) }
		</>
	)

}