import dayjs from 'dayjs'

export const utils = {
    dateCompare : function( d1: any, d2: any ) {
        if( d1 < d2 )
            return -1
        if( d1 > d2 )
            return 1
        return 0
    },
    formatDateTime : function( d: any, fmt = 'D MMM YYYY HH:mm' ) {
        if( !d )
            return ''
        return dayjs( d ).format( fmt )
    },
    rootIC : function( docID: string ) {
        return docID ? docID.split( '::')[0] : docID
    },
    truncateIC : function( docID: string ) {
        if( !docID )
            return docID
        return docID.length > 9 ? docID.substring(0,6) + '...' : docID 
    },
    umrFromDate : function( d: any ) {
        if( !d )
            return 'B0999JC' + '0000000000'
        else
            return 'B0999JC' + dayjs( d ).format( 'DDMMYYHHmm');
    },
    makePlural : function( count: number, single : string, plural : string | null = null ) {
        if( count == 1 )
            return single;
        else
            return plural ? plural : single + "s";
    },
    validUMR : function( s: string ) {
        const pattern = /^B\d{4}[A-Z0-9]{1,12}$/i
        return pattern.test( s );
    },
    parseAmount : function( s: string ) {
        const pattern = /[A-Z]{3}\s+[0-9,.]+/
        if( pattern.test( s ) ) {
            return { currency : s.substring( 0, 3 ), figure : parseFloat( s.substring( 4 ).replaceAll( ",", "" ) )}
        }
        return null;
    },
    splitAmount : function ( s: string ) {
        const parsed = utils.parseAmount( s );
        if( parsed ) {
            return [ parsed.currency, parsed.figure.toFixed(2) ];
        } else {
            return [ s, "" ];
        }
    },
    trimTrailingZeroes : function( str : string ) : string {
        if( str.includes( ".") ) {
            const rgx1 = /0+$/, rgx2 = /\.$/;
            return str.replace( rgx1, "").replace( rgx2, "");
        }
        return str;
    },
    formatAmount : function( currency : string, figure: number ) {
        return currency + " " + figure.toLocaleString("en-GB", { minimumFractionDigits: 2, maximumFractionDigits: 2 } ).replaceAll( ".00", "");
    },
    round2dps : function( figure: number ) {
        return parseFloat((figure).toFixed(2));
    },
    fmt2ps : function( figure: number ) {
        return figure.toLocaleString("en-GB", { minimumFractionDigits: 2, maximumFractionDigits: 2 } );   
    },
    diacriticTidy : function( str: string ) {
        return utils.aggressiveTidy( str.normalize("NFD").replace(/\p{Diacritic}/gu, "") );
    },
    aggressiveTidy : function( str: string ) {
        if( !str ) {
            return "";
        }
        const parts = str.match( /[A-Z0-9]/gi );
        if( !parts ) {
            return "";
        }
        return parts.join( '' ).toLowerCase();
    },
    prepareForCSV : function( str: string ) {
        if( !str ) {
            return "";
        }
        return '"' + str.replaceAll( "\n", " ").replaceAll( "\r", "").replaceAll( "\t", " " ).replaceAll( '"', "'") + '"';
    },
    prettyStatus : function( status : string ) : string {
        let PrettyStatus = status;
        if( PrettyStatus == "NotFinalised") {
            PrettyStatus = "Draft";
        }
        const rgx = new RegExp( "[A-Z]+[a-z]+", "g" )
        const parts = PrettyStatus.match( rgx );
        if( parts ) {
            const newlength = parts.reduce( (x1,p) => { return x1 + p.length; }, 0 );
            if( newlength == PrettyStatus.length ) {
                PrettyStatus = parts.join( " ");
            }    
        }
        return PrettyStatus;
    }

}