import dotenv from 'dotenv'
import React, { useState, useEffect, ReactElement } from 'react'
import { Modal } from 'react-bootstrap'
import { Navbar } from './components/Navbar'
import { Page } from './components/Page'
import { Login } from './components/Login'
import { isLoggedIn } from './helpers/auth'
import { modules } from './modules/registrar'
import { whitespace, Query } from './helpers/whitespace'
import Cookies from 'js-cookie'
import 'react-notifications/lib/notifications.css'
import './App.css'

// Configure application environment 
dotenv.config()
import { NotificationContainer } from 'react-notifications'
import { Session } from './Session'

export let MUID = ''
export const setMUID = ( value : string ) : void => {
    MUID = value
}

export const RefreshSubscribers : any[] = [];

export const LoadSessionDetails = () => {
  whitespace(Query.GET, '/api/user/myDetails').then( response => {
    if( response?.data ) {
        Session.userDetails = response.data;
        whitespace(Query.GET, '/api/documents/Corporate-' + Session.userDetails.companyId ).then( response2 => {
            if( response2?.data ) {
                Session.corporate = response2.data;
            }
            console.log( `calling ${RefreshSubscribers.length} Refresh Subscribers for ${Session.corporate.companyId} ${Session.userDetails.uniqueID}`);
            RefreshSubscribers.forEach( ob => {
                ob.fn();
            });
        });
    }
});  
}

export const App = () : ReactElement => {
  const [loggedIn, setLoggedIn] = useState<boolean>()
  const [module, setModule] = useState(modules()[0])
  const [refresh, triggerRefresh] = useState('')

  useEffect(() => {
    setLoggedIn(isLoggedIn()) 
    console.log('debug available cookies', Cookies.get())
  }, [])

  const handleLogin = loggedIn => {
    if( loggedIn ) {
        // setLoggedIn(true)
        const authResult = whitespace(Query.POST, '/auth/token', {} )
        Promise.all([ authResult]).then( response => {
            if( response[0]?.data ) {
                if( response[0].data.userID ) {
                    MUID = response[0].data.userID
                } 
                if( response[0].data.userIDs ) {
                    MUID = response[0].data.userIDs[0]
                }
                console.log( 'handleLogin', loggedIn, MUID )
                setLoggedIn(true);
                LoadSessionDetails();
            }
        })
    } else {
        setLoggedIn(false)
    }
  }

  const external = () => {
    return (
      <div className="External-backdrop">
      <Modal show={true}><Login handler={handleLogin} /></Modal>
      </div>
    )
  }

  const internal = () => {
    return (
      <div className="page-layout">
       <Navbar currentModule={module} switchModule={setModule} triggerRefresh={triggerRefresh} />
       <Page module={module} refresh={refresh} />
      </div>
    )
  }

  return (
    <div className="App">
        <NotificationContainer/>
        {loggedIn ? internal() : external() }
    </div>
  )
}

export default App
