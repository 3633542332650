import { DDDataPoint, DDExport, DefinedData } from "../../FromElsewhere/DefinedData";
import { JMRC } from "../../rebrowse/jmrc";
import { DefData } from "../../rebrowse/defined.data";
import { LGUI } from "../../rebrowse/line.guidance";
import { MSStorer } from "../../rebrowse/multi.section.storer";
import dayjs from 'dayjs';
import { LineGuidanceSet } from "../../helpers/LineGuidanceSet";

const wsDatetimeFormat = 'YYYY-MM-DD HH:mm:ss'

export class GlobulePurpose {
	static DefData = "DefData";
	static Risk = "Risk";
	static LineGuidance = "LineGuidance";
	static MultiSectionStorer = "MultiSectionStorer"
}

export class GlobuleStatus {
	static Uncalled = "Uncalled";
	static Failed = "Failed";
	static Calling = "Calling";
	static OK = "OK";
}

export class Globule {
	purpose : string;
	url : string;
	fetched : string;
	data: any = {};
	constructor( purpose: string, url : string ) {
		this.purpose = purpose;
		this.url = url;
		this.fetched = "new";
	}
	
}

interface StampCarrierBusinessUnit {
	stampID : string
	carrier : string
	businessUnit : string;
}

export class Candidate {

	rootID = "";
	riskID = "";
	umr = "";
	insuredName = "";
	inceptionDate = "inception_date";
	fetched = 0;
	globules: Globule[] = [];
	output: any = null;

	ddData: DefData.Root | null = null;
	xmrc: JMRC.XMRC | null = null;
	lgui: LGUI.Root[] | null = null;
	msst: MSStorer.Root | null = null;

	constructor( rootID : string ) {
		this.rootID = rootID;
		this.riskID = `${rootID}::SIGNED`;
	}

	setFourInputs = ( ddData: any, xmrc: any, lgui: any, msst: any ) => {
		this.ddData = ddData;
		this.xmrc = xmrc;
		this.lgui = lgui;
		this.msst = msst;
	}

	pickGlobule = ( purpose : string ) : Globule => {
		return this.globules.filter( g => { return g.purpose == purpose })[0]
	}
	getInceptionDate = () : string => {
		const globule = this.pickGlobule( GlobulePurpose.Risk );
		if( globule && globule.data && globule.data.MRCContract && globule.data.MRCContract.lineItems ) {
			const x : JMRC.Root = globule.data;
			const ddPoints = DDDataPoint.fromJMRC( x );
			const inceptionDate = DDDataPoint.getFirstMatchingValue( ddPoints, "Inception_Date");
			if( inceptionDate ) {
				return dayjs( inceptionDate ).format( wsDatetimeFormat );
			}
		}
		return "";
	}

	getTaggedValueFromRiskData = ( tag : string ) => {
		if( this.output && this.output.ddPoints ) {
			return DDDataPoint.getFirstMatchingValue( this.output.ddPoints, tag );
		}
		return "";
	}

	prepareForContractOutput = () => {
		const xmrcGlobule = this.pickGlobule( GlobulePurpose.Risk );
		if( xmrcGlobule && xmrcGlobule.data && xmrcGlobule.data.MRCContract && xmrcGlobule.data.MRCContract.lineItems ) {
			this.xmrc = xmrcGlobule.data;
			this.read_xmrc();
			// const x : JMRC.Root = globule.data;
			// this.umr = x.control.umr;
			// this.insuredName = x.control.insuredName;
			// this.output = { ddPoints : DDDataPoint.fromJMRC( x ) };
		}
	}
	read_xmrc = () => {
		if( this.xmrc ) {
			this.umr = this.xmrc.control.umr;
			this.insuredName = this.xmrc.control.insuredName;
			this.output = { ddPoints : DDDataPoint.fromJMRC( this.xmrc ) };
		}
	}

	prepareForSignedOutput = ( expectedTags : string[] ) => {
		const dataGlobule = this.pickGlobule( GlobulePurpose.DefData );
		const lguiGlobule = this.pickGlobule( GlobulePurpose.LineGuidance ); 
		const xmrcGlobule = this.pickGlobule( GlobulePurpose.Risk ); 
		const msstGlobule = this.pickGlobule( GlobulePurpose.MultiSectionStorer ); 
		this.setFourInputs( dataGlobule?.data, xmrcGlobule?.data, lguiGlobule?.data, msstGlobule?.data );
		this.read_ForSignedOutput( expectedTags );
	}

	read_ForSignedOutput = ( expectedTags : string[] ) => {
		this.output = { analysis: null };
		if( this.ddData ) {
			const ddExport : DDExport = new DDExport( this.ddData );
			const analysis : any[] = ddExport.getAnalysisByCarrierAndSection( expectedTags );
			analysis.forEach( a => { 
				let slip_leader = "";
				if( this.lgui && !slip_leader) {
					slip_leader = this.lookForSlipLeaderInLGSet( a.sCombo, this.lgui );
				}
				if( this.xmrc && !slip_leader ) {
					slip_leader = this.lookForSlipLeaderInRisk();
				}
				if( this.msst && this.msst.sections && !slip_leader ) {
					slip_leader = this.lookForSlipLeaderInMultiSectionStorer( a.sCombo );
				}
				if( slip_leader ) {
					const p = a.pairs.filter( ( p : any ) => { return p.tag == "SLIP-LEADER"} )[0];
					if( p ) {
						p.value = slip_leader;
					}
				}
				const cbu : StampCarrierBusinessUnit | null = this.lookForCarrierBusinessUnit( a.parentID );
				if( cbu ) {
					let p = a.pairs.filter( ( p : any ) => { return p.tag == "CARRIER-NAME"} )[0];
					if( p ) {
						p.value = cbu.carrier;
					}
					p = a.pairs.filter( ( p : any ) => { return p.tag == "BUSINESS-UNIT"} )[0];
					if( p ) {
						p.value = cbu.businessUnit;
					}
				}
			});	
			this.output.analysis = analysis;
		}
	}
/*
private func getIsLeadUnderwriter(risk: MRCRisk, carrierChannel: String, lineGuidanceSets: [LineGuidanceSet], sectionID: String?, role: FacilityRole?, associatedFacilityId: FacilityID?, sectionInfos: [SectionInfo]?, bindableQuoteGuidance: [BindableQuoteGuidance]) -> String? {
	//Determine the leader if the risk is at least at Firm Order
	guard risk.control.status.stageOrder > 1 else {
		return nil
	}
	if role == .leader {
		return "Yes"
	}
	if risk.isFacility || risk.isDeclaration {
		let allGuidance = lineGuidanceSets.flatMap{ $0.contents }.isEmpty ? bindableQuoteGuidance.flatMap{ $0.contents } : lineGuidanceSets.flatMap{ $0.contents }
		let myGuidanceForSection = allGuidance.first(where: { $0.sectionIdentifiers.first == sectionID && $0.associatedFacilityId == associatedFacilityId })
		if myGuidanceForSection?.role == .leader {
			return "Yes"
		}
	}
	//If it's a sectioned risk, have to get it from the line guidance
	if let sectionID = sectionID {
		let allGuidance = lineGuidanceSets.flatMap{ $0.sections }.isEmpty ? bindableQuoteGuidance.flatMap{ $0.sections } : lineGuidanceSets.flatMap{ $0.sections}
		let allSectionInfos = sectionInfos ?? allGuidance
		let mySectionInfo = allSectionInfos.first(where: { $0.multiSectionId == sectionID })
		if case let .openMarket(leadUWChannel, _) = mySectionInfo?.leadUnderwriter {
			//Need to check this for facility too when role is not leader in case there is an off platform leader
			return leadUWChannel == carrierChannel ? "Yes" : "No"

		}
	}
	if case let .openMarket(chan, _) = risk.control.leadUnderwriter {
		//Need to check this for facility too when role is not leader in case there is an off platform leader
		return chan == carrierChannel ? "Yes" : "No"
	}
	return "No"
}
*/
	lookForCarrierBusinessUnit = ( parentID : string ) : StampCarrierBusinessUnit | null => {
		let retval : StampCarrierBusinessUnit | null = null;
		if( !parentID ) {
			return null;
		}
		if( this.xmrc?.signedLineSets ) {
			this.xmrc.signedLineSets.forEach( sils => {
				sils.contents.forEach( c => {
					c.impressions.forEach( i => {
						if( i.stamp.uniqueID == parentID ) {
							retval = { stampID : i.stamp.uniqueID, carrier: c.businessUnit, businessUnit: i.stamp.businessUnit };
							// console.log( `lookForCarrierBusinessUnit ${parentID} ${retval.carrier} ${retval.businessUnit}`);
						}
					});
				});
			});
		}
		return retval;
	}

	lookForSlipLeaderInRisk = () : string => {
		let retval = "";
		if( !this.xmrc ) {
			return retval;
		}
		if( this.xmrc.control?.leadUnderwriter ) {
			retval = this.xmrc.control.leadUnderwriter;
		} else if( this.xmrc.control?.leadUnderwriterChannel ) {
			retval = this.xmrc.control.leadUnderwriterChannel
		}
		return retval;
	}
	lookForSlipLeaderInLGSet = ( sCombo: string, data: any ) : string => {
		const parts = sCombo.split( ",").map( x => { return x.trim(); });
		let retval = "";

		data.forEach( (x:any) => { 
			const lgset : LineGuidanceSet.Root = x;
			lgset.sections.forEach( s => {
				if( parts.includes( s.multiSectionId ) ) {
					if( !retval ) {
						retval = s.leadUnderwriter;
					}
				}
			})
		});
		return retval;
	}
	lookForSlipLeaderInMultiSectionStorer = ( sCombo: string ) : string => {
		let retval = "";
		if( this.msst && this.msst.sections ) {
			console.log( `lookForSlipLeaderInMultiSectionStorer seeking ${sCombo} amongst ${this.msst.sections.length} sections`)
			this.msst.sections.forEach( s => {
				if( s.multiSectionId == sCombo ) {
					retval = s.leadUnderwriter.replaceAll( "OFFLINE", "OFFLINE ");
				}
			});
		}
		return retval;
	}
}