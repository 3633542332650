import { utils } from '../FromElsewhere/utils'

export const payloads = {
    // WsRiskLibrary/Sources/WsSchema/InboxFilter.swift

    summary_Draft : function( grail ) {
        const payload: any = { statuses : [ "NotFinalised" ] };
        if( grail ) {
            if( utils.validUMR( grail ) ) {
                payload.umrSearch = grail;
            } else {
                payload.nameSearch = grail;
            }
        }
        return payload;
    },

    summary_QuotationRequest : function( grail ){
        const payload: any = { statuses : [ "QuotationRequest","QuotationInPreparation" ] };
        if( grail ) {
            if( utils.validUMR( grail ) ) {
                payload.umrSearch = grail;
            } else {
                payload.nameSearch = grail;
            }
        }
        return payload;
    },

    summary_Signed : function( grail ) {
        const payload: any = { statuses : [ "Signed","Endorsement","EndorsementPending" ] };
        if( grail ) {
            if( utils.validUMR( grail ) ) {
                payload.umrSearch = grail;
            } else {
                payload.nameSearch = grail;
            }
        }
        return payload;
    }
}