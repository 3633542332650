import React, { useState, useEffect, ReactElement } from 'react'
import { Modal, Col, Row, Button, Tab, Tabs, Form } from 'react-bootstrap'
import { Query, whitespace, WhitespaceAPI } from "../../helpers/whitespace";
import { Corporate } from '../../Session';
import { JMRC } from "../../rebrowse/jmrc";
import { utils} from "../../FromElsewhere/utils";
import { DDDataPoint, DDExport, DefinedData } from "../../FromElsewhere/DefinedData";
import dayjs from 'dayjs';
import { NotificationManager } from 'react-notifications'
import './LabelledRisks.css'
import { Candidate, Globule, GlobulePurpose, GlobuleStatus } from './CandidateRisk';

/*
From: Hamish Greenwood <Hamish.Greenwood@mcgillpartners.com>
Date: Thursday, 17 November 2022 at 09:52
To: Clarke, Jonathan <Jonathan.Clarke@Whitespace.co.uk>
Cc: Wright, Charley <Charley.Wright@whitespace.co.uk>, Wilkinson, Jody <Jody.Wilkinson@whitespace.co.uk>
Subject: RE: MI help

Done in a consistent manner since 1st October 2022. The tags we have introduced are as follows:
UNIQUE MARKET REFERENCE
INSURED
INSURED ADDRESS
INSURED IDENTIFIER 
PERIOD-
LIMITS OF LIABILITY
TERRITORIAL LIMITS
PREMIUM
SLIP LEADER
SETTLEMENT DUE DATE
OVERSEAS BROKER
FEE PAYABLE BY CLIENT
TOTAL BROKERAGE
ORDER HEREON
BASIS OF WRITTEN LINES
BASIS OF SIGNED LINES
*/

export const LabelledRisks = () => {
	const [loading, setLoading] = useState("");
	const [fromDate, setFromDate] = useState("1 October 2022");
	const [labelList, setLabelList] = useState<any[]>([]);
	const [summaries, setSummaries] = useState<any[]>([]);
	const [selectedLabel, setSelectedLabel] = useState("");


	const MyDataset : Candidate[] = [];
	const [displayDataSet, setDisplaySet] = useState<Candidate[]>([]);

	// let MyCorporates: Corporate[] = [];
	// let MyStamps: any = {};
	let fromInceptionDate = "";
	const wsDatetimeFormat = 'YYYY-MM-DD HH:mm:ss'

	const Expected_Tags = [
		"Broker_Reference",
		"Insured_Name", "Reinsured_Name", "Original_Insured_Name",
		"Insured_Country", "Reinsured_Country",
		"Insured_Identifier",
		"Inception_Date", "Expiry_Date",
		"Coverage_Amount", "Excess_Amount",
		"Risk_Location_Country",
		"Premium_Amount","Premium_Period_Qualifier",
		"Contract_Leader_Name",
		"Settlement_Due_Date",
		"Producing_Broker_Name",
		"Fee_Payable_By_Client",
		"Brokerage_%", "Brokerage_Amount",
		"Order_%",
		"Basis_Of_Signed_Lines",
		"Basis_Of_Written_Lines"
	];

	useEffect(() => {
        refreshLabelList()
    }, [])

	const refreshLabelList = () => {
        const url = `/api/labels/teamLabels`;
		setLoading( "Loading labels...");
        WhitespaceAPI.get( url ).then( response => {
			// console.log( response.data );
			setLoading( "");
			setLabelList( response.data );	
			const x = {};
			response.data.forEach( lbl => {
				const title = lbl.title;
				if( x[ title ] ) {
					x[ title ] += 1;
				} else {
					x[ title ] = 1;
				}
			});	
			const y: any[] = [];
			Object.keys( x ).forEach( key => { y.push( { label: key, count: x[key] } )} );
			setSummaries( y );
        });
	}

	const runDownload = ( doSigned : boolean ) => {
		fromInceptionDate = dayjs(fromDate).format('YYYY-MM-DD HH:mm:ss');
		if( fromInceptionDate.substring(0,2) != "20") {
			NotificationManager.error( `Date ${fromDate} should be like 1 October 2022`);
			return;
		}
		MyDataset.length = 0;
		setDisplaySet( [] );
		callSummaryByLabel( "", "", doSigned );
	}

	const callSummaryByLabel = ( last, from, doSigned ) => {
		let url = "/api/summary";	
		if( last && from ) {
			url += `?last=${last}&from=${from}`;
		}
		const payload = { "labels" : [ selectedLabel ] };
		WhitespaceAPI.post( url, payload ).then( response => {
			const returnedCount = response.data.length;
			console.log( `returnedCount ${returnedCount} from ${url}` );
			if( returnedCount == 0 ) {
				addGlobules( doSigned );
				setDisplaySet( MyDataset );
				processDataSet( doSigned );
			} else {
				response.data.forEach( x => {
					const item = new Candidate( x.rootID ); // { rootID: x.rootID, riskID: "", fetched: 0, output: null };
					MyDataset.push( item );
				});
				setLoading( `Identified ${MyDataset.length} risks labelled ${selectedLabel}`);
				const lastItem = response.data[ returnedCount - 1 ];
				const last = lastItem.rootID;
				const from = encodeURI( lastItem.updatedAt );
				callSummaryByLabel( last, from, doSigned );
			}
		});
	}

	const addGlobules = ( doSigned ) => {
		MyDataset.forEach( item => {
			if( doSigned ) {
				item.globules.push( new Globule( GlobulePurpose.DefData, `/api/v22.04/data/${item.riskID}` ) );
				item.globules.push( new Globule( GlobulePurpose.Risk, `/api/risks/${item.riskID}/getExtendedMRC` ) );
				item.globules.push( new Globule( GlobulePurpose.LineGuidance, `/api/risks/${item.rootID}/related/RWLineGuidanceSet` ) );
				item.globules.push( new Globule( GlobulePurpose.MultiSectionStorer, `/api/documents/${item.rootID}::MS` ) );
			} else {
				item.globules.push( new Globule( GlobulePurpose.Risk, `/api/risks/${item.riskID}` ) );
			}
		});
	};

	const processDataSet = ( doSigned ) => {
		setLoading( `Reading ${MyDataset.length} signed risks`);
		MyDataset.forEach( item => {
			fetchAllGlobules( item, doSigned );
		});

		// if( doSigned ) {
		// 	setLoading( `Fetching all stamp data...`);
		// 	const url = "/api/shared/corporate";
		// 	WhitespaceAPI.get( url ).then( response => {
		// 		MyCorporates = response.data;
		// 		MyStamps = {};
		// 		MyCorporates.forEach( c => {
		// 			c.stamps.forEach( s => {
		// 				MyStamps[ s.uniqueID ] = { companyId: c.companyId, companyName: c.name, stamp : s };
		// 			});
		// 		})
		// 		setLoading( `Loaded ${MyCorporates.length} corporates. Now reading ${MyDataset.length} signed risks`);
		// 		MyDataset.forEach( item => {
		// 			fetchAllGlobules( item, doSigned );
		// 			// fetchDefinedData( item );
		// 		});
		// 	});

		// } else {
		// 	setLoading( `Reading ${MyDataset.length} signed risks`);
		// 	MyDataset.forEach( item => {
		// 		fetchAllGlobules( item, doSigned );
		// 		// fetchData( item );
		// 	});
		// }
	}

	const fetchAllGlobules = ( item : Candidate, doSigned : boolean ) => {
		item.globules.forEach( g => {
			g.fetched = GlobuleStatus.Calling;
			WhitespaceAPI.get( g.url, { silent: true } ).then( response => {
				if( !response?.data) {
					g.fetched = GlobuleStatus.Failed;
				} else {
					g.fetched = GlobuleStatus.OK;
					g.data = response.data;
				}
				const finished = showLoadingProgress();
				if( finished ) {
					postDataRead( doSigned );
				}
			});
		});
	}

	const showLoadingProgress = () => {
		let fetched = 0, failed = 0, count = 0;
		const len = MyDataset.length;
	
		for( let i = 0; i < len; i++ ) {
			const item = MyDataset[i];
			item.globules.forEach( g => {
				count += 1;
				if( g.fetched == GlobuleStatus.OK ) { fetched += 1; }
				if( g.fetched == GlobuleStatus.Failed ) { failed += 1; }
			});
		}
		setLoading( `${fetched} loaded, ${failed} failed of ${count} total across ${len} risks`);
		return count == ( fetched + failed );
	}

	const postDataRead = ( doSigned : boolean ) => {
		const signedSet : Candidate[] = [];
		const signedExpectedTags = [ "Broker_Reference", "Insured_Name", "Reinsured_Name", "Original_Insured_Name", 
		"Inception_Date", "Expiry_Date", "Coverage_Amount", "Brokerage_%",
        "Premium_Amount", "Signed_Line_Percentage", "SLIP-LEADER", "CARRIER-NAME", "BUSINESS-UNIT" ];

		let afterDate0 = 0;
		MyDataset.forEach( item => {
			console.log( `postDataRead ${item.riskID} ${item.insuredName}` );
			item.inceptionDate = item.getInceptionDate() || "blank";
			if( item.inceptionDate != "blank" && item.inceptionDate >= fromInceptionDate ) {
				afterDate0 += 1;
				setLoading( `Found ${afterDate0} risks after ${fromInceptionDate}`);
				try{
					if( doSigned ) {
						item.prepareForSignedOutput( signedExpectedTags );
					} else {
						item.prepareForContractOutput();
					}
					signedSet.push( item );	
					console.log( `postDataRead pushed ${item.riskID} ${item.insuredName}`);
				} catch( ex : any ) {
					console.log( `ERROR caught in postDataRead ${ex.message || "No message"} ${item.riskID} ${item.insuredName}`);
					console.log( ex );
					item.inceptionDate = "ERROR";
				}
			}
		});
		if( doSigned ) {
			outputSignedData( signedSet, doSigned )
		} else {
			outputContractData( signedSet, doSigned );
		}
	};

	/*
	const fetchDefinedData = async( item : Candidate ) => {
		const url = `/api/v22.04/data/${item.riskID}`;	
		const expectedTags = [ "Broker_Reference", "Insured_Name", "Reinsured_Name", "Original_Insured_Name", 
		"Inception_Date", "Expiry_Date", "Coverage_Amount", "Brokerage_%",
        "Premium_Amount", "Signed_Line_Percentage" ];

		WhitespaceAPI.get( url, { silent: true } ).then( response => {
			if( !response?.data ) {
				item.fetched = -1;
			} else {
				const ddExport : DDExport = new DDExport( response.data );
				const analysis : any[] = ddExport.getAnalysisByCarrierAndSection( expectedTags );
				item.output = { analysis : analysis }
				const inceptionDate = ddExport.getFirstMatchingValue("Inception_Date")
				if( inceptionDate ) {
					item.output.inceptionDate = dayjs( inceptionDate ).format( wsDatetimeFormat );
				}
				item.fetched = 1;
			}
			const finished = showLoadingProgress1();
			if( finished ) {
				prepareForOutput( true );
			}
		});
	}
	*/
	/*
	const fetchData = async ( item : Candidate ) => {
		const url = `/api/risks/${item.riskID}`;		
		WhitespaceAPI.get( url, { silent: true } ).then( response => {
			if( !response?.data ) {
				item.fetched = -1;
			} else {
				const x : JMRC.Root = response.data;
				const ddPoints = DDDataPoint.fromJMRC( x );
				const output = { umr: x.control.umr, id: x._id, insuredName: x.control.insuredName, inceptionDate : "", 
					premium: "", ddValues : [""], lineAnalysis: null, ddPoints: ddPoints };
				const ddValues: string[] = [];
				output.premium = DDDataPoint.getFirstMatchingValue( ddPoints, "Premium_Amount");
				const inceptionDate = DDDataPoint.getFirstMatchingValue( ddPoints, "Inception_Date");
				if( inceptionDate ) {
					output.inceptionDate = dayjs( inceptionDate ).format( wsDatetimeFormat );
				}
				Expected_Tags.forEach( tag => {
					const myValues = ddPoints.filter( p => { return p.tag == tag }).map( p => { return p.value });
					const joinedValue = myValues.length == 0 ? "" : myValues.join( ", ");
					ddValues.push( joinedValue );
				});
				output.ddValues = ddValues;
				item.output = output;
				item.fetched = 1;
			}
			const finished = showLoadingProgress1();
			if( finished ) {
				prepareForOutput( false );
			}
		});
	}
	*/
	/*
	const showLoadingProgress1 = () => {
		let fetched = 0, missing = 0;
		const len = MyDataset.length;
		for( let i = 0; i < len; i++ ) {
			const item = MyDataset[i];
			if( item.fetched == 1 ) 	fetched += 1;
			if( item.fetched == -1 ) 	missing += 1;
		}
		setLoading( `${fetched} loaded, ${missing} not signed of ${len} total`);
		return len == ( fetched + missing );
	}
	*/
	/*
	const prepareForOutput = ( doSigned ) => {
		const signedSet = MyDataset.filter( ( item ) => { return item.fetched >= 1; }).filter( item => { return item.output.inceptionDate >= fromInceptionDate } );
		const len = signedSet.length;
		if( len == 0 ) {
			setLoading( `No signed risks for label ${selectedLabel}`);
			return;
		}		
		if( doSigned ) {
			outputSignedData( signedSet, doSigned )
		} else {
			outputContractData( signedSet, doSigned );
		}
	}
	*/
	const makeOutputFromAnalysis = ( a : any, riskID : string ) : string => {
		const d = ",";
		const parts : string[] = []; 
		const insured = [ valueInAnalysis( a, "Insured_Name"), valueInAnalysis( a, "Reinsured_Name"), valueInAnalysis( a, "Original_Insured_Name") ]
		parts.push( valueInAnalysis( a, "Broker_Reference"));
		parts.push( insured.filter( x => { return x }).join( " / ") || "-");
		parts.push( valueInAnalysis( a, "Inception_Date"));
		parts.push( valueInAnalysis( a, "Expiry_Date"));
		parts.push( parseCurrency( a, "Coverage_Amount"));
		parts.push( parseFigure( a, "Coverage_Amount"));
		parts.push( valueInAnalysis( a, "Brokerage_%"));
		parts.push( valueInAnalysis( a, "SLIP-LEADER"));
		parts.push( a.sCombo );
		parts.push( parseCurrency( a, "Premium_Amount"));
		parts.push( parseFigure( a, "Premium_Amount"));
		parts.push( "");	// USD Date
		parts.push( "" );	// USD Premium
		parts.push( valueInAnalysis( a, "CARRIER"));
		parts.push( valueInAnalysis( a, "BUSINESS-UNIT"));
		// const sdata = MyStamps[ a.parentID ];
		// parts.push( sdata ? sdata.companyName : a.parentID + "missing");
		// parts.push( sdata ? sdata.stamp.businessUnit : a.parentID + "missing");
		parts.push( valueInAnalysis( a, "Signed_Line_Percentage"));
		parts.push( "" );	// Carrier USD Premium

		parts.push( riskID );

		return parts.map( p => { return utils.prepareForCSV( p ) } ).join( d );
	}
	const parseCurrency = ( a: any, tag : string ) : string => {
		const curr_amount = valueInAnalysis( a, tag );
		const parsed = utils.parseAmount( curr_amount );
		return parsed ? parsed.currency : "";
	}
	const parseFigure = ( a: any, tag : string ) : string => {
		const curr_amount = valueInAnalysis( a, tag );
		const parsed = utils.parseAmount( curr_amount );
		return parsed ? parsed.figure.toString() : "";
	}
	const valueInAnalysis = ( a: any, tag : string ) : string => {
		const p = a.pairs.filter( p => { return p.tag == tag; } )[0];
		return p ? p.value : "";
	}

	const outputSignedData = ( signedSet, doSigned ) => {
		setLoading( `Downloading signed line report for ${signedSet.length} risks with label ${selectedLabel}`);
		
		const lines: string[] = [];
		const d = ",";
		lines.push( `UMR${d}Insured / Reinsured${d}Inception Date${d}Expiry Date${d}Coverage Currency${d}Coverage Amount${d}Brokerage${d}Slip Leader${d}Section${d}Premium Currency${d}Premium Amount${d}USD Rate${d}USD Premium${d}Carrier${d}Business Unit${d}SignedPerc${d}Carrier USD Premium${d}IC`);
		signedSet.forEach( x => {
			if( x.output && x.output.analysis) {
				x.output.analysis.forEach( a => {
					lines.push( makeOutputFromAnalysis(a, x.riskID ));
				});
			} else if(x.output ){
				lines.push( "No output.analysis...");
			} else {
				lines.push( "No output");
			}
		}); 
		downloadData( makeFilename( doSigned ), lines );
	}

	const outputContractData = ( signedSet, doSigned ) => {
		setLoading( `Downloading contract data report for ${signedSet.length} risks with label ${selectedLabel}`);
		const lines: string[] = [];
		const d = ",";
		lines.push( `UMR${d}Insured${d}` + Expected_Tags.join( d ) + `${d}IC`);
		signedSet.forEach( (x : Candidate) => {
			const parts = [ x.umr, x.insuredName ];
			Expected_Tags.forEach( tag => {
				parts.push( x.getTaggedValueFromRiskData( tag ) );
			});
			parts.push( x.riskID );
			lines.push( parts.map( p => { return utils.prepareForCSV( p ) } ).join( d ) );
		});
		downloadData( makeFilename( doSigned ), lines );
	}

	const downloadData = ( filename, lines) => {
		const content = lines.join( "\n");
		const link = document.createElement('a');
		const file = new Blob( [content], {
			type: "text/plain"
		});
		link.href = URL.createObjectURL( file );
		link.download = filename;
		link.click()
	}

	const makeFilename = ( doSigned ) : string => {
		const parts = selectedLabel.match( /[A-Z0-9]+/gi );
		const suffix = doSigned ? "_ByUnderwriter" : "";
		if( parts ) {
			return parts.join( "_") + suffix + ".csv";
		} else {
			return `LabelledRisks${suffix}.csv`;
		}
	}

	const onSelectLabel = (event) => {
		const value = event.target.value;
		setSelectedLabel( value );
    }

	const onChangeFromDate = event => {
		setFromDate( event.target.value )
	}

	const GlobuleDisplay = ( { globule, idx } ) => {
		const className = `fetched fetched-${globule.fetched}`;
		return (
			<span className={className} key={idx}>{globule.purpose}</span>
		)
	}

	const CandidateDisplay = ( { item, idx } ) => {
		return (
			<div className="row" key={idx}>
				<div key="IC" className="SmallText col-md-6">{item.rootID}</div>
				<div key="InceptionDate" className="SmallText col-md-2">{item.inceptionDate.substring(0,10)}</div>
				<div key="Globules" className="col-md-4">
					{item.globules.map( ( g, idx2 ) => <GlobuleDisplay globule={g} key={idx2} idx={idx2}/>)}
				</div>
			</div>
		)
	}

	return (
		<>
		{labelList.length > 0 &&
		<h2>{labelList.length} labels applied - {summaries.length} distinct labels</h2>
		}		
		{summaries.length > 0 &&
			<Form.Control
				as="select"
				className="my-1"
				onChange={onSelectLabel}
				name="selectedLabel"
				value={selectedLabel}
				custom>
				<option value='' key="select">Select ...</option>
				{summaries.map((s, idx) => <option value={s.label} key={idx}>{s.label} - {s.count} risks</option>)}
			</Form.Control>	
		}
		{ selectedLabel != "" && 
			<div className='Above12 Below12'>
				Inception Date from&nbsp; 
				<input type="text" placeholder="dd MMM yyyy" aria-label="Search Input" value={fromDate} onChange={onChangeFromDate} />
				&nbsp;
			<Button onClick={() => {runDownload(false);}}>Contract Data</Button>&nbsp;
			<Button onClick={() => {runDownload(true);}}>Signed Line Data</Button>
			</div>	
		}
		{loading != "" &&
		<div className='WarningBanner'>{loading}</div>
		}
		<div className='Above12'>
			{displayDataSet.map( ( item, idx ) => <CandidateDisplay item={item} key={idx} idx={idx}/>)}
		</div>
		</>
	)
}