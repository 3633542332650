import { DataRuleHardcoded, DataRuleSet } from "./DataRules";

export class SummaryHardCoded {
	static PASS = "PASS";
	static FAIL = "FAIL";
	static MISSING = "MISSING";
	static NORULES = "NORULES";
	static DOESNOTAPPLY = "DOESNOTAPPLY";
	static NYI = "NYI";
}

export class SummaryForChecker {
	narrative : string = "";
	rules : SummaryForRule[];
	constructor() {
		this.rules = [];
	}
	result() : string {
		if( this.missingCount() > 0 )	return SummaryHardCoded.MISSING;
		if( this.failCount() > 0 )		return SummaryHardCoded.FAIL;
		if( this.passCount() > 0 )		return SummaryHardCoded.PASS;
		return SummaryHardCoded.NORULES;
	}
	missingCount() : number { return this.rules.filter( r => { return r.result == SummaryHardCoded.MISSING; } ).length; }
	passCount() : number { return this.getPasses().length; }
	failCount() : number { return this.getFails().length; }
	skipCount() : number { return this.getSkips().length; }
	getPasses() : SummaryForRule[] {
		return this.rules.filter( r => { return r.result == SummaryHardCoded.PASS; } );  
	}
	getFails() : SummaryForRule[] {
		return this.rules.filter( r => { return r.result == SummaryHardCoded.FAIL && r.severity != DataRuleHardcoded.OPTIONAL; } );  
	}
	getOptionalFails() : SummaryForRule[] {
		return this.rules.filter( r => { return r.result == SummaryHardCoded.FAIL && r.severity == DataRuleHardcoded.OPTIONAL; } );  
	}
	getSkips() : SummaryForRule[] {
		return this.rules.filter( r => { return r.result == SummaryHardCoded.DOESNOTAPPLY; } );  
	}

	summarise() : string {
		const optionalFails = this.getOptionalFails();
		const optionalMessage = optionalFails.length == 0 ? "" : ` but problems with ${optionalFails.length} optional rules`;
		if( this.missingCount() > 0 ) {
			this.narrative = "ERROR missing rule(s) " + this.rules.filter( r => { return r.result == SummaryHardCoded.MISSING; } ).map( r => { return r.ruleID; }).join( ", ");
		}
		else if( this.failCount() > 0 ) {
			this.narrative = `failed on ${this.failCount()} of ${this.rules.length} rules`;
		}
		else if( this.passCount() > 0 ) {
			this.narrative = `pass ${this.passCount()} rules apply ${optionalMessage}`;
		} else {
			this.narrative = `No rules applied`;
		}
		return this.narrative;
	}
}

export class SummaryForRule {
	ruleID : string;
	fieldName : string;
	result : string = "";
	failMessages: string[] = [];
	rawRiskValue: string;
	severity: string;

	constructor( ruleID : string, rule : DataRuleSet | undefined ) {
		this.ruleID = ruleID;
		if( rule ) {
			this.fieldName = rule.fieldName;
			this.rawRiskValue = rule.rawValue;
			this.severity = rule.severity;
			if( rule.feedback ) {
				this.failMessages.push( rule.feedback );
			}
		} else {
			this.fieldName = "";
			this.rawRiskValue = "";
			this.severity = "";
		}
	}


	// constructor( id : string, fieldName : string, rawValue : string, severity : string ) {
	// 	this.ruleID = id;
	// 	this.fieldName = fieldName;
	// 	this.rawRiskValue = rawValue;
	// 	this.severity = severity;
	// }
}