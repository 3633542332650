import { isTemplateLiteral } from 'typescript';
import { utils } from '../FromElsewhere/utils'

export const riskFilter = {
    groupRisksSummary : function( allRisks, options: any = null ) {
        const result: any[] = [];
        const statusMatch = options?.status || "";
        allRisks.map( item => {
            let summary: any = null;
            item.docs.map( risk => {
                if( risk.type === "RWPlacing" && ( !statusMatch || risk.status === statusMatch )) {
                    const myRisk = { id: risk.id, rootID: item.rootID, umr: risk.umr, InsuredName: risk.insuredName, Status: risk.status,
                        riskdate : risk.updatedAt || risk.createdAt, channels: risk.channels }
                    if( !summary ) {
                        summary = { id: risk.id, rootID: item.rootID, umr: risk.umr, InsuredName: risk.insuredName, Status: risk.status,
                            earliest: risk.updatedAt, latest: risk.updatedAt, iterations: [] };
                    }
                    summary.iterations.push( myRisk );
                    if( risk.updatedAt > summary.latest ) {
                        summary.latest = risk.updatedAt
                        summary.Status = risk.status
                    }
                    if( risk.updatedAt < summary.earliest ) {
                        summary.earliest = risk.riskdate
                    }
                }
            });
            if( summary ) {
                result.push( summary );
                if( summary.rootID == "ICAB797B60-54EA-4E5C-AD39-A4C50FF548CD" ) {
                    console.log( `groupRisksSummary ${summary.rootID} has ${summary.iterations.length} iterations`);
                    summary.iterations.forEach( itn => {
                        console.log( `groupRisksSummary ${itn.id} ${itn.Status} ${itn.updatedAt}`);
                    })
                }
            }
        });
        return result
    },
    groupAllRisks : function( allRisks, options: any = null ) {
        const result: any[] = []
        allRisks.map( (risk) => {
            if( options?.method === 'BrokerSigned' ) {
                if( risk.id.slice(risk.id.length - 8) != '::SIGNED' )
                    return
            }
            const rootID = utils.rootIC( risk.id )
            let summary = result.filter( ( itm ) => itm.rootID === rootID )[0]
            risk.riskdate = risk.updatedAt || risk.createdAt
            if( !summary )
            {
                summary = { id: risk.id, rootID: rootID, umr: risk.umr, InsuredName: risk.InsuredName, Status: risk.Status,
                    earliest: risk.riskdate, latest: risk.riskdate, iterations: [] }
                result.push( summary )   
            }
            if( risk.riskdate > summary.latest ) {
                summary.latest = risk.riskdate
                summary.Status = risk.Status
            }
            if( risk.riskdate < summary.earliest ) {
                summary.earliest = risk.riskdate
            }
            summary.iterations.push( risk )
        })
        result.forEach( ( summary ) => {
            summary.iterations.sort( ( x, y ) => {
                if( x.createdAt && y.createdAt ) {
                    return( x.createdAt.localeCompare( y.createdAt ) )
                } else {
                    return 0;
                }
            })
        })
        if( options?.method === 'NotFinalisedSingletons') {
            const len = result.length
            for( let i = len - 1; i >= 0; i-- )
            {
                const summary = result[i]
                if( summary.iterations.length != 1 || summary.Status != 'NotFinalised') 
                    result.splice( i, 1 )
            }
        }
        return result
    },
    match : function( summaries, value ) {
        const result: any[] = []
        const grail = value.toLowerCase()
        if( !grail )
            return summaries
        summaries.map( ( summary ) => {
            if( summary.InsuredName.toLowerCase().includes( grail ) || summary.umr.toLowerCase().includes( grail ) )
                result.push( summary )
        })
        return result
    }
    
}
