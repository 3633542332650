import { DataRuleCondition } from "./DataRuleCondition";
import { DataRuleEquation } from "./DataRuleEquation";
import { DataRuleStatement } from "./DataRuleStatement";
import { DataRuleTranslator } from "./DataRuleTranslator";
import { DataRuleFieldMapper } from "./DataRulesFieldMapper";
import { DDExport, DefinedData } from "../FromElsewhere/DefinedData";

export class DataRuleHardcoded {
	static NULL = "**NULL**";
	static OPTIONAL = "Optional";
}

export class DataRuleUtils {
	static trimEnds( str : string, start : string, end : string ) : string {
		if( str.startsWith( start ) ) {
			str = str.substring( start.length );
		}
		if( str.endsWith( end ) ) {
			str = str.substring(0, str.length - end.length );
		}
		return str.trim();
	}
}

export interface DataRuleEnvironment {
	mayLogFn : any;
	translator : DataRuleTranslator | null;
	fieldMapper : DataRuleFieldMapper | null;
}

export interface DDFieldMap {
	mrcHeading: string;
	tag: string;
	translation: string;
}

export interface DataRuleSet {
	identifier: string;
	fieldName: string;
	equations: DataRuleEquation[];
	statements: DataRuleStatement[];
	condition: DataRuleCondition;
	rawValue: string;
	reduced: string;
	severity: string;
	feedback: string;
}







