import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { WhitespaceAPI } from '../../helpers/whitespace';
import { utils } from '../../FromElsewhere/utils';
import './Synonyms.css'

export const Synonyms = () => {
    const HEADINGS_SYNONYM_FILE_NAMES: string[] = [
        "CDRiskDetailsSynonyms",
        "CDInformationSynonyms",
        "CDSecurityDetailsSynonyms",
        "CDSubscriptionAgreementSynonyms",
        "CDFiscalAndRegulatorySynonyms",
        "CDBrokerRemunerationAndDeductionsSynonyms",
        "CDEndorsementSynonyms",
		"CDSectionSynonyms"
    ];
	const [selected,setSelected] = useState("");
	const [synoFile,setSynoFile] = useState<any>(null);
	const [searchString,setSearchString] = useState("");
	const [lookupResults,setLookupResults] = useState("");

	const lookup = () => {
		const grail = searchString.trim();
		if( !grail ) {
			setLookupResults( "Please enter something in the search box");
			return;
		}
		setLookupResults( `Looking up ${grail}`);
		const url = "/api/lookup/check/" + encodeURIComponent( grail );
		WhitespaceAPI.get( url ).then( response => {
			setLookupResults( response?.data?.msg || `${url} failure`);
		});
	}

	const pickFile = ( fn ) => {
		setSelected( fn );
		const url = `/api/documents/${fn}`;
		WhitespaceAPI.get( url ).then( response => {
			setSynoFile( response.data );
		});
	}

	const ItemViewer = ( { idx, heading, synonyms } ) => {
		return (
			<>
			<div key={idx} className='SuccessText'>{heading} has {synonyms.length} {utils.makePlural(synonyms.length, "synonym")}</div>
			<ul>
			{synonyms.map( (s,idx2) => { return ( <li key={idx2}>{s}</li>)})}
			</ul>
			</>
		)
	}

	const SynoViewer = () => {
		const ob = synoFile;
		console.log( ob );
		if( !ob || !ob._id ) {
			return (
				<div>Select a file to view</div>
			)
		} else {
			const fileID = ob._id;
			const standard = [ "_id","_rev","id","rev","channels","createdAt","type"];
			const toDisplay: string[] = [];
			Object.keys(ob).forEach( heading => {
				if( !standard.includes( heading ) ) {
					toDisplay.push( heading );
				}
			});
			return (
				<>
				<h2>{fileID} has {toDisplay.length} {utils.makePlural(toDisplay.length,"heading")} with synonyms</h2>
				{toDisplay.map( (heading,idx) => {
					if( Array.isArray( ob[ heading ] ) ) {
						const synonyms: string[] = ob[ heading ];
						return ( <ItemViewer idx={idx} heading={heading} synonyms={synonyms} /> )	
					}
				})}
				</>
			)
		}
	}

	return (
        <>
		<div className='Below12'>
			<input type="text" placeholder="word or phrase" defaultValue={searchString} onKeyUp={(event) => setSearchString(event.currentTarget.value) }></input>			
			&nbsp;
			<Button onClick={lookup}>Lookup</Button>
			&nbsp;
			<span className='SuccessText'>{lookupResults}</span>
		</div>
		<div>
		{HEADINGS_SYNONYM_FILE_NAMES.map( (fn,idx) => {
			const myClass = ( fn == selected ? "CDRadioButton CDSelected" : "CDRadioButton") 
			return( <div key={idx} className={myClass} onClick={() => pickFile(fn)}>{fn}</div> )
		})}
		</div>
		<div className='ClearBoth'></div>
		<SynoViewer></SynoViewer>
		</>
	)
}